import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './en'
import vi from './vi'
import fr from './fr'
import de from './de'
import ru from './ru'
import az from './az'
import zh from './zh'
import tr from './tr'
import id from './id'
import kr from './kr'
import th from './th'
import ar from './ar'
import bn from './bn'
import es from './es'
import hi from './hi'
import pt from './pt'
import jp from './jp'
import zhft from './zhft'
Vue.use(VueI18n)

const messages = {
  tr: {
    ...tr
  },
  vi: {
    ...vi
  },
  fr: {
    ...fr
  },
  de: {
    ...de
  },
  ru: {
    ...ru
  },
  az: {
    ...az
  },
  hi: {
    ...hi
  },
  bn: {
    ...bn
  },
  es: {
    ...es
  },
  pt: {
    ...pt
  },
  id: {
    ...id
  },
  jp: {
	...jp
  },
  zhft: {
  	...zhft
  },
  th: {
    ...th
  },
  kr: {
    ...kr
  },
  ar: {
    ...ar
  },
  zh: {
    ...zh
  },
  en: {
    ...en
  }
}

const i18n = new VueI18n({
  locale: 'en', // set locale
  messages // set locale messages
})

export default i18n
