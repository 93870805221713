export default {
  tabbar: {  
      首页: "Trang chủ",  
      市场: "Thị trường",  
      任务: "Nhiệm vụ",  
      团队: "Đội nhóm",  
      我的: "Của tôi"  
    },  
    navbar: {  
      充值: "Nạp tiền",  
      提现: "Rút tiền",  
      帮助: "FAQ",  
      分享: "Mời",  
      推广: "Quảng bá",  
      活动: "Trò chơi",  
      游戏: "Trò chơi"  
    },  
    payment: {  
      付款方式详情: "Chi tiết phương thức thanh toán",  
      银行卡: "Thẻ ngân hàng",  
      钱包地址: "Phương thức rút tiền",  
      交易密码: "Mật khẩu giao dịch",  
      保存: "Lưu"  
    },  
    market: {  
      市场: "Thị trường",  
      名称: "Tên",  
      价格: "Giá"  
    },  
    task: {  
      任务: "Nhiệm vụ",  
      我的余额: "Số dư của tôi",  
      增加订单数量: "Tăng số lượng đơn hàng",  
      今日剩余订单: "Đơn hàng còn lại hôm nay",  
      频率: "Tần suất",  
      手动交易: "Giao dịch thủ công",  
      预期收益: "Lợi nhuận dự kiến",  
      需要启动存款: "Cần khởi động khoản tiền gửi",  
      任务说明: "Mô tả nhiệm vụ",  
      每天可以完成10个任务: "Mỗi ngày có thể hoàn thành 10 nhiệm vụ",  
      联系VIP助手增加任务数量: "Liên hệ trợ lý VIP để tăng số lượng nhiệm vụ",  
      每个用户的任务总数为300次: "Tổng số nhiệm vụ của mỗi người dùng là 300 lần",  
      购买机器人无需人工操作即可自动交易: "Mua robot để giao dịch tự động mà không cần thao tác thủ công",  
      我知道了: "Tôi biết rồi"  
    },  
    邀请好友注册领取佣金: "Mời bạn bè đăng ký nhận hoa hồng",  
    领取: "Nhận",  
    未满足条件: "Chưa thỏa mãn điều kiện",  
    总收入: "Tổng thu nhập",  
    投资建议: "※ Thực hiện kế hoạch đầu tư tái đầu tư, lợi nhuận sẽ cao hơn",  
    语言切换: "Chuyển đổi ngôn ngữ",  
    POS区块节点挖矿: "Chi tiết giao dịch AI",  
    节点类型: "Loại robot",  
    加入价格: "Giá mua",  
    质押资金: "Vốn POS",  
    质押周期: "Thời gian (ngày)",  
    每日收入: "Thu nhập hàng ngày",  
    累计积分: "Điểm tích lũy",  
    代理等级: "Cấp đại lý",  
    下级折扣比: "Giảm giá cấp dưới",  
    代理折扣比: "Giảm giá đại lý",  
    下属等级: "Cấp dưới",  
    代理佣金比率: "Tỷ lệ hoa hồng đại lý",  
    下属每日收入: "Thu nhập hàng ngày của cấp dưới",  
    代理佣金: "Hoa hồng đại lý",  
    引用对象: "Đối tượng trích dẫn",  
    积分等级折扣规则: "Quy tắc giảm giá theo cấp độ điểm",  
    积分等级折扣规则介绍: "Người dùng tham gia giao dịch AI thành công sẽ nhận được điểm tài khoản tương ứng, và người dùng cấp trên của họ cũng có thể tăng điểm tương ứng (áp dụng cho cấp dưới trực tiếp 15%). Khi tổng điểm tài khoản đạt yêu cầu tương ứng, cấp độ tài khoản đại lý sẽ được nâng cao, tài khoản đại lý ở các cấp độ khác nhau có thể nhận được tỷ lệ giảm giá khác nhau. Chi tiết về giảm giá điểm liên quan như sau：",  
    积分等级折扣规则介绍2: "Người dùng có mối quan hệ khác nhau (trực tiếp) sẽ nhận được giảm giá cho cấp dưới tham gia giao dịch AI và giảm giá cấp đại lý. Các cấp trên khác cũng sẽ nhận được giảm giá theo tỷ lệ đại lý tương ứng của mình. Giảm giá khi tham gia giao dịch AI: Người dùng có cấp độ đại lý tương ứng khi tham gia giao dịch AI sẽ nhận được quyền lợi giảm giá tương ứng。",  
    团队: "Đội nhóm",  
    代理佣金制度: "Chế độ hoa hồng đại lý",  
    代理佣金说明详情: "Tỷ lệ thuốc LV1 mà A thu được là 8%, tỷ lệ thuốc LV2 mà A thu được là 3% và tỷ lệ thuốc LV3 mà A thu được là 2%. Ví dụ: Lợi nhuận hàng ngày của B, C và D là: 100 USD và phần thưởng đại lý LV1 mà A nhận được: 8%*100=8 USD. Phần thưởng đại lý LV2 mà A nhận được: 3%*100=3 USD. Phần thưởng đại lý LV3 mà A nhận được: 2%*100=2 USD. Tổng thu nhập hoa hồng là: 8+3+2=13 USD.",  
    代理结构图示: "Sơ đồ cơ cấu đại lý",  
    代理结构图示说明: "A đại diện cho bạn, A mời B (cấp dưới của A là B), B mời C (cấp dưới của B là C), C mời D (cấp dưới của C là D). A có thể có nhiều cấp dưới (B1, B2, B3, B4, B5, B6, v.v.) và B (B1, B2, B3) cũng có thể có nhiều cấp dưới (C1, c2, c3, c4, c5, c6, v.v.) .)ĐĨA CD...",  
    高级代理佣金说明: "Giải thích hoa hồng đại lý cao cấp",  
    高级代理佣金说明详情: "A đại diện cho bạn, A mời B (cấp dưới của A là B), B mời C (cấp dưới của B là C), C mời D (cấp dưới của C là D). Đại lý cấp cao hơn sẽ nhận được thu nhập hoa hồng đại lý khác nhau (%) dựa trên tỷ lệ của tổng số tiền khai thác nút giữa tài khoản của chính họ và tài khoản của cấp dưới. A so sánh thu nhập hàng ngày của các thành viên cấp dưới với tỷ lệ phần thưởng đại lý tương ứng. Các nút tham gia phải ở trạng thái hoạt động bình thường. Thu nhập của đại lý được INGSAI MinING cung cấp cho tất cả các chi phí tài chính và sẽ không ảnh hưởng đến thu nhập hàng ngày của các thành viên cấp dưới thông thường.",  
    高级代理佣金说明详情2: "Ví dụ: Tổng lượng khai thác của các đại lý cấp cao (A)>30% tổng lượng khai thác của các thành viên cấp thấp hơn (B/C/D) (Ví dụ: A>30% của B/C/D)",  
    积分: "积分",  
    规则: "Quy tắc",  
    会员级别: "Cấp thành viên",  
    经验: "Tổng giá trị điểm",  
    代理信息: "Thông tin thành viên",  
    更多: "Thêm",  
    团队奖励: "Hoa hồng đội nhóm",  
    昨天: "Hôm qua",  
    本星期: "Tuần này",  
    全部: "Tất cả",  
    机器人说明: "Có thể mua nhiều gói giao dịch định lượng cùng lúc, mỗi tài khoản chỉ có thể mua robot tối đa 10 lần. Lưu ý: Sau khi mua robot định lượng, đừng quên kích hoạt chiến lược, nếu không sẽ không có thu nhập. Thời gian hiệu lực của robot định lượng được tính theo thời gian mua, hãy kích hoạt chiến lược giao dịch định lượng kịp thời, nếu robot định lượng hết hạn mà chưa được kích hoạt, bạn sẽ mất chi phí mua gói chiến lược giao dịch định lượng của mình! Các người dùng tham gia giao dịch định lượng Plus500Ai hãy đọc kỹ quy định này!!",  
    会员体验: "Trải nghiệm thành viên",  
    机器人采购: "Mua robot",  
    机器人启动押金: "Số tiền POS của nút",  
    机器人礼物: "Quà từ nút",  
    机器人订单: "Đơn hàng từ nút",  
    一次性总和: "Hiển thị",  
    总计: "Tổng cộng",  
    被激活: "Đã POS",  
    工作中: "Đã kích hoạt",  
    暂停: "Tạm dừng POS",
  结束: 'Đã hết hạn',  
    刷新: 'Làm mới',  
    来源: 'Nguồn',  
    价格: 'Giá gói định lượng',  
    启动押金: 'Số tiền POS',  
    剩余时间: 'Thời gian còn lại',  
    激活时间: 'Thời gian kích hoạt',  
    号: 'Số',  
    我的资产: 'Tài sản của tôi',  
    退出: 'Đăng xuất',  
    邀请码: 'Mã mời',  
    我的钱包余额: 'Số dư ví của tôi',  
    我的机器人仓库: 'Danh sách robot của tôi',  
    收益面板: 'Bảng thu nhập',  
    去提款: 'Rút tiền',  
    昨天代理返利: 'Hoa hồng đại lý hôm qua',  
    累计代理返利: 'Tổng hoa hồng đại lý',  
    今天机器人收益: 'Thu nhập giao dịch hôm nay',  
    累计机器人收益: 'Tổng thu nhập giao dịch',  
    累计机器人回扣: 'Tổng hoa hồng giao dịch',  
    累计总回报: 'Tổng thu nhập',  
    自动订单机器人数量: 'Số lượng robot giao dịch đã kích hoạt',  
    我的付款方式: 'Phương thức thanh toán của tôi',  
    个人收入记录: 'Thu nhập cá nhân',  
    团队收入记录: 'Thu nhập nhóm',  
    记录详情: 'Chi tiết ghi chép',  
    充值记录: 'Ghi chép nạp tiền',  
    我的团队成员: 'Thành viên trong nhóm của tôi',  
    活动中心: 'Giới thiệu quy tắc',  
    修改登录密码: 'Cài đặt mật khẩu đăng nhập',  
    交易密码管理: 'Cài đặt mật khẩu giao dịch',  
    机器人: 'Chiến lược giao dịch AI',  
    机器人性能: 'Bảng điều khiển robot AI',  
    机器人价格: 'Giá chiến lược giao dịch',  
    有效期: 'Thời gian hiệu lực giao dịch',  
    钱包地址: 'Địa chỉ ví',  
    天: 'Ngày',  
    去购买: 'Mua',  
    买个机器人: 'Mua robot giao dịch định lượng',  
    我的钱包余额: 'Số dư tài khoản của tôi',  
    预计日收益: 'Lợi nhuận hàng ngày dự kiến',  
    启动保证金: 'Tiền ký quỹ khởi động',  
    请输入购买数量: 'Vui lòng nhập số lượng mua',  
    个机器人: 'Robot giao dịch định lượng',  
    机器人购买后需要激活才能工作: 'Sau khi mua nút, cần số tiền POS tương ứng để bắt đầu kiếm lợi',  
    机器人激活需要从余额中扣除相应的启动押金: 'Khai thác POS nút cần trừ số tiền POS tương ứng từ số dư tài khoản',  
    机器人暂停机器人停止时将返还启动押金不活跃的机器人可以赠送: 'Khi tạm dừng khai thác POS nút, có thể ngay lập tức hoàn lại số tiền POS',  
    购买: 'Mua',  
    点击去了解我们: 'Giao dịch định lượng',  
    购买机器人: 'Mua robot',  
    快速入口: 'Cổng nhanh',  
    '日/个人受益': 'Ngày/Thu nhập cá nhân',  
    购买教程: 'Danh sách robot giao dịch AI',  
    激活金额: 'Phí POS',  
    订单数量: 'Số lượng đơn hàng',  
    我们的合作伙伴: 'Đối tác thị trường tiền điện tử của chúng tôi',  
    提款: 'Rút tiền',  
    提款金额: 'Số tiền rút',  
    你还没有添加银行卡: 'Bạn chưa thêm thẻ ngân hàng',  
    提现说明: 'Hướng dẫn rút tiền',  
    说明1: '1. Bạn có thể chọn một lần hoặc nhập số tiền nạp thủ công',  
    说明2: '2. Khuyên dùng USD (TRC20), phí chuyển khoản thấp nhất',  
    说明3: '3. Thời gian phục vụ khách hàng là từ thứ Hai đến thứ Sáu, từ 8 AM đến 6 PM theo giờ Trung Mỹ',  
    说明4: 'Người dùng nạp USD cần tải lên ảnh chụp màn hình chuyển khoản thành công và TXID chính xác, sau khi kiểm tra và phê duyệt của nền tảng, sẽ thành công vào tài khoản',  
    说明5: '4. Số tiền nạp tối thiểu là 8 USD, để hệ thống nhanh chóng kiểm tra và xử lý vào tài khoản',  
    说明6: '5. Nếu bạn có bất kỳ vấn đề nào về việc nạp tiền, vui lòng liên hệ với dịch vụ khách hàng trực tuyến để được hỗ trợ',  
    确认提款: 'Xác nhận rút tiền',  
    请输入手机号码: 'Số điện thoại',  
    请输入提现金额: 'Số tiền rút',  
    提现费: 'Phí rút tiền',  
    提现须知: 'Vui lòng đọc kỹ quy tắc rút tiền',  
    TIPS1: '1: Thời gian kiểm tra rút tiền hàng ngày là từ thứ Hai đến thứ Sáu, từ 8 AM đến 6 PM. Cuối tuần có thể rút tiền, thời gian kiểm tra rút tiền là vào thứ Hai',  
    TIPS2: '2: Chế độ 24/7, có thể rút tiền bất cứ lúc nào, muộn nhất là 24 giờ vào tài khoản, nhanh nhất là 5 phút vào tài khoản',  
    TIPS3: '3: Thời gian phục vụ khách hàng trực tuyến: từ thứ Hai đến thứ Sáu, từ 8 AM đến 6 PM',  
    TIPS4: '4: Do chi phí chuyển USD, nền tảng sẽ thu phí khi người dùng rút tiền',  
    TIPS5: '5: Số tiền rút tối thiểu là 10 USD',  
    钱包余额: 'Số dư ví',  
    输入数量: 'Nhập số lượng',  
    数量: 'Số lượng',
  手机号码: "Số điện thoại",  
    手机号码已存在: "Số điện thoại đã được đăng ký",  
    邮箱: "Email",  
    输入手机号码: 'Nhập số điện thoại',  
    请输入登录密码: 'Mật khẩu đăng nhập',  
    登录: 'Đăng nhập',  
    输入账号: 'Nhập tài khoản',  
    密码: 'Mật khẩu',  
    点击注册: 'Chưa có tài khoản? Tạo ngay một tài khoản!',  
    忘记密码: 'Quên mật khẩu',  
    重新登录: 'Đăng nhập lại',  
    密码找回成功: 'Khôi phục mật khẩu thành công',  
    找回成功请重新登录: 'Khôi phục thành công, vui lòng đăng nhập lại',  
    确认: 'Xác nhận',  
    发送验证码: 'Gửi mã xác nhận',  
    再次输入密码: 'Nhập lại mật khẩu',  
    输入验证码: 'Nhập mã CAPTCHA',  
    输入手机验证码: "Nhập mã xác nhận điện thoại",  
    输入手机号: 'Nhập số điện thoại',  
    输入密码: 'Nhập mật khẩu',  
    快速注册: 'Đăng ký nhanh',  
    注册: 'Đăng ký',  
    立即登录: 'Đã có tài khoản, nhấn để đăng nhập',  
    请输入邮箱: 'Nhập địa chỉ email',  
    输入用户名: 'Nhập tên người dùng',  
    请输入邀请码: 'Vui lòng nhập mã mời',  
    返回: 'Quay lại',  
    密码找回失败: 'Khôi phục mật khẩu thất bại',  
    输入的信息有误: 'Thông tin nhập vào không chính xác',  
    获取验证码: "Lấy mã xác nhận",  
    手机号码不能为空: "Số điện thoại không được để trống",  
    账号不存在: "Tài khoản không tồn tại",  
    发送成功: "Gửi thành công",  
    今日收益: "（Thu nhập hiện tại）",  
    "您好如果您中途终止POS-STAKING系统将收取5%的STAKING费用": "Xin chào, nếu bạn dừng hệ thống POS giữa chừng, hệ thống sẽ thu phí quản lý POS 5%. Lưu ý: Các nút trải nghiệm miễn phí sẽ không thể kiếm lại sau khi tạm dừng POS và sẽ không nhận được bất kỳ khoản phí nào!!",  
    我同意: "Tôi đồng ý",  
    用户账号ID: "ID tài khoản",  
    取消: "Hủy bỏ",  
    日收益: "Thu nhập hàng ngày",  
    请输入购买数量: "Vui lòng nhập số lượng mua",  
    数量不能为空: "Số lượng không được để trống",  
    加载中: "Đang tải...",  
    唯一码: "Mã robot",  
    未激活: "Chưa kích hoạt",  
    激活: "Kích hoạt chiến lược",  
    购买时间: "Thời gian mua",  
    "钱包余额不足，激活该机器人失败": "Số dư tài khoản không đủ, không thể kích hoạt robot này",  
    
    运行时长: "Thời gian hoạt động",  
    签约购买: "Mua bằng số dư",  
    系统赠送: "Quà tặng từ hệ thống",  
    状态: "Trạng thái",  
    正常: "Bình thường",  
    我的机器人: "Robot giao dịch định lượng của tôi",  
    一级会员: "Cấp 1",  
    二级会员: "Cấp 2",  
    三级会员: "Cấp 3",  
    人: "",  
    充值客服: "Dịch vụ khách hàng nạp tiền",  
    充值: "Nạp tiền",  
    提现: "Rút tiền",  
    提款密码: "Mật khẩu rút tiền",  
    设置交易密码: "Cài đặt mật khẩu giao dịch",  
    登录密码: "Mật khẩu đăng nhập",  
    请输入交易密码: "Nhập mật khẩu giao dịch",  
    再次请输入交易密码: "Nhập lại mật khẩu giao dịch",  
    确认: 'Xác nhận',  
    手机号码不能为空: "Số điện thoại không được để trống",  
    两次密码不一致: "Hai mật khẩu không khớp",  
    请输入验证码: "Vui lòng nhập mã xác nhận",  
    操作成功: "Thao tác thành công",  
    "用户名或密码不正确,登录失败": "Tên người dùng hoặc mật khẩu không chính xác, đăng nhập thất bại",  
    登录成功: "Đăng nhập thành công",  
    充值说明: "Hướng dẫn nạp tiền",  
    请先设置支付密码: "Vui lòng cài đặt mật khẩu giao dịch trước",  
    复制成功: "Sao chép thành công",  
    冻结机器人做单本金: "Vốn giao dịch định lượng bị đóng băng",  
    待审核: "Đang chờ phê duyệt...",  
    成功: "Thành công",  
    失败: "Thất bại",  
    审核中: "Đang phê duyệt",  
    在线充值: "Nạp tiền trực tuyến",  
    描叙: "Mô tả",  
    POS节点挖矿LV0: "Khai thác nút POS LV0",  
    POS节点挖矿LV1: "Khai thác nút POS LV1",  
    POS节点挖矿LV2: "Khai thác nút POS LV2",  
    POS节点挖矿LV3: "Khai thác nút POS LV3",  
    POS节点挖矿LV4: "Khai thác nút POS LV4",  
    POS节点挖矿LV5: "Khai thác nút POS LV5",  
    银行卡提现: "Rút tiền qua thẻ ngân hàng",  
    USD提现: "Rút USD",  
    三级代理: "Đại lý cấp 3",  
    一级代理: "Đại lý cấp 1",  
    二级代理: "Đại lý cấp 2",  
    一级: "Cấp 1",  
    二级: "Cấp 2",  
    三级: "Cấp 3",  
    做单: "Giao dịch định lượng",  
    登录失败: "Đăng nhập thất bại",  
    请勿重复操作: "Vui lòng không thao tác lặp lại",  
    邀请码不正确: "Mã mời không chính xác",  
    团队返佣: "Hoa hồng nhóm",  
    购买机器人返佣: "Hoa hồng robot",
	本金返还: "Hoàn lại vốn",  
	  佣金收入: "Thu nhập hoa hồng",  
	  时间: "Thời gian",  
	  机器人做单返还: "Hoàn lại số tiền POS",  
	  涨幅: "Tăng trưởng",  
	  市场: "Thị trường",  
	  客服: "Dịch vụ khách hàng",  
	  验证码错误: "Mã xác nhận không chính xác",  
	  付款方式: "Phương thức rút tiền",  
	  我的留言: "Tin nhắn của tôi",  
	  暂无数据: 'Không có dữ liệu',  
	  银行卡: 'Thẻ ngân hàng',  
	  修改: 'Sửa đổi',  
	  '请确保您的钱包地址正确，如果您的地址输入错误而导致收不到钱，请及时联系在线客服协助': 'Vui lòng đảm bảo địa chỉ ví của bạn chính xác. Nếu địa chỉ nhập sai dẫn đến không nhận được tiền, vui lòng liên hệ với dịch vụ khách hàng trực tuyến để được hỗ trợ.',  
	  连接借记卡: "Kết nối thẻ ghi nợ",  
	  银行名称: 'Tên ngân hàng',  
	  姓名姓氏: 'Họ và tên',  
	  IBAN号码: 'Số IBAN',  
	  '* 重要提示:借记卡信息必须真实有效才能取款。': '* Lưu ý quan trọng: Thông tin thẻ ghi nợ phải chính xác và hợp lệ để rút tiền.',  
	  帮助中心: 'Trung tâm trợ giúp',  
	  会员须知: 'Quy định thành viên',  
	  '24小时内使用USD实时取款（推荐）': '1. Sử dụng USD để rút tiền trực tiếp trong vòng 24 giờ (được khuyến nghị)',  
	  '银行卡 取款 *取款时间为10:00-18:00 *发起取款后2小时内入账*申请非取款时间或跨行取款将于次日10:00-18:00到账': '2. Rút tiền qua thẻ ngân hàng *Thời gian rút tiền từ 10:00-18:00 *Tiền sẽ vào tài khoản trong vòng 2 giờ sau khi yêu cầu rút tiền *Yêu cầu rút tiền ngoài giờ hoặc rút tiền liên ngân hàng sẽ vào tài khoản vào ngày hôm sau từ 10:00-18:00',  
	  我知道了: 'Tôi đã biết',  
	  链接点击: 'Liên kết mời: ',  
	  确定: 'Xác nhận',  
	  建立自己的团队邀请: 'Xây dựng nhóm của riêng bạn để mời',  
	  此账号已冻结: "Tài khoản này đã bị khóa",  
	  手机号存已存在: "Số điện thoại đã tồn tại",  
	  注册账号已存在: "Tài khoản đã được đăng ký",  
	  请确定新密码: "Vui lòng xác nhận mật khẩu mới",  
	  请再次输入登录密码: "Vui lòng nhập lại mật khẩu đăng nhập",  
	  密码长度不能少于6位: "Độ dài mật khẩu không được ít hơn 6 ký tự",  
	  加入: 'Tham gia',  
	  "你出售的机器人对方已接受,出售所得已打款到您账户,请查收。": "Robot bạn bán đã được bên kia chấp nhận, số tiền bán đã được chuyển vào tài khoản của bạn, vui lòng kiểm tra.",  
	  "你出售的机器人对方已拒绝接受": "Robot bạn bán đã bị bên kia từ chối",  
	  向你赠送一台机器人: "Tặng bạn một robot, đang chờ xác nhận",  
	  向你出售一台机器人: "Bán cho bạn một robot, đang chờ xác nhận",  
	  出售价格不能超过机器人价格: "Giá bán không được vượt quá giá robot",  
	  银行卡已被绑定: "Thẻ ngân hàng đã được liên kết",  
	  USD_TRC20提现: 'Rút USD_TR20',  
	  USD_ERC20提现: 'Rút USD_ERC20',  
	  银行卡提现: 'Rút tiền qua thẻ ngân hàng',  
	  邀请赠送: 'Tiền thưởng mời',  
	  机器人返佣: 'Hoa hồng robot',  
	  升级: 'Mua thành công',  
	  充值主网: 'Nạp tiền vào mạng chính',  
	  "您被限制出售机器人,请联系客服了解详情": "Bạn bị hạn chế bán robot, vui lòng liên hệ với dịch vụ khách hàng để biết thêm chi tiết",  
	  交易编号: "Mã giao dịch",  
	  消息详情: "Chi tiết tin nhắn",  
	  个人收益累计: "Tổng thu nhập cá nhân",  
	  今日代理收益: "Thu nhập đại lý hôm nay",  
	  代理累计收益: "Tổng thu nhập đại lý",  
   截图保存推荐给朋友: "Chia sẻ liên kết mời của bạn để mời bạn bè tham gia nền tảng Plus500Ai và kiếm hoa hồng thu nhập nhóm. Thành viên nhóm cấp 3 nhận hoa hồng 8%-3%-2%, hoa hồng nhóm sẽ tự động được tính vào số dư tài khoản của bạn dựa trên dữ liệu thu nhập hàng ngày của các thành viên cấp dưới, vào lúc 00:00 mỗi ngày.",  
     复制: "Sao chép",  
     充值提示1: "Lưu ý 1: Số tiền nạp tối thiểu là 5 USD, nếu không sẽ không được ghi có.",  
     充值提示2: "Lưu ý 2: Nạp tiền cần có xác nhận trên chuỗi, sau khi nạp thành công, dự kiến sẽ vào tài khoản trong khoảng một phút.",  
     请输入正确的充值金额: "Số tiền nạp phải ≥ 8 USD",  
     推荐: "Giới thiệu",  
     充值金额: "Số tiền nạp",  
     请上传付款截图: "Vui lòng tải lên ảnh chụp màn hình thanh toán thành công",  
     交易号: "Mã giao dịch",  
     充值ID提示: "Vui lòng dán mã Txid giao dịch của bạn",  
     "请确保钱包地址无误，如因钱包地址填写错误导致无法收款而造成的损失，将有用户自己承担。": "Vui lòng đảm bảo địa chỉ ví chính xác, nếu do sai địa chỉ ví mà không nhận được tiền, người dùng sẽ tự chịu trách nhiệm.",  
     上传凭证: "Tải lên chứng từ thanh toán",  
     充值确认: "Gửi",  
     不能为空: "Không được để trống",  
     绑定钱包地址: "Liên kết địa chỉ ví",  
     备注: "Ghi chú",  
     请输入银行卡号: "Vui lòng nhập số thẻ ngân hàng",  
     请输入你的名字: "Vui lòng nhập tên của bạn",  
     银行卡号: "Số thẻ ngân hàng",  
     添加银行卡: "Thêm thẻ ngân hàng",  
     请选择银行: "Vui lòng chọn ngân hàng",  
     请输入钱包地址: "Vui lòng nhập địa chỉ ví",  
     钱包地址: "Địa chỉ ví",  
     "密码错误次数过多,请等待x秒后再试": "Số lần nhập sai mật khẩu quá nhiều, vui lòng chờ 600 giây trước khi thử lại",  
     "此账号已冻结,请联系客服": "Tài khoản này đã bị khóa, vui lòng liên hệ với dịch vụ khách hàng",  
     手机号格式错误: "Định dạng số điện thoại không chính xác",  
     "取款须知": "Lưu ý rút tiền",  
     "须知1": "1. Sử dụng USD-TRC20 để rút tiền trực tiếp trong vòng 24 giờ (được khuyến nghị)",  
     "须知2": "2. Rút tiền vào thứ Bảy và Chủ nhật",  
     "须知3": "* Người dùng có thể rút tiền vào thứ Bảy và Chủ nhật",  
     "须知4": "* Rút tiền vào cuối tuần sẽ được ghi có vào thứ Hai từ 10AM đến 8PM",  
     我知道了: "Tôi đã biết",  
     "提现0": "1USD=1USD ERC20",  
     "提现1": "Lưu ý 1: Mỗi lần rút tiền tối thiểu là 5USD/ngày, phí giao dịch mỗi lần là 5USD.",  
     "提现2": "Lưu ý TRC20: Mỗi lần rút tiền tối thiểu là 5USD, phí giao dịch mỗi lần là 1USD.",  
     "提现3": "Lưu ý tài khoản ngân hàng: Mỗi lần rút tiền tối thiểu là 5USD, phí giao dịch là 5% trên số tiền giao dịch.",  
     "提现4": "Thời gian rút tiền: Tiền sẽ được ghi có trong vòng 24 giờ sau khi gửi yêu cầu rút tiền.",  
     
     已绑定: "Đã liên kết",  
     去设置: "Đi đến cài đặt",  
     注册成功: "Đăng ký thành công",  
     汇率: "Tỷ giá",  
     请输入正确的提现金额: "Vui lòng nhập số tiền rút chính xác",  
     提款记录: "Lịch sử rút tiền",  
     "绑定的钱包地址必须跟付款钱包一致，否则不会到账。": "Tip 1: Địa chỉ ví đã liên kết phải giống với ví thanh toán, nếu không sẽ không được ghi có.",  
     "充值成功后预计在一分钟左右到账，请检查钱包约。": "Tip 2: Sau khi nạp tiền thành công, dự kiến sẽ vào tài khoản trong khoảng một phút, vui lòng kiểm tra số dư ví.",  
     请先绑定钱包地址: "Vui lòng liên kết địa chỉ ví trước",  
     输入邮箱: "Nhập email",  
     邮箱已存在: "Email đã tồn tại",  
     注册邮箱已存在: "Email đã đăng ký tồn tại",  
     指定用户不存在: "Người dùng chỉ định không tồn tại",  
     今日涨幅: "Biến động hôm nay",  
     恭喜新用户: "Chúc mừng người dùng mới",  
     快捷充币: "Nạp tiền nhanh",  
     快速买USD: "Mua USD nhanh",  
     在线支付: "Thanh toán trực tuyến",  
     自动到账: "Tự động ghi có",  
     线下支付: "Thanh toán ngoại tuyến",  
     联系客服获取验证码: "Liên hệ dịch vụ khách hàng để nhận mã xác nhận",  
     获取: "Nhận",  
     退出成功: "Đăng xuất thành công",  
     不能低于最小充值: "Không được thấp hơn số tiền nạp tối thiểu",  
     不能低于最小提现金额: "Không được thấp hơn số tiền rút tối thiểu",  
     最小提现: "Số tiền rút tối thiểu",  
     设置成功: "Cài đặt thành công",  
     官方充币: "Nạp tiền chính thức",  
     银行卡充值: "Nạp tiền qua thẻ ngân hàng",
	等待审核: "Chờ phê duyệt",  
	  可提现金额不足: "Số tiền có thể rút không đủ",  
	  未经授权: "Chưa được ủy quyền",  
	  交易密码不正确: "Mật khẩu giao dịch không chính xác",  
	  提现次数不足: "Số lần rút tiền không đủ",  
	  官网: "Trang web chính thức",  
	  账户USD不足: "Số dư tài khoản USD không đủ",  
	  激活成功: "Kích hoạt thành công",  
	  操作成功: "Hoạt động thành công",  
	  修改成功: "Sửa đổi thành công",  
	  注册说明: "Mã OTP điện thoại và CAPTCHA email chỉ cần xác minh một trong hai cách để đăng ký thành công",  
	  弹窗内容: "Chào mừng bạn đến với nền tảng giao dịch định lượng Plus500Ai. Mỗi người dùng có thể sử dụng khoản tiền thưởng đăng ký 1 USD để mua gói chiến lược giao dịch robot định lượng NO.0, có hiệu lực trong 1 ngày, miễn phí kiếm 0.5 USD. Các gói chiến lược giao dịch định lượng khác cần phải trả phí tương ứng để sử dụng. Người dùng sau khi mua robot cần kích hoạt chiến lược giao dịch định lượng để tự động bắt đầu hoạt động, mỗi chiến lược giao dịch có thời hạn hiệu lực khác nhau. Nếu người dùng không nhấp vào kích hoạt chiến lược sau khi mua robot tương ứng, bạn sẽ không nhận được bất kỳ lợi nhuận giao dịch nào.",  
	  标题1: '1. Giới thiệu về nền tảng Plus500',  
	  标题2: '2. Ưu điểm của giao dịch định lượng AI',  
	  标题3: '3. Tại sao chọn Plus500Ai?',  
	  标题4: '4. Cách tham gia Plus500Ai',  
	  标题5: '5. Triển vọng thị trường của Plus500Ai',  
	  标题6: '6. Mô hình thu nhập của Plus500Ai',  
	  标题7: '7. Quy định nạp và rút tiền',  
	  标题8: "Cách chơi trò chơi sinh tồn",  
	  内容8: "Trong trò chơi sinh tồn của nền tảng Plus500Ai có 8 phòng, người chơi có thể chọn bất kỳ phòng nào trong 8 phòng để đặt cược vàng. Sau khi hệ thống đếm ngược kết thúc, hệ thống trò chơi sẽ xuất hiện một kẻ giết người, kẻ giết người sẽ ngẫu nhiên vào một phòng và tiêu diệt người trong phòng đó, những người chơi khác có thể chia sẻ số vàng trong phòng đó để kiếm lợi nhuận. Cách chơi trò chơi sinh tồn khá ngắn, mỗi ván chỉ kéo dài vài phút, người chơi có thể chơi trong thời gian rảnh. Số tiền chia sẻ = Tổng số tiền của phòng mà kẻ giết người đã tiêu diệt. Số tiền thực tế chia sẻ = Số tiền chia sẻ * 0.9 (tỷ lệ bị tiêu hủy là 0.1). Tỷ lệ chia sẻ = Số tiền thực tế chia sẻ / Tổng số tiền đặt cược của các phòng khác. Số tiền thắng = Tỷ lệ chia sẻ * Số tiền đặt cược thực tế. 1 vàng = 1 USD, tối thiểu cần 10 USD để tham gia trò chơi sinh tồn",  
	  内容1: 'Nền tảng giao dịch Plus500 được cung cấp bởi Plus500SEY Ltd. Do đó, Plus500SEY Ltd là nhà phát hành và bán các sản phẩm tài chính được mô tả hoặc cung cấp trên trang web này. Plus500SEY Ltd là một công ty có trụ sở tại Seychelles (số công ty 8426415-1) với văn phòng tại Victoria. Công ty được ủy quyền và quản lý bởi Cơ quan Quản lý Dịch vụ Tài chính Seychelles (FSA), số giấy phép SD039, cung cấp hợp đồng chênh lệch (CFD) cho nhiều sản phẩm cơ bản. Công ty là một nhà cung cấp CFD đang phát triển nhanh chóng, hiện cung cấp danh mục đầu tư hơn 2800 công cụ tài chính. Plus500SEY Ltd là công ty con của Plus500 Ltd, Plus500 Ltd đã được niêm yết trên sàn giao dịch chứng khoán London, có trụ sở tại Haifa.',  
	内容2:'Ưu điểm của giao dịch định lượng hoàn toàn tự động AI là sử dụng các công cụ hợp lý để vận hành các giao dịch theo cài đặt mô hình. Robot không có sự thay đổi về cảm xúc và có thể vượt qua bản chất con người, lòng tham và nỗi sợ hãi của con người cũng như nhiều khuyết điểm, điểm yếu khác trong quá trình vận hành. Hoạt động thủ công thường bị ảnh hưởng bởi sự lên xuống của thị trường và nhiều yếu tố khác và không thể hoạt động theo mô hình lợi nhuận tốt nhất trong thời gian dài.',
	内容3:'Plus500Ai đã dần củng cố vị thế của mình như một tập đoàn fintech đa tài sản bằng cách mở rộng các sản phẩm cốt lõi của mình sang các thị trường mới và hiện có, tung ra các sản phẩm tài chính và giao dịch mới, đồng thời tăng cường gắn kết với khách hàng. Bảo vệ tài sản của bạn và bảo vệ khóa mã hóa của bạn bằng công nghệ bảo mật hàng đầu trong ngành. Plus500Ai cũng cung cấp quyền giám sát toàn cầu, có nghĩa là chúng tôi được quản lý, kiểm toán và bảo hiểm lên tới 250 triệu USD. Triển khai vốn của bạn với mục tiêu không chỉ là nắm giữ tài sản mà còn đưa chúng vào hoạt động. Plus500Ai cho phép bạn triển khai tiền của mình theo nhiều cách khác nhau, từ giao dịch định lượng Ai đến các dịch vụ chính cổ điển, tùy thuộc vào khả năng chấp nhận rủi ro của bạn. Nền tảng trực tuyến để cung cấp nhiều dịch vụ hơn cho người dùng của bạn mà không cần thuê thêm kỹ sư blockchain.',
	内容4:'Kể từ khi thành lập Plus500Ai, chỉ có một trang web chính thức là WWW.PLUS500AI.COM. Tất cả người dùng cần đăng nhập vào trang web chính thức để tải xuống APP vì các trang web khác không phải của công ty chúng tôi. Để đăng ký, bạn cần gửi số điện thoại di động, địa chỉ email của người dùng và mã mời của người giới thiệu để đăng ký nhanh chóng. Mọi liên lạc với khách hàng đều bằng văn bản, qua email hoặc trò chuyện trực tuyến. Để liên hệ với Plus500 qua email, vui lòng điền vào biểu mẫu trên trang "Liên hệ với chúng tôi" ("Mẫu yêu cầu"). Sau khi gửi đơn đăng ký, Plus500 sẽ gửi trả lời qua email trực tiếp đến địa chỉ email bạn đã chỉ định trong đơn đăng ký. Nếu bạn có bất kỳ câu hỏi nào, vui lòng liên hệ với dịch vụ khách hàng.',
	内容5:'Plus500Ai hoạt động trên nhiều sản phẩm, thiết bị và quốc gia để mang đến cho khách hàng những cơ hội giao dịch tài chính trực quan và đáng tin cậy. Chúng tôi luôn theo đuổi sự xuất sắc trong các sản phẩm và đội ngũ của mình. Với công nghệ độc quyền tiên tiến và cách tiếp cận lấy khách hàng làm trung tâm, người dùng của chúng tôi sẽ nhận được trải nghiệm dịch vụ giao dịch hạng nhất.',
	内容6:'Plus500Ai cung cấp các chiến lược giao dịch định lượng chuyên nghiệp cho thị trường tiền điện tử bởi nhóm các nhà phân tích dữ liệu cấp cao. Người dùng đầu tư vào robot giao dịch định lượng Ai có thể thu được lợi nhuận giao dịch định lượng bằng cách dựa vào các chiến lược giao dịch phù hợp với robot. Robot giao dịch định lượng Ai phân tích theo chu kỳ thời gian giao dịch tối ưu dựa trên chiến lược giao dịch thị trường của Plus500Ai và thu được lợi nhuận giao dịch định lượng thông qua mô hình mua và bán ở mức giá cao. Plus500Ai cung cấp cho người dùng đầu tư các chiến lược giao dịch định lượng tối ưu mỗi ngày và nền tảng trích 5% lợi nhuận thu nhập định lượng của người dùng làm quỹ vận hành nền tảng.',
	内容7:'Plus500Ai hiện hỗ trợ gửi và rút USD và sẽ có thông báo trên trang web chính thức nếu các kênh khác được mở trong tương lai. Số tiền gửi và rút tối thiểu đều là 10 USD. Thời gian gửi và rút tiền nhanh nhất là trong vòng 5 phút và chậm nhất là trong vòng 24 giờ. Không có giới hạn về thời gian rút tiền của người dùng, bạn có thể rút tiền bất cứ lúc nào. Việc rút tiền sẽ đến trong vòng 5 phút - 24 giờ vào các ngày làm việc bình thường. Rút tiền cuối tuần được sắp xếp để được xử lý vào thứ Hai.',
	BSC提现: "Rút tiền USD_BSC",  
	  社区Ads收益: "Doanh thu Ads cộng đồng",  
	  Ads收益区间: "Doanh thu Ads từ 1-1000$",  
	  申请Ads费用: "Xin cấp phí Ads",  
	  Ads促销地址: "Địa chỉ khuyến mãi Ads",  
	  填写Ads促销地址: "Điền địa chỉ khuyến mãi Ads",  
	  申请Ads费用须知: "Thông tin cần biết khi xin cấp phí Ads",  
	  须知A: "1. Người dùng ghi lại video khuyến mãi và tải lên kênh YouTube, Facebook hoặc các cộng đồng khác để quảng bá Ads, xin thưởng từ 1-1000$. Sau khi gửi đơn xin, nền tảng sẽ xem xét video Ads của bạn trong vòng 3 ngày và sẽ chuyển thưởng vào số dư tài khoản của bạn dựa trên hiệu quả khuyến mãi của video. Video không có hiệu quả khuyến mãi sẽ không được phê duyệt.",  
	  须知B: "2. Video quảng cáo Ads trên cùng một kênh chỉ có thể xin thưởng một lần, mỗi tuần có thể phát hành một video khuyến mãi mới. Không gửi yêu cầu lặp lại một cách ác ý. Nếu bạn nhiều lần gửi yêu cầu không phải do chính mình tạo ra hoặc video không đạt yêu cầu, tài khoản của bạn sẽ bị cấm sử dụng.",  
	  须知C: "3. Video quảng cáo Ads cần có giới thiệu cơ bản về nền tảng và giải thích chức năng của nền tảng, thời gian video không dưới 5 phút. Số lượng người đăng ký kênh của bạn càng nhiều, hiệu quả quảng bá video càng tốt, số phí Ads có thể xin càng cao. Cụ thể phí Ads tham khảo như sau, ví dụ với YOUTUBE:",  
	  须知D: "Người đăng ký 0-2K: số tiền xin từ 1-20$",  
	  须知D1: "Người đăng ký 3K-10K: số tiền xin từ 21-40$",  
	  须知D2: "Người đăng ký 11K-30K: số tiền xin từ 41-100$",  
	  须知D3: "Người đăng ký 31K-50K: số tiền xin từ 101-300$",  
	  须知D4: "Người đăng ký 51K-100K: số tiền xin từ 301-1000$",  
	  须知D5: "4. Hệ thống sẽ xem xét đơn xin thưởng Ads vào mỗi Chủ nhật, người dùng đủ điều kiện sẽ nhận được thưởng từ hệ thống.",  
	  请求审核: "Xin phê duyệt",  
	  申请金额: "Số tiền xin",  
	  URL促销地址: "Địa chỉ khuyến mãi URL",  
	  提交时间: "Thời gian gửi",  
	  需要更新的银行卡不存在: "Không tồn tại thẻ ngân hàng cần cập nhật",  
	  银行卡提现: "Rút tiền qua thẻ ngân hàng",  
	  预计收入: "Dự kiến thu nhập",  
	  总投资: "Tổng đầu tư",  
	  购买价: "Giá mua",  
	  邀请: "Mời",  
	  成员: "Thành viên",  
	  积分等级折扣规则介绍3: "Mức giảm giá này chỉ có hiệu lực đến ngày 31 tháng 12 năm 2023, sau ngày 1 tháng 1 năm 2024 sẽ có điều chỉnh mới.",  
	  LV1佣金: "Hoa hồng CẤP 1",  
	  LV2佣金: "Hoa hồng CẤP 2",  
	  LV3佣金: "Hoa hồng CẤP 3",  
	  合格条件: "Mỗi người tối thiểu nạp 8USD",  
	  随时可退: "Có thể rút bất cứ lúc nào",  
	  登录密码错误: "Mật khẩu đăng nhập không chính xác",  
	  充值奖金: "Nạp USD ≥ 50USD, thưởng 3%",  
	  充值奖金1: "Nạp IDR ≥ 50USD, thưởng 2%",  
	  充值奖金2: "Nạp THB ≥ 50USD, thưởng 5%",  
	不能重复发送短信:"Không thể gửi tin nhắn lặp lại trong vòng 10 phút. Khuyên bạn nên sử dụng EMAIL CAPTCHA để đăng ký trực tiếp.",
	限购说明1: "Xin chào, gói chiến lược giao dịch robot định lượng NO.0 chỉ có thể được mua một lần cho mỗi người dùng.",  
	  活动已过期: "Hoạt động đã hết hạn",  
	  修改银行提示: "Tài khoản ngân hàng rút tiền không thể thay đổi sau khi đã liên kết, nếu cần hỗ trợ, vui lòng liên hệ với dịch vụ khách hàng.",  
	  修改钱包地址提示: "Địa chỉ rút USD không thể thay đổi sau khi đã liên kết, nếu cần hỗ trợ, vui lòng liên hệ với dịch vụ khách hàng.",  
	  注册推送消息1: "Chào mừng bạn đến với INGSAI, người dùng mới khởi động nút khai thác POS sẽ nhận được thu nhập khai thác POS mỗi 5 phút. Doanh thu hoa hồng đội nhóm sẽ tự động vào số dư tài khoản vào khoảng 00:00 mỗi ngày.",  
	  限购说明: "Xin chào, số lần mua tối đa cho gói robot định lượng loại này là 10 lần, khuyên bạn nên mua các gói giao dịch định lượng khác.",  
	  注册协议: "Bằng cách nhấn 'Tạo tài khoản', bạn xác nhận rằng bạn đã đủ 18 tuổi. Bạn đã biết rằng Plus500AI có thể sử dụng địa chỉ email hoặc thông tin cá nhân khác để liên lạc với bạn một cách điện tử, cung cấp thông tin liên quan đến sản phẩm/dịch vụ của họ. Chúng tôi cũng có thể thông báo cho bạn về các sự kiện thị trường, giúp bạn tối đa hóa trải nghiệm giao dịch của mình. Bạn có thể quản lý và chỉnh sửa tùy chọn thông báo của mình trong tab 'Cài đặt thông báo' và 'Cài đặt quyền riêng tư' trên nền tảng. Bạn có thể hủy đăng ký thông tin này bất cứ lúc nào. Để biết thêm thông tin, vui lòng xem 'Thỏa thuận quyền riêng tư' của chúng tôi.",  
	  公司说明1: "Plus500AI là thương hiệu đã đăng ký của Plus500 Ltd. Plus500 Ltd hoạt động thông qua các công ty con sau đây:",  
	  公司说明2: "Plus500SEY Ltd được ủy quyền và quản lý bởi Cơ quan Quản lý Dịch vụ Tài chính Seychelles (số giấy phép SD039).",  
	  公司说明3: "Plus500SEY Ltd là nhà phát hành và bán các sản phẩm tài chính được mô tả hoặc có sẵn trên trang web này.",  
	  公司说明4: "Plus500UK Ltd được ủy quyền và quản lý bởi Cơ quan Quản lý Hành vi Tài chính. Số đăng ký của Cơ quan Quản lý Hành vi Tài chính: 509909. CFD tiền điện tử không được cung cấp cho người dùng bán lẻ.",  
	  公司说明5: "Plus500CY Ltd được ủy quyền và quản lý bởi Ủy ban Chứng khoán và Giao dịch Cyprus (mã ủy quyền: 250/14). CFD tiền điện tử không được cung cấp cho người dùng bán lẻ tại Vương quốc Anh.",  
	  公司说明6: "Plus500AU Pty Ltd sở hữu các giấy phép sau: Giấy phép AFSL do ASIC cấp (số 417727), Giấy phép FSP do FMA New Zealand cấp (số 486026), và Giấy phép nhà cung cấp dịch vụ tài chính được ủy quyền do FSCA Nam Phi cấp (số 47546).",  
	  公司说明7: "Plus500EE AS được ủy quyền và quản lý bởi Cơ quan Quản lý Tài chính Estonia (số giấy phép No. 4.1-1/18).",  
	    公司说明8: "Plus500SG Pte Ltd (UEN 201422211Z) sở hữu giấy phép dịch vụ thị trường vốn do Cơ quan Quản lý Tài chính Singapore cấp, cho phép giao dịch các sản phẩm thị trường vốn (số giấy phép CMS100648).",
	公司说明9: "Plus500AE Ltd được ủy quyền và quản lý bởi Cơ quan Quản lý Dịch vụ Tài chính Dubai (F005651).",  
	  加入我们的社群: "Tham gia cộng đồng của chúng tôi",  
	  PLUS500集团: "Tập đoàn PLUS500",  
	  受监管的操作: "Hoạt động được quản lý",  
	  健全的全球监管框架: "Khung quản lý toàn cầu vững chắc",  
	  治理和社会责任: "Quản trị và trách nhiệm xã hội",  
	  底部说明: "Thông tin dưới cùng",  
	  投资者联系方式: "Liên hệ với nhà đầu tư",  
	  投资者关系主管: "Giám đốc quan hệ nhà đầu tư",  
	  AI智慧型量化交易: "Giao dịch định lượng thông minh AI",  
	  订单执行快速可靠: "Thực hiện đơn hàng nhanh chóng và đáng tin cậy",  
	  全天候自动分析行情: "Phân tích thị trường tự động 24/7",  
	  交易受监管低风险: "Giao dịch được quản lý với rủi ro thấp",  
	  稳定策略高效收益: "Chiến lược ổn định với lợi nhuận hiệu quả",  
	  取款快速且安全: "Rút tiền nhanh chóng và an toàn",  
	  注册成功通知: "Thông báo đăng ký thành công",  
	  游戏说明: "Số dư tối thiểu là 10 USD để tham gia trò chơi và kiếm tiền",  
	  
	  货币: "VND",  
	  能量值: "Giá trị năng lượng",  
	  财富值: "Giá trị tài sản",  
	  佣金: "Hoa hồng",  
	  累计工资: "Lương tích lũy",  
	  工资: "Lương",  
	  租赁: "Thuê",  
	  去租赁: "Đi thuê",  
	  
	  当前速率: "Tốc độ hiện tại",  
	  当前工资: "Lương hiện tại",  
	  预计今日收益: "Dự kiến lợi nhuận hôm nay",  
	  累计佣金: "Hoa hồng tích lũy",  
	  邀请好友注册领取工资: 'Mời bạn bè đăng ký nhận lương',  
	  月: 'Tháng',  
	  符号: 'vi_vn',  
	  转盘抽奖: 'Rút thăm trúng thưởng',  
	  剩余抽奖次数: 'Số lần rút thăm còn lại',  
	  关闭: 'Đóng',  
	  恭喜您获得: 'Chúc mừng bạn đã nhận được',  
	  很遗憾没有中奖: 'Rất tiếc bạn không trúng thưởng',  
	  游戏规则: 'Quy tắc trò chơi',  
	  租赁机器人后会获得抽奖次数: 'Sau khi thuê robot sẽ có số lần rút thăm',  
	  中奖金额会存入余额: 'Số tiền trúng thưởng sẽ được chuyển vào số dư',  
	  中奖记录请到收入记录中查看: 'Vui lòng xem lịch sử trúng thưởng trong hồ sơ thu nhập' 
	
    
	
}
