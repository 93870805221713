export default {
  tabbar: {
    首页: 'Titelseite',
    市场: 'Markt',
    任务: 'Aufgabe',
    团队: 'Team',
    我的: 'Mein'
  },
  navbar: {
    充值: 'Kaution',
    提现: 'Geld abheben',
    帮助: 'FAQ',
    分享: 'Einladen',
	推广: 'Werbeeinnahmen',
    活动: 'Prämie!',
    游戏: 'Spiel',
  },	 
  payment: {
    付款方式详情: 'Details der Zahlungsmethode',
    银行卡: 'Bankkarte',
    钱包地址: 'Wallet-Adresse',
    交易密码: 'Transaktions Passwort',
    保存: 'sparen'
  },
  market: {
    市场: 'Markt',
    名称: 'Name',
    价格: 'Preis',
  },
 
  task: {
    任务: '任务',
	我的余额: '我的余额',
    增加订单数量: '增加订单数量',
    今日剩余订单: '今日剩余订单',
    频率: '频率',
    手动交易: '手动交易',
    预期收益: '预期收益',
    需要启动存款: '需要启动存款',
    任务说明: '任务说明',
    每天可以完成10个任务: '每天可以完成10个任务',
    联系VIP助手增加任务数量: '联系VIP助手增加任务数量',
    每个用户的任务总数为300次: '每个用户的任务总数为300次',
    购买机器人无需人工操作即可自动交易: '购买机器人无需人工操作即可自动交易',
    我知道了: '我知道了'
  },
  邀请好友注册领取佣金:"Laden Sie Freunde ein, sich zu registrieren und Provisionen zu erhalten",
  领取:"Erhalten",
  未满足条件:"Bedingung nicht erfüllt",
  总收入:"Gesamteinkommen",
  投资建议:"※Führen Sie einen Zinseszins-Investitionsplan durch, um höhere Renditen zu erzielen",
  语言切换: 'Sprachumschaltung',
  POS区块节点挖矿: 'Ai Quantitative Trading-Details',
  节点类型: 'Robo\nTyp',
  加入价格: 'Kaufen\nPreis',
  质押资金: 'POS\nFonds',
  质押周期: 'Zeitraum\n(Tage)',
  每日收入: 'Tages\nEinkommen',
  累计积分: 'Kumulierte\nPunkte',
  代理等级: 'Agent\nLevel',
  下级折扣比: 'Untergeben\nRabatt',
  代理折扣比: 'Agent\nRabatt',
  下属等级: 'Untergeordnete\nEbene',
  代理佣金比率: 'Agent\nProvision\nRate',
  下属每日收入: 'Untergeordneter\nTäglicher\nUmsatz',
  代理佣金: 'Agent\nProvision',
  引用对象: 'Zitat \nObjekt',
  积分等级折扣规则: 'Rabattregeln für Punktestufen',
  积分等级折扣规则介绍: 'Benutzer, die erfolgreich am quantitativen Ai-Handel teilnehmen, erhalten entsprechende Kontopunkte, und die übergeordneten Benutzer ihrer Agenten können die entsprechenden Punkte auch erhöhen (gilt für 15 % der direkten Untergebenen). Wenn die Gesamtkontopunkte die entsprechenden Bedingungen erfüllen, wird die Ebene des Agenten erhöht Das Konto wird verbessert. Agentenkonten auf verschiedenen Ebenen können unterschiedliche Anteile an Preisnachlässen erhalten. Einzelheiten zu relevanten Punkterabatten sind wie folgt:',
  积分等级折扣规则介绍2: 'Benutzer mit unterschiedlichen Beziehungen (direkt) erhalten Rabatte und Rabatte auf Agentenebene, basierend auf dem Verhältnis zur Teilnahme der Untergebenen an quantitativen Ai-Transaktionen. Andere leitende Angestellte erhalten ebenfalls Rabattstufen im Verhältnis zu ihren eigenen Vertretern. Preisnachlässe für die Teilnahme an quantitativen Ai-Transaktionen: Benutzer mit entsprechenden Agentenstufen erhalten bei der Teilnahme an quantitativen Ai-Transaktionen entsprechende Vorzugsrechte auf Agentenebene.',
  团队: 'Team',
  代理佣金制度: 'Agenturprovisionssystem',
  代理佣金说明详情: 'Anteil der von A erhaltenen LV1-Mittel: 8 %, Anteil der von A erhaltenen LV2-Mittel: 3 %, Anteil der von A erhaltenen LV3-Mittel: 2 %. Beispiel: Der tägliche Gewinn von B, C und D beträgt: 100 USD und die von A erhaltene LV1-Agentenprämie: 8 % * 100 = 8 USD. Die von A erhaltene LV2-Agentenprämie: 3 %*100=3 USD. Die von A erhaltene LV3-Agentenprämie: 2 %*100=2 USD. Die gesamten Provisionseinnahmen betragen: 8+3+2=13 USD.',
  代理结构图示: 'Proxy-Strukturdiagramm',
  代理结构图示说明: 'A vertritt Sie, A lädt B ein (der Untergebene von A ist B), B lädt C ein (der Untergebene von B ist C) und C lädt D ein (der Untergebene von C ist D). A kann mehrere Untergebene haben (B1, B2, B3, B4, B5, B6 usw.), B (B1, B2, B3) kann auch mehrere Untergebene haben (C1, C2, C3, C4, C5, C6 usw. ) CD...',
  高级代理佣金说明: 'Anweisungen für die Senior Agent-Provision',
  高级代理佣金说明详情: 'A vertritt Sie, A lädt B ein (der Untergebene von A ist B), B lädt C ein (der Untergebene von B ist C) und C lädt D ein (der Untergebene von C ist D). Der übergeordnete Agent erhält unterschiedliche Agenturprovisionseinnahmen (%) entsprechend dem Verhältnis des Gesamtbetrags des Node-Mining, der mit seinem eigenen Konto und untergeordneten Konten verbunden ist. A vergleicht das tägliche Einkommen der untergeordneten Mitglieder, um das entsprechende Verhältnis der Agenturbelohnungen zu erhalten. Teilnehmende Knoten müssen betriebsbereit sein. Agentureinnahmen werden von INGSAI MINING für alle finanziellen Ausgaben bereitgestellt und wirken sich nicht auf das tägliche Einkommen normaler untergeordneter Mitglieder aus.',
  高级代理佣金说明详情2: 'Beispiel: Die gesamte Mining-Menge der Senior Agents (A) > 30 % der gesamten Mining-Menge der unteren Mitglieder (B/C/D) (Beispiel: A > 30 % von B/C/D)',
  积分: 'Integral',
  规则: 'Regel',
  会员级别: 'Mitgliedsstufe',
  经验: 'Gesamtpunktzahl',
  代理信息: 'Mitgliederinformation',
  更多: 'Mehr',
  团队奖励: 'Mannschaftsprovision',
  昨天: 'gestern',
  本星期: 'diese Woche',
  全部: 'alle',
  机器人说明: "Benutzer von Plus500Ai können mehrere quantitative Handelspakete gleichzeitig erwerben, und der Roboter Nr. 1 kann nur 10 Mal pro Konto kaufen. Denken Sie daran: Vergessen Sie nicht, nach dem Kauf des quantitativen Roboters auf die Aktivierung der Strategie zu klicken, da sonst kein Einkommen erzielt wird. Die Gültigkeitsdauer des quantitativen Roboters wird auf der Grundlage des Kaufzeitpunkts berechnet. Bitte aktivieren Sie die quantitative Handelsstrategie rechtzeitig. Wenn der quantitative Roboter nach Ablauf nicht aktiviert wurde, verlieren Sie die Kosten für den Kauf des quantitativen Handelsstrategiepakets! !! Alle am quantitativen Handel mit Plus500Ai teilnehmenden Nutzer lesen sich diese Regelung bitte sorgfältig durch! !",
  会员体验: 'Mitgliedserfahrung',
  机器人采购: 'Roboterkauf',
  机器人启动押金: 'Knoten-POS-Betrag',
  机器人礼物: 'Knoten Geschenk',
  机器人订单: 'Knotenreihenfolge',
  一次性总和: 'zeigen',
  总计: 'gesamt',
  
  被激活: 'Bereits POS',
  工作中: 'Aktiviert',
  暂停: 'Pause-POS',
  结束: 'verfallen sein',
  刷新: 'erneuern',
  来源: 'Quelle',
  价格: 'Roboterpaketpreis',
  启动押金: 'POS die Menge',
  剩余时间: 'übrige Zeit',
  激活时间: 'Aktivierungszeit',
  号: 'NO.',
  我的资产: 'mein Vermögen',
  退出: 'Ausloggen',
  邀请码: 'Einladungscode',
  我的钱包余额: 'mein Wallet-Guthaben',
  我的机器人仓库: 'Meine Roboterliste',
  收益面板: 'Verdienstpanel',
  去提款: 'Geld abheben',
  昨天代理返利: 'Agenturprovision gestern',
  累计代理返利: 'Kumulierte Agenturprovision',
  今天机器人收益: 'Der heutige Handelsgewinn',
  累计机器人收益: 'Kumulierte Handelserträge',
  累计机器人回扣: 'Kumulierte Handelsprovision',
  累计总回报: 'Kumuliertes Gesamteinkommen',
  自动订单机器人数量: 'Aktivierter quantitativer Handelsroboter',
  我的付款方式: 'meine Zahlungsmethode',
  个人收入记录: 'persönliches Einkommen',
  团队收入记录: 'Einkünfte der Mannschaft',
  记录详情:'Details aufzeichnen',
  充值记录: 'Datensätze aufladen',
  我的团队成员: 'meine Teammitglieder',
  活动中心: 'Regeln Einführung',
  修改登录密码: 'Login-Passwort-Einstellung',
  交易密码管理: 'Einstellung des Transaktionspassworts',
  机器人: 'KI-Handelsstrategie',
  机器人性能: 'Roboter-Panel',
  机器人价格: 'Handelsstrategie-Preis',
  有效期: 'Gültigkeitszeitraum',
  钱包地址: 'Brieftaschenadresse',
  天: 'Tag',
  去购买: 'Einkauf',
  买个机器人: 'Quant-Handelsroboter kaufen',
  我的钱包余额: 'meinen Kontostand',
  预计日收益: 'Tägliches Einkommen',
  启动保证金: 'Starteinlage',
  请输入购买数量: 'Bitte geben Sie die Abnahmemenge ein',
  个机器人: 'Quant-Handelsroboter',
  机器人购买后需要激活才能工作: 'Nach dem Kauf eines Knotens ist die entsprechende Menge an POS erforderlich, um davon zu profitieren',
  机器人激活需要从余额中扣除相应的启动押金:
    'Node POS Mining muss den entsprechenden POS-Betrag vom Kontostand abziehen',
  机器人暂停机器人停止时将返还启动押金不活跃的机器人可以赠送:
    'Die Menge an POS, die sofort zurückgegeben werden kann, wenn das POS-Mining des Knotens ausgesetzt wird',
  购买: 'Einkauf',
  点击去了解我们: 'Quantitativer Handel',
  购买机器人: 'Kauf einen Roboter',
  快速入口: 'Schneller Einstieg',
  '日/个人受益': 'Tägliches/persönliches Einkommen',
  购买教程: 'Liste der KI-Handelsroboter',
  激活金额: 'POS-GEBÜHR',
  订单数量: 'Bestellmenge',
  我们的合作伙伴: 'Unsere Krypto-Marktpartner',
  提款: 'Geld abheben',
  提款金额: 'Entnahmebetrag',
  你还没有添加银行卡: '你还没有添加银行卡',
  提现说明: 'Widerrufsbelehrung',
  说明1: '1.Sie können mit einem Klick auswählen oder den Aufladebetrag manuell eingeben',
  说明2: '2.Es wird empfohlen, USD (TRC20) zu verwenden, die Überweisungsgebühr ist am niedrigsten',
  说明3: '3.Die Kundendienstzeiten sind Montag bis Freitag AM8 bis PM6 mittelamerikanische Zeit',
  说明4: 'Benutzer, die USD zum Aufladen verwenden, müssen den richtigen Screenshot der erfolgreichen Überweisung und TXID hochladen, und das Konto wird erfolgreich empfangen, nachdem die Plattform die Überprüfung bestanden hat',
  说明5: '4.Die Mindestaufladung beträgt 8 USD, was für das System praktisch ist, um das Konto schnell zu überprüfen und zu bearbeiten',
  说明6: '5.Wenn Sie Fragen zum Aufladen haben, wenden Sie sich bitte rechtzeitig an den Online-Kundendienst, um Unterstützung zu erhalten',
  确认提款: 'Auszahlung bestätigen ',
  请输入手机号码:'Telefonnummer',
  请输入提现金额: 'Entnahmebetrag ',
  提现费:'Abhebegebühr',
  提现须知: 'Bitte lesen Sie die Auszahlungsregeln sorgfältig durch',
  TIPS1: '1:Die tägliche Auszahlungsüberprüfungszeit ist von Montag bis Freitag AM8 bis PM6. Auszahlungen können an Wochenenden vorgenommen werden, und die Auszahlungsüberprüfungszeit ist Montag',
  TIPS2: '2:7X24-Stunden-System, jederzeit Geld abheben, spätestens innerhalb von 24 Stunden auf dem Konto und spätestens innerhalb von 5 Minuten auf dem Konto',
  TIPS3: '3:Online-Kundendienstzeit: Montag bis Freitag 8 bis 6 Uhr',
  TIPS4: '4:Aufgrund der Kosten für die USD-Überweisung erhebt die Plattform eine Abhebungsgebühr, wenn der Benutzer Geld abhebt',
  TIPS5: '5:Der Mindestauszahlungsbetrag beträgt 10 USD',
  钱包余额: 'Wallet-Guthaben',
  输入数量:"Gib die Menge ein",
  数量:"Quantität",
  手机号码:"Telefonnummer",
  手机号码已存在:"Die Mobiltelefonnummer wurde registriert",
  邮箱:"E-Mail",
  输入手机号码: 'Handynummer eingeben',
  请输入登录密码: 'Login-Passwort',
  登录: 'Einloggen',
  输入账号: 'Geben Sie die Kontonummer ein',
  密码: 'Passwort',
  点击注册: 'Sie haben noch kein Konto? Jetzt eines erstellen!',
  忘记密码: 'Passwort vergessen',
  重新登录: 'erneut einloggen',
  密码找回成功: 'Passwort erfolgreich abgerufen',
  找回成功请重新登录: 'Abruf erfolgreich, bitte melden Sie sich erneut an',
  确认: 'bestätigen',
  发送验证码: 'Senden Sie den Bestätigungscode',
  再次输入密码: 'Bestätige das Passwort',
  输入验证码: 'E-Mail-CAPTCHA eingeben',
  输入手机验证码:"Geben Sie mobiles OTP ein",
  输入手机号: 'Handynummer eingeben',
  输入密码: 'Login-Passwort',
  快速注册: 'Schnelle Registrierung',
  注册: 'Registrieren',
  立即登录: 'Sie haben bereits ein Konto, klicken Sie hier, um sich anzumelden',
  请输入邮箱: 'Gmail-Adresse',
  输入用户名: 'Gib deinen Benutzernamen ein',
  请输入邀请码: 'Einladungscode',
  返回: 'Rückkehr',
  密码找回失败: 'Kennwortwiederherstellung fehlgeschlagen',
  输入的信息有误: 'Die eingegebenen Informationen sind falsch',
	获取验证码:"CAPTCHA",
	手机号码不能为空:"Die Telefonnummer darf nicht leer sein",
	账号不存在:"Konto existiert nicht",
	发送成功:"Erfolgreich verschickt",
	今日收益:"（laufendes Einkommen）",
	"您好如果您中途终止POS-STAKING系统将收取5%的STAKING费用":"Hallo, wenn Sie das POS auf halbem Weg kündigen, berechnet das System eine POS-Verwaltungsgebühr von 5 %. Besondere Erinnerung: Nachdem der POS des kostenlosen Node-Erlebnisses ausgesetzt wurde, können Sie es nicht mehr erhalten, und Sie können auch keine Gebühren erhalten! !",
	我同意:"Ich stimme zu",
	用户账号ID:"Konto-ID",
	取消:"Absagen",
	日收益:"Tageseinkommen",
	请输入购买数量:"Bitte geben Sie die Abnahmemenge ein",
	数量不能为空:"Menge darf nicht leer sein",
	加载中:"Wird geladen...",
	唯一码:"Robotercodierung",
	未激活:"INAKTIV",
	激活:"Strategie Starten",
	购买时间:"Kaufzeit",
	"钱包余额不足，激活该机器人失败":"Unzureichender Kontostand, Node-POS-Mining kann nicht durchgeführt werden",
	
	运行时长:"Laufzeit",
	签约购买:"Kauf mit Restbetrag",
	系统赠送:"Systemgeschenk",
	状态:"Zustand",
	正常:"normal",
	我的机器人:"Mein Quant-Trading-Roboter",
	一级会员:"Level 1",
	二级会员:"Level 2",
	三级会员:"Level 3",
	人:"",
	充值客服:"充值客服",
	充值:"Kaution",
	提现:"zurückziehen",
	提款密码:"Widerrufspasswort",
	设置交易密码:"Transaktionskennwort festlegen",
	登录密码:"Login-Passwort",
	请输入交易密码:"Transaktionskennwort eingeben",
	再次请输入交易密码:"Transaktionskennwort bestätigen",
	确认: 'bestätigen',
	手机号码不能为空:"Telefonnummer kann nicht leer sein",
	两次密码不一致:"Die passwörter stimmen nicht überein",
	请输入验证码:"Bitte Bestätigungscode eingeben",
	操作成功:"Erfolgreiche Operation",
	"用户名或密码不正确,登录失败":"Benutzername oder Passwort ist falsch, Anmeldung fehlgeschlagen",
	登录成功:"Anmeldung erfolgreich",
	充值说明:"Aufladeanleitung",
	请先设置支付密码:"Bitte legen Sie zuerst das Transaktionspasswort fest",
	复制成功:"erfolgreich kopieren",
	冻结机器人做单本金:"Quantitative Mittel einfrieren",
	待审核:"rezensiert werden...",
	成功:"Erfolg",
	失败:"scheitern",
	审核中:"unter Überprüfung",
	在线充值:"Online aufladen",
	描叙:"beschreiben",
	POS节点挖矿LV0:"Mining von POS-LV0-Knoten",
	POS节点挖矿LV1:"Mining von POS-LV1-Knoten",
	POS节点挖矿LV2:"Mining von POS-LV2-Knoten",
	POS节点挖矿LV3:"Mining von POS-LV3-Knoten",
	POS节点挖矿LV4:"Mining von POS-LV4-Knoten",
	POS节点挖矿LV5:"Mining von POS-LV5-Knoten",
	银行卡提现:"Abhebung vom Bankkonto",
	USD提现:"USD-Auszahlung",
	三级代理:"Level-3-Agent",
	一级代理:"Level-1-Agent",
	二级代理:"Level-2-Agent",
	一级:"Level-1",
	二级:"Level-2",
	三级:"Level-3",
	做单:"Node-Mining",
	登录失败:"Fehler bei der Anmeldung",
	请勿重复操作:"Wiederholen Sie häufige Vorgänge nicht",
	邀请码不正确:"Einladungscode ist falsch",
	团队返佣:"Mannschaftsprovision",
	购买机器人返佣:"Robot-Kommission",
	本金返还:"POS-Rückgabe",
	佣金收入:"Provisionserträge",
	时间:"Zeit",
	机器人做单返还:"POS-Betragsrückerstattung",
	涨幅:"Zunahme",
	市场:"Markt",
	客服:"Servieren",
	验证码错误:"Bestätigungscode-Fehler",
  付款方式:"USD-Auszahlungsadresse",
	我的留言:"meine Nachricht",
  暂无数据: 'Keine Daten',
  银行卡:'Bankkarte',
  修改: 'Überarbeiten',
 '请确保您的钱包地址正确，如果您的地址输入错误而导致收不到钱，请及时联系在线客服协助':'Bitte stellen Sie sicher, dass Ihre Wallet-Adresse korrekt ist. Wenn Ihre Adresse falsch eingegeben wurde und Sie kein Geld erhalten können, wenden Sie sich bitte rechtzeitig an den Online-Kundendienst, um Hilfe zu erhalten.',
 连接借记卡:"连接借记卡",
 银行名称:'Name der Bank',
 姓名姓氏: 'Name',
 IBAN号码:'IBAN 号码',
 '* 重要提示:借记卡信息必须真实有效才能取款。':'*Wichtige Erinnerung: Die Bankkontodaten müssen wahr und gültig sein, bevor Sie Geld abheben können。',
 帮助中心:'Hilfezentrum',
 会员须知:'Mitgliederhinweis',
 '24小时内使用USD实时取款（推荐）':'1. 24小时内使用USD实时取款（推荐）',
 '银行卡 取款 *取款时间为10:00-18:00 *发起取款后2小时内入账*申请非取款时间或跨行取款将于次日10:00-18:00到账':' 2.银行卡 取款 *取款时间为10:00-18:00 *发起取款后2小时内入账*申请非取款时间或跨行取款将于次日10:00-18:00到账',
 我知道了:'Ich verstehe',
 链接点击:'Link zum Empfehlungsregister',
 确定:'Gewiss',
 建立自己的团队邀请:'baue dein eigenes team auf',
 此账号已冻结:"Dieses Konto wurde gesperrt",
 手机号存已存在:"Mobiltelefonnummer existiert bereits",
 注册账号已存在:"Registriertes Konto existiert bereits",
 请确定新密码:"Bitte bestätigen Sie das neue Passwort",
 请再次输入登录密码:"Login-Passwort bestätigen",
 密码长度不能少于6位:"Die Passwortlänge darf nicht weniger als 6 Zeichen betragen",
 加入:'dazu kommen',
 "你出售的机器人对方已接受,出售所得已打款到您账户,请查收。":
 	    "你出售的节点对方已接受,出售所得已打款到您账户,请查收。",
 	"你出售的机器人对方已拒绝接受":"你出售的节点对方已拒绝接受",
 	向你赠送一台机器人:"向你赠送一个节点，待确认",
 	向你出售一台机器人:"向你出售一个节点，待确认",
 	出售价格不能超过机器人价格:"出售价格不能超过节点价格",
 	银行卡已被绑定:"Bankkonto wurde verwendet",
 	USD_TRC20提现:'USD_TRC20 Auszahlung',
 	USD_ERC20提现:'USD_ERC20 Auszahlung',
 	银行卡提现:'Abhebung vom Bankkonto',
 	邀请赠送:'Einladungsbonus',
 	机器人返佣:'Robot-Kommission',
 	升级:'erfolgreicher Kauf',
 	充值主网:'充值主网',
   "您被限制出售机器人,请联系客服了解详情":
     "您被限制出售节点,请联系客服了解详情",
   交易编号: "Transaktionsnummer",
   消息详情:"Nachrichtendetails",
   个人收益累计:"kumulierte persönliche Einkünfte",
   今日代理收益:"Agenteneinnahmen heute",
   代理累计收益:"Gesamteinkommen des Agenten",
   截图保存推荐给朋友:"Teilen Sie Ihren Empfehlungs-Registrierungslink und laden Sie Freunde ein, der Plus500Ai-Plattform beizutreten, um Teameinkommensprovisionen zu erhalten. Die Einkommensprovision für Teammitglieder der dritten Ebene beträgt 8 %-3 %-2 %. Die Teamprovision basiert auf den täglichen Einkommensdaten der untergeordneten Mitglieder und wird täglich nach 00:00 Uhr automatisch abgerechnet und Ihrem Kontostand gutgeschrieben.",
   复制:"Kopie",
   充值提示1:"Tipp 1: Die Mindestaufladung beträgt 10 USD, Sie können aufladen, sonst werden Sie nicht gutgeschrieben",
   充值提示2:"Tipp 2: Das Aufladen muss auf der Kette bestätigt werden, nach erfolgreicher Aufladung wird es voraussichtlich in etwa einer Minute auf dem Wallet-Guthaben ankommen。",
   请输入正确的充值金额:"Einzahlungsbetrag ≥ 8 USD",
   推荐:"empfehlen",
   充值金额:"Aufladebetrag",
   请上传付款截图:"Bitte laden Sie einen Screenshot der erfolgreichen Zahlung hoch",
   交易号: 'Transaktions-Hash-Nummer',
   充值ID提示:"Bitte fügen Sie Ihre Txid-Transaktionsseriennummer ein",
   "请确保钱包地址无误，如因钱包地址填写错误导致无法收款而造成的损失，将有用户自己承担。":"Bitte vergewissern Sie sich, dass die Wallet-Adresse korrekt ist. Jegliche Verluste, die dadurch entstehen, dass kein Geld aufgrund einer falschen Eingabe der Wallet-Adresse empfangen wird, gehen zu Lasten des Benutzers.",
	上传凭证:"Zahlungsnachweis hochladen",
	充值确认: "einreichen",
	不能为空:"Kann nicht leer sein",
  绑定钱包地址:'Brieftaschenadresse binden',
	备注:"备注",
	请输入银行卡号:"Geben Sie die Bankkontonummer ein",
	请输入你的名字:"Geben Sie Ihren Namen ein",
	银行卡号:"Bankkonto",
	添加银行卡:"Bankkonto binden",
	请选择银行:"Bitte wählen Sie eine Bank aus",
	请输入钱包地址:"Bitte Wallet-Adresse eingeben",
	钱包地址:"Brieftaschenadresse",
	"密码错误次数过多,请等待x秒后再试":"Zu viele falsche Passwörter, bitte warten Sie 600 Sekunden und versuchen Sie es erneut",
	"此账号已冻结,请联系客服":"Dieses Konto wurde gesperrt, bitte wenden Sie sich an den Kundendienst",
	手机号格式错误:"Falsch formatierte Telefonnummer",
	"取款须知":"Widerrufsbelehrung",
	"须知1":"1. Verwenden Sie USD-TRC20 für Auszahlungen in Echtzeit innerhalb von 24 Stunden (empfohlen)",
	"须知2":"2. Rücktritt am Samstag und Sonntag",
	"须知3":"*Benutzer können am Samstag und Sonntag Geld abheben",
	"须知4":"*Auszahlungen am Wochenende kommen am Montag zwischen 10:00 und 20:00 Uhr an",
	我知道了:"Ich verstehe",
	"提现0":"1USD=1USD ERC20",
	"提现1":"提示1：最低单笔提现100USD/天，单笔交易手续费5USD/次",
	"提现2":"TRC20提示2：每次提款最低10USD，每次交易手续费1USD。",
	"提现3":"银行账户提示3：最低单次取款为$10，交易手续费为交易金额的$6%",
	"提现4":"提现时间：提现申请提交后24小时内到账",
	
	已绑定:"gefesselt",
	去设置:"gehe zu den Einstellungen",
	注册成功:"Registrierung erfolgreich",
	汇率:"Tauschrate",
	请输入正确的提现金额:"Bitte geben Sie den korrekten Auszahlungsbetrag ein",
	提款记录:"Widerrufsgeschichte",
	"绑定的钱包地址必须跟付款钱包一致，否则不会到账。":"tip1:绑定的钱包地址必须跟付款钱包一致，否则不会到账。",
	"充值成功后预计在一分钟左右到账，请检查钱包约。":"tip2:充值成功后预计在一分钟左右到账，请检查钱包余额。",
	请先绑定钱包地址:"Bitte binden Sie zuerst die Wallet-Adresse",
	输入邮箱:"Email eingeben",
	邮箱已存在:"Postfach existiert bereits",
	注册邮箱已存在:"Registrierte E-Mail existiert bereits",
	指定用户不存在:"Der angegebene Benutzer existiert nicht",
	今日涨幅:"ändern",
	恭喜新用户:"Glückwunsch neuer Benutzer ",
	快捷充币:"Kaution",
	快速买USD:"USD-Aufladung",
	在线支付:"Onlinebezahlung",
	自动到账:"Automatisches Konto",
	线下支付:"线下支付",
	联系客服获取验证码:"Wenden Sie sich an den Kundendienst, um den Bestätigungscode zu erhalten",
	获取:"Erhalten",
	退出成功:"Ausfahrt erfolgreich",
	不能低于最小充值:"Kann nicht niedriger als die Mindestaufladung sein",
	不能低于最小提现金额:"Darf nicht unter dem Mindestauszahlungsbetrag liegen",
	最小提现:"Mindestentnahme",
	设置成功:"erfolgreich eingestellt",
	官方充币:"Systempfand",
	银行卡充值:"Bankaufladung",
	等待审核:"auf Überprüfung warten",
	可提现金额不足:"Unzureichender Betrag zum Abheben verfügbar",
	未经授权:"unbefugt",
	交易密码不正确:"Das Transaktionskennwort ist falsch",
	提现次数不足:"Unzureichende Auszahlungszeiten",
	官网:"官网",
	账户USD不足:"Der USD-Kontostand ist unzureichend",
	激活成功: "POS-Erfolg",
	操作成功: "Erfolgreiche Operation",
	修改成功:"Erfolgreich geändert",
	注册说明:"Mobiles OTP und E-Mail-CAPTCHA müssen nur eine Methode zur Überprüfung auswählen und Sie können sich erfolgreich registrieren",
	弹窗内容:
	"Willkommen auf der quantitativen Handelsplattform Plus500Ai. Jeder Benutzer kann den Registrierungsbonus von 1 USD nutzen, um das quantitative Roboter-Handelsstrategiepaket NO.0 zu erwerben, das 1 Tag lang gültig ist, und 0,5 USD kostenlos zu verdienen. Bei anderen quantitativen Handelsstrategiepaketen müssen entsprechende Gebühren erhoben werden. Nachdem Benutzer den Roboter gekauft haben, müssen sie die quantitative Handelsstrategie aktivieren, bevor sie automatisch ausgeführt werden kann. Jede Handelsstrategie entspricht einem anderen Gültigkeitszeitraum. Wenn der Benutzer nach dem Kauf der entsprechenden Roboterpunkte nicht auf die Aktivierung der Strategie klickt, erhalten Sie keine Handelseinnahmen.",
	标题1:'1. Einführung in die Plus500-Plattform',
	标题2:'2. Vorteile des quantitativen KI-Handels',
	标题3:'3. Warum Plus500Ai wählen?',
	标题4:'4. So treten Sie Plus500Ai bei',
	标题5:'5.Plus500Ai-Marktaussichten',
	标题6:'6.Plus500Ai-Umsatzmodell',
	标题7:'7.Ein- und Auszahlungsbestimmungen',
	标题8:"Battle-Royale-Gameplay",
	内容8:"Es gibt 8 Räume im Battle-Royale-Spiel auf der Plu500Ai-Plattform. Spieler können einen beliebigen der 8 Räume auswählen, um Goldmünzen hineinzulegen. Nach Ablauf des System-Countdowns erscheint ein Killer im Spielsystem. Der Killer betritt zufällig einen Raum und eliminiert die Personen im Raum. Andere Spieler können die Goldmünzen in diesem Raum teilen und Gewinne erzielen. Das Gameplay des Battle-Royale-Spiels ist relativ kurz und dauert nur wenige Minuten, sodass die Spieler es in ihrer Freizeit spielen können. Der Teilungsbetrag = die Gesamtzahl der Räume, die der Mörder in diesem Zeitraum getötet hat. Tatsächlicher Anteilsbetrag = Anteilsbetrag * 0,9 (ohne die Zerstörungsquote von 0,1). Anteilsverhältnis = tatsächlicher Anteilsbetrag / Gesamteinsatzbetrag in anderen Räumen. Gewinnbetrag = Anteilsverhältnis * tatsächlicher Wettbetrag. 1 Goldmünze = 1 USD, und für die Teilnahme am Battle-Royale-Spiel sind mindestens 10 USD erforderlich.",
	内容1:"Die Plus500-Handelsplattform wird von Plus500SEY Ltd. bereitgestellt. Dementsprechend ist Plus500SEY Ltd der Emittent und Verkäufer der auf dieser Website beschriebenen oder angebotenen Finanzprodukte. Plus500SEY Ltd ist ein auf den Seychellen ansässiges Unternehmen (Firmennummer 8426415-1) mit Sitz in Victoria. Das Unternehmen ist von der Seychelles Financial Services Authority (FSA) mit der Lizenznummer SD039 autorisiert und beaufsichtigt und bietet Differenzkontrakte (CFD) für verschiedene Basisprodukte an. Das Unternehmen ist ein schnell wachsender CFD-Anbieter, der derzeit ein Portfolio von über 2.800 Finanzinstrumenten anbietet. Plus500SEY Ltd ist eine Tochtergesellschaft von Plus500 Ltd, die am Hauptmarkt der Londoner Börse notiert ist und ihren Hauptsitz in Haifa hat.",
	内容2:'Der Vorteil des vollautomatischen quantitativen Handels mit KI besteht darin, rationale Werkzeuge zu verwenden, um Transaktionen gemäß Modelleinstellungen durchzuführen. Der Roboter hat keine emotionalen Veränderungen und kann die menschliche Natur, menschliche Gier und Angst sowie viele andere Mängel und Schwächen im Betriebsprozess überwinden. Manuelle Vorgänge werden oft durch den Anstieg und Fall des Marktes und verschiedene andere Faktoren beeinflusst und können nicht über einen längeren Zeitraum nach dem besten Gewinnmodell betrieben werden.',
	内容3:'Plus500Ai hat seine Position als Multi-Asset-Fintech-Gruppe schrittweise gestärkt, indem das Unternehmen seine Kernprodukte auf neue und bestehende Märkte ausgeweitet, neue Handels- und Finanzprodukte auf den Markt gebracht und die Zusammenarbeit mit seinen Kunden vertieft hat. Schützen Sie Ihre Vermögenswerte und Ihre Verschlüsselungsschlüssel mit branchenführender Sicherheitstechnologie. Plus500Ai bietet auch globale Verwahrung an, was bedeutet, dass wir reguliert, geprüft und bis zu 250 Millionen US-Dollar versichert sind. Setzen Sie Ihr Kapital mit dem Ziel ein, Vermögenswerte nicht nur zu halten, sondern einzusetzen. Mit Plus500Ai können Sie Ihre Mittel je nach Risikotoleranz auf verschiedene Weise einsetzen, vom quantitativen Ai-Handel bis hin zu klassischen Primärdienstleistungen. Online-Plattform, um Ihren eigenen Benutzern mehr Dienste bereitzustellen, ohne zusätzliche Blockchain-Ingenieure einzustellen.',
	内容4:"Seit der Gründung von Plus500Ai gab es nur eine offizielle Website, WWW.PLUS500AI.COM. Alle Benutzer müssen sich auf der offiziellen Website anmelden, um die APP herunterzuladen, da andere Websites nicht zu unserem Unternehmen gehören. Um sich zu registrieren, müssen Sie die Mobiltelefonnummer, die E-Mail-Adresse und den Einladungscode des Empfehlungsgebers angeben, um eine schnelle Registrierung zu ermöglichen. Die gesamte Kommunikation mit Kunden erfolgt schriftlich, entweder per E-Mail oder Online-Chat. Um Plus500 per E-Mail zu kontaktieren, füllen Sie bitte das Formular auf der Seite „Kontakt“ („Anfrageformular“) aus. Nach dem Absenden des Bewerbungsformulars sendet Plus500 eine Antwort per E-Mail direkt an die E-Mail-Adresse, die Sie im Bewerbungsformular angegeben haben. Bei Fragen wenden Sie sich bitte an den Kundenservice.",
	内容5:'Plus500Ai ist produkt-, geräte- und länderübergreifend tätig, um Kunden zuverlässige und intuitive Finanzhandelsmöglichkeiten zu bieten. Wir streben stets nach Exzellenz bei unseren Produkten und Teams. Mit fortschrittlicher proprietärer Technologie und einem kundenorientierten Ansatz erhalten unsere Benutzer ein erstklassiges Handelsservice-Erlebnis.',
	内容6:"Plus500Ai bietet professionelle quantitative Handelsstrategien für den Kryptowährungsmarkt durch ein Team erfahrener Datenanalysten. Benutzer, die in den quantitativen Handelsroboter Ai investieren, können quantitative Handelsgewinne erzielen, indem sie sich auf die mit dem Roboter abgestimmten Handelsstrategien verlassen. Der quantitative Handelsroboter Ai analysiert zyklisch die optimale Handelszeit auf der Grundlage der Markthandelsstrategie von Plus500Ai und erzielt quantitative Handelsgewinne durch das Modell des Kaufs und Verkaufs zu hohen Preisen auf niedrigem Niveau. Plus500Ai bietet investierenden Nutzern jeden Tag optimale quantitative Handelsstrategien und die Plattform schöpft 5 % der quantitativen Einkommensgewinne der Nutzer als Mittel für den Plattformbetrieb aus.",
	内容7:"Plus500Ai unterstützt derzeit Ein- und Auszahlungen in USD und es wird auf der offiziellen Website eine Ankündigung geben, ob in Zukunft weitere Kanäle geöffnet werden. Die Mindesteinzahlung und -auszahlung beträgt jeweils 10 USD. Die schnellste Ein- und Auszahlung erfolgt innerhalb von 5 Minuten und die langsamste innerhalb von 24 Stunden. Es gibt keine Begrenzung für die Abhebungszeit des Benutzers. Sie können jederzeit Geld abheben. Auszahlungen erfolgen an normalen Werktagen innerhalb von 5 Minuten bis 24 Stunden. Wochenendauszahlungen werden voraussichtlich am Montag bearbeitet.",
	BSC提现:"USD_BSC-Auszahlung",
	社区Ads收益:"Einnahmen aus Community Ads",
	Ads收益区间:"Werbeeinnahmen 1-1000 $",
	申请Ads费用:"Anwendungsanzeigengebühr",
	Ads促销地址:"Ads-Promotion-Adresse",
	填写Ads促销地址:"Geben Sie die Ads-Werbeadresse ein",
	申请Ads费用须知:"Anleitung zum Beantragen von Anzeigengebühren",
	须知A:"1. Benutzer nehmen Werbevideos auf und laden sie auf ihre eigenen YouTube-, Facebook-, Kanal- oder anderen Communitys zur Anzeigenwerbung hoch, beantragen einen Bonus von 1 bis 1000 US-Dollar, und die Plattform überprüft Ihr Anzeigenvideomaterial 3 Tage nach Einreichung der Bewerbung und schaltet die Anzeigen Bonus auf das Konto entsprechend dem Video-Werbeeffekt Ihr Kontostand, Videos ohne Werbeeffekte können nicht überprüft werden",
	须知B:"2. Dasselbe Ads-Werbevideo auf demselben Kanal kann nur einmal für einen Bonus beantragt werden, und neues Werbevideomaterial kann einmal pro Woche veröffentlicht werden. Senden Sie keine böswilligen wiederholten Anfragen. Wenn Sie böswillig Anzeigenanfragen, die nicht von Ihnen selbst auf der Plattform gestellt wurden, mehrmals oder Anzeigenmaterialien, die nicht überprüft werden, übermitteln, wird Ihr Konto gesperrt, nachdem das System dies festgestellt hat",
	须知C:"3. Das vom Nutzer aufgenommene Ads-Videomaterial muss eine grundlegende Plattformeinführung und Plattformfunktionserklärung enthalten. Die Videodauer beträgt nicht weniger als 5 Minuten. Je mehr Abonnenten Ihr Kanal hat, desto besser ist die Werbewirkung des Videos und desto höher die Anzeigengebühr, die Sie beantragen können. Die spezifischen Anzeigengebühren sind am Beispiel von YOUTUBE wie folgt:",
	须知D: "Abonnent 0-2K: Antragsbetrag 1-20 $",
	须知D1:"Abonnenten 3K-10K: Antragsbetrag 21-40 $",
	须知D2:"Abonnent 11K-30K: Antragsbetrag 41-100 $",
	须知D3:"Abonnent 31K–50K: Antragsbetrag 101–300 $",
	须知D4:"Abonnent 51K-100K: Antragsbetrag 301-1000$",
	须知D5:"4. Das System prüft und bearbeitet jeden Sonntag Anzeigenbonusanträge und qualifizierte Benutzer erhalten vom System gesendete Boni.",
	请求审核:"Überprüfung anfordern",
	申请金额:"Antragsmenge",
	URL促销地址:"URL-Werbeadresse",
	提交时间:"Einreichungszeit",
	需要更新的银行卡不存在:"Die zu aktualisierende Bankkarte existiert nicht",
	银行卡提现:"Bargeldabhebung per Bankkarte",
	预计收入:"Geschätztes Einkommen",
	总投资:"Gesamtinvestition",
	购买价:"Kaufpreis",
	邀请:"Einladen",
	成员:"Mitglieder",
	积分等级折扣规则介绍3:"Dieser Rabattbetrag gilt nur bis zum 31. Dezember 2023, nach dem 1. Januar 2024 erfolgt eine erneute Anpassung.",
	LV1佣金:"LEVEL1-Kommission",
	LV2佣金:"LEVEL2-Kommission",
	LV3佣金:"LEVEL3-Kommission",
	合格条件:"Anzahlung mindestens 8$ pro Person",
	随时可退:"Jederzeit kündbar",
	登录密码错误:"Falsches Anmeldekennwort",
	充值奖金:"Einzahlung USD≥50USD, Bonus 3 %",
	充值奖金1:"Einzahlung IDR≥50USD, Bonus 2 %",
	充值奖金2:"Einzahlung THB≥50USD, Bonus 5 %",
	活动已过期:"Das Ereignis ist abgelaufen und wartet auf das nächste Ereignis",
	不能重复发送短信:"Sie können innerhalb von 10 Minuten keine wiederholten Textnachrichten senden. Es wird empfohlen, sich direkt mit EMAIL CAPTCHA zu registrieren.",
	限购说明1:"Hallo, jeder Benutzer des quantitativen Roboterhandelsstrategiepakets NO.0 kann es nur einmal kaufen.",
	修改钱包地址提示:"Die USD-Auszahlungsadresse kann nach der Bindung nicht mehr geändert werden. Wenn Sie Hilfe benötigen, wenden Sie sich bitte an den Kundendienst.",
	修改银行提示:"Das Auszahlungsbankkonto kann nach der Bindung nicht mehr geändert werden. Wenn Sie Hilfe benötigen, wenden Sie sich bitte an den Kundendienst.",
	注册推送消息1:"Willkommen bei INGSAI. Nachdem neue Benutzer einen POS-Mining-Knoten gestartet haben, erhalten sie alle 5 Minuten POS-Mining-Einnahmen. Die Einnahmen aus der Mannschaftsprovision werden dem Kontostand automatisch mit etwa 00,00 pro Tag gutgeschrieben.",
	限购说明:"Hallo, die maximale Anzahl an Käufen für diese Art von quantitativen Roboterpaketen beträgt das 10-fache. Es wird empfohlen, andere Arten von quantitativen Handelspaketen zu erwerben.",
	注册协议:"Durch Klicken auf (Konto erstellen) bestätigen Sie, dass Sie über 18 Jahre alt sind. Ihnen ist bekannt, dass Plus500AI Ihre E-Mail-Adresse oder andere personenbezogene Daten verwenden kann, um Sie elektronisch zu kontaktieren und Ihnen relevante Informationen zu seinen Produkten/Dienstleistungen zukommen zu lassen. Möglicherweise benachrichtigen wir Sie auch über Marktereignisse, damit Sie Ihr Handelserlebnis optimal nutzen können. Sie können Benachrichtigungseinstellungen auf den Registerkarten (Benachrichtigungseinstellungen) und (Datenschutzeinstellungen) auf der Plattform verwalten und ändern. Sie können diese Mitteilungen jederzeit abbestellen. Weitere Informationen finden Sie in unserer (Datenschutzerklärung)",
	公司说明1:"Plus500AI ist eine eingetragene Marke von Plus500 Ltd. Plus500 Ltd ist über die folgenden Tochtergesellschaften tätig:",
	公司说明2:"Plus500SEY Ltd ist von der Seychelles Financial Services Authority autorisiert und reguliert (Lizenznummer SD039).",
	公司说明3:"Plus500SEY Ltd ist der Emittent und Verkäufer der auf dieser Website beschriebenen oder verfügbaren Finanzprodukte.",
	公司说明4:"Plus500UK Ltd ist von der Financial Conduct Authority autorisiert und wird von ihr reguliert. Registrierungsnummer der Financial Conduct Authority: 509909. Kryptowährungs-CFDs sind für Privatanwender nicht verfügbar.",
	公司说明5:"Plus500CY Ltd ist von der Cyprus Securities and Exchange Commission zugelassen und reguliert (Autorisierungscode: 250/14). Kryptowährungs-CFDs stehen britischen Privatanlegern nicht zur Verfügung.",
	公司说明6:"Plus500AU Pty Ltd verfügt über die folgenden Lizenzen: AFSL (Nr. 417727), ausgestellt von ASIC, FSP (Nr. 486026), ausgestellt von der neuseeländischen FMA, und Authorised Financial Services Provider (Nr. 47546), ausgestellt von der südafrikanischen FSCA.",
	公司说明7:"Plus500EE AS ​​​​ist von der estnischen Finanzaufsichtsbehörde zugelassen und reguliert (Lizenz Nr. 4.1-1/18).",
	公司说明8:"Plus500SG Pte Ltd (UEN 201422211Z) verfügt über eine von der Monetary Authority of Singapore ausgestellte Kapitalmarktdienstleistungslizenz, die den Handel mit Kapitalmarktprodukten ermöglicht (Lizenznummer CMS100648).",
	公司说明9:"Plus500AE Ltd ist von der Dubai Financial Services Authority (F005651) zugelassen und wird von ihr reguliert.",
    加入我们的社群:"Tritt unserer Gemeinschaft bei",
	PLUS500集团:"PLUS500-GRUPPE",
	受监管的操作:"REGULIERTER BETRIEB",
	健全的全球监管框架:"Robuster globaler Regulierungsrahmen",
	治理和社会责任:"GOVERNANCE UND SOZIALE VERANTWORTUNG",
	底部说明:"Plus500 ist weiterhin bestrebt, in allen Aspekten seines Geschäfts verantwortungsvoll und nachhaltig zu agieren und ist davon überzeugt, dass dieser Ansatz sowohl seine Pflicht als auch ein wesentlicher Bestandteil einer effektiven Unternehmensführung ist. Plus500 engagiert sich für eine Reihe von ESG-Initiativen, um greifbaren Wert für unsere Mitarbeiter, Kunden, lokale Gemeinschaften und Wohltätigkeitsorganisationen sowie unsere Aktionäre zu schaffen.",
	投资者联系方式:"INVESTORENKONTAKTE",
	投资者关系主管:"Leiter Investor Relations",
	AI智慧型量化交易:"KI-intelligenter quantitativer Handel",
	订单执行快速可靠:"Die Auftragsausführung erfolgt schnell und zuverlässig",
	全天候自动分析行情:"Automatische Marktanalyse rund um die Uhr",
	交易受监管低风险:"Der Handel ist reguliert und risikoarm",
	稳定策略高效收益:"Stabile Strategie und effiziente Renditen",
	取款快速且安全:"Schnell und sicher Geld abheben",
	游戏说明:"Das erforderliche Mindestguthaben beträgt 10 USD, um am Spiel teilzunehmen und Geld zu verdienen",
}
