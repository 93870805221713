<template>
  <div class="page">
    <div class="header">
	  <title>Plus500 WebTrader | Leading CFD Platform | Online Trading </title>
      <div></div>
      <div style="margin-top:10px; height:30px;width:38%; text-align: center;  border-radius: 10px; border: 1px solid #cccccc;background-color:#fff;box-shadow: 0px 0px 7px 0px rgba(1, 1, 1, 0.35);">{{
            $t('语言切换')}}<img class="msg-icon" style="width: 15px;height: 15px;vertical-align:middle; " src="@/assets/lang2.png" alt="" @click="changlang()" /></div>
      <!-- <div>
        <img class="close" src="../../assets/img/close.png" alt="" />
      </div> -->
    </div>
    <div class="content">
      <img class="logo" src="../../assets/img/logo111.png" alt="" />
	  
	  <div style="height: 1.5rem;">
		  <van-tabs v-model="active" @click="tabxuanze" color="#000000" background="#ffffff" title-active-color="#000000" title-inactive-color="#000000">
		    <van-tab :title="$t('手机号码')"></van-tab>
		    <van-tab :title="$t('邮箱')"></van-tab>
		  </van-tabs>
	  </div>
	  
      <van-form>

        <div class="formText" style="background-color: #FFFFFF !important;">
          <!-- <select  v-model="guoma">
            <option :data-iconurl="item.img" style="border: none;border: 1px solid #cccccc;" v-for="(item, index) in list" :key="index">{{item.code}}
            </option>
          </select> -->
          <div v-if="active == 0" style="height: 40px; width:100px;border-radius: 5px; border:0px;background-color:#fff; 
			position: absolute; z-index: 999;left: 30px; top: 6.8rem; color: #FFFFFF;">
          <fdSelect :list="list" v-model="selected"></fdSelect>
          </div>
          <van-field v-if="active == 0" style="box-shadow: 0px 0px 7px 0px rgba(1, 1, 1, 0.35); border-radius: 5px;" v-model="username" :label="$t('国码')" :placeholder="$t('输入手机号码')" :center="true" /><br>
          <van-field v-if="active == 1" style="box-shadow: 0px 0px 7px 0px rgba(1, 1, 1, 0.35); border-radius: 5px;" v-model="username" :label="$t('邮箱')" :placeholder="$t('输入邮箱')" :center="true" /><br>
          <van-field style="box-shadow: 0px 0px 7px 0px rgba(1, 1, 1, 0.35); border-radius: 5px;" v-model="password" type="password" :label="$t('密码')" :placeholder="$t('请输入登录密码')" :center="true" />
          
		</div>
        <div style="width:95%;margin-left:10px; text-align: center;">
          <van-button class="btn" round block type="info" @click="onSubmit">{{
            $t('登录')
          }}</van-button>
        </div>
      </van-form>
    </div>
    <ROBOT />
    <!-- <div class="wel-foot" style="color:#2E86FE;">
      <router-link to="/forgetpass" style="background:#ffffff;width:100%;">&nbsp;&nbsp;&nbsp;&nbsp;{{ $t('忘记密码') }} ？&nbsp;&nbsp;&nbsp;&nbsp;</router-link>
    </div> -->
    <!-- <div class="center"> -->
	<div class="signUp" style="margin-top:40px;">
      <!-- <p  style="background:white;text-align:center;font-weight:bold;">{{ $t('加入我们的社群') }}:</p> -->
	  <router-link to="/register"><span  style="background:#ffffff;width:100%;">&nbsp;&nbsp;&nbsp;&nbsp;{{ $t('点击注册') }}&nbsp;&nbsp;&nbsp;&nbsp;</span></router-link>
    </div>
 <div class="signUp">
	 <!-- <img @click="See('https://www.youtube.com/@Plus500Site')" src="../../assets/youtube.png" style="width:40px;height: 40px;"/>
        <img @click="See('https://www.facebook.com/Plus500')" src="../../assets/fb.png" style="width:40px;height: 40px;"/> -->
	    <!-- <img @click="See('https://www.instagram.com/plus500/')" src="../../assets/ins.png" style="width:40px;height: 40px;"/> -->
		<!-- <img @click="See('https://twitter.com/plus500')" src="../../assets/twitter.png" style="width:40px;height: 40px;"/> -->
		<!--<img @click="See('https://chat.whatsapp.com/HWVOY0fXTXOLXiKq1l8dZv')" src="../../assets/ws.png" style="width:40px;height: 40px;"/>-->
		<!-- <img @click="See('https://t.me/plus500ai_global')" src="../../assets/tg.png" style="width:40px;height: 40px;"/><br><br><br><br><br> -->
		<!--<img @click="See('https://line.me/ti/p/L_d88h7cvc')" src="../../assets/line.png" style="width:40px;height: 40px;"/>-->
		<img @click="See('https://www.youtube.com/@Plus500Site')" src="../../assets/qa.gif" style="width:100%;"/>
    </div>
	<div class="signUp" style="margin-top:40px;">
	  
  </div>
</div>
</template>

<script>
import { apiLogin, apigetGuoma } from '@/http/api/' // 导入我们的api接口
import { Tab, Tabs, Button, Form, Field, Toast } from 'vant'
import ROBOT from '@/components/robot'
import fdSelect from "@/components/fd-select/fd-select.vue"
export default {
  components: {

    ROBOT,
    fdSelect

  },
  data () {
    return {
      username: '',
      password: '',
      guoma: "",
      list: [],
      selected: {}, //选中国旗拿到的数据
	  active: 0,
    }
  },
  created () {
    apigetGuoma({

    }).then((res) => {
      this.list = res.info.map(v=>{
        return {
          ...v,
          img:require(`@/static/images/country/${v.code.replace(/\+/g,'')}.png`),
        }
      });
      this.guoma = this.list[0].code;
      this.selected=this.list[0]
    });
  },
  methods: {
	  tabxuanze(name, title){
		  
	  },
    changlang () {
      this.$router.push('/lang')
    },
	See (e) {
	        window.location.href=e;
	     
	    },
    onSubmit () {
      Toast.loading({
        duration: 0,
        message: this.$t('加载中'),
        forbidClick: true
      })
	
      apiLogin({
        mobile: this.username,
        password: this.password,
        optionval: this.selected.code,
		isemail:this.active
      }).then((res) => {
        if (res.status == 1) {
          setTimeout(() => {
            // 存储用户登录状态
            localStorage.setItem('key', res.userid)
            localStorage.setItem('msg', 1)
            Toast.clear()
            Toast.success(this.$t('登录成功'))

            this.$router.replace('/')
          }, 1000)
        } else {
          Toast.fail(this.$t(res.info))
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.page {
  padding: 0 18px 40px;
  .logg {
    font-size: 40px;
    font-family: PingFang SC-中粗体, PingFang SC;
    font-weight: normal;
    color: #ffffff;
    text-align: center;
  }
}
.van-field {
  background-color: #ffffff !important;
}
.van-field__control {
  background-color: #ffffff !important;
}
.header {
  padding: 0 10px;
  height: 56px;
  line-height: 56px;
  display: flex;
  justify-content: space-between;
  .close {
    width: 28px;
    height: 28px;
  }
}
.content {
  text-align: center;
  .logo {
    width: 524px;
    height: 226px;
	margin-top: 80px;
	border-radius: 40px;
   // margin-top: 53px;
   // margin-bottom: 5px;
  }
  ::v-deep .van-cell {
    background-color: #171717;
  }
  ::v-deep input.van-field__control {
    color: #000;
  }
  .formText {
    // opacity: 1
    // margin-top: 20px;
    padding: 10px 21px;
    border: 1px solid rgba(255, 255, 255, 0.20000000298023224);
    border-radius: 20px;
  }
  ::v-deep .van-cell {
    height: 110px;
    line-height: 110px;
  }

  .btn {
  //  box-shadow: 0px 10px 20px #2e86fe;
    font-size: 40px;
	font-weight: bold;
    height: 103px;
    background: #0c2780;
    border: none;
	border-radius: 5px;
    color: #fff;
  }
}
.wel-foot {
  margin-left: 5px;
  font-size: 30px;
  
  font-family: PingFang SC-常规体, PingFang SC;
  font-weight: normal;
  
  margin-top: 20px;
}
.signUp {
  //   color: #fff;
  text-align: center;
  // width: 80%;
  // position: absolute;
  // bottom: 90px;
  // left: 50%;
  // margin-left: -40%;
  height: 30px;
  margin-top: 45px;
  font-size: 30px;
  font-family: PingFang SC-常规体, PingFang SC;
  font-weight: normal;
  color: #0d9135;
}

.bt {
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.20000000298023224);
}
</style>
