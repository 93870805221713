export default {
  tabbar: {
    首页: 'ön Sayfa',
    市场: 'Market',
    任务: 'görevler',
    团队: 'ekip',
    我的: 'Benim'
  },
  navbar: {
    充值: 'Depozito',
    提现: 'Çekil',
    帮助: 'FAQ',
    分享: 'Davet et',
	推广: 'Reklam geliri',
    活动: 'ikramiye',
    游戏: 'Oyunu',
  },	 
  payment: {
    付款方式详情: 'Ödeme yöntemi ayrıntıları',
    银行卡: 'banka kartı',
    钱包地址: 'cüzdan adresi',
    交易密码: 'İşlem Şifresi',
    保存: 'kurtarmak'
  },
  market: {
    市场: 'Market',
    名称: 'isim',
    价格: 'fiyat',
  },
 
  task: {
    任务: '任务',
	我的余额: '我的余额',
    增加订单数量: '增加订单数量',
    今日剩余订单: '今日剩余订单',
    频率: '频率',
    手动交易: '手动交易',
    预期收益: '预期收益',
    需要启动存款: '需要启动存款',
    任务说明: '任务说明',
    每天可以完成10个任务: '每天可以完成10个任务',
    联系VIP助手增加任务数量: '联系VIP助手增加任务数量',
    每个用户的任务总数为300次: '每个用户的任务总数为300次',
    购买机器人无需人工操作即可自动交易: '购买机器人无需人工操作即可自动交易',
    我知道了: 'Anlıyorum'
  },
  邀请好友注册领取佣金:"Arkadaşlarınızı kaydolmaya ve komisyon almaya davet edin",
  领取:"Almak",
  未满足条件:"Koşul karşılanmadı",
  总收入:"Toplam gelir",
  投资建议:"※Daha önemli getiri elde etmek için bileşik faiz yatırım planı uygulayın",
  语言切换: 'dil anahtarı',
  POS区块节点挖矿: 'Ai Kantitatif Ticaret Detayları',
  节点类型: 'Robot\nTürü',
  加入价格: 'Satın Al\nFiyat',
  质押资金: 'POS\nFonlar',
  质押周期: 'Dönem\n(gün)',
  每日收入: 'Günlük\nGelir',
  累计积分: 'Birikmiş\nPuanlar',
  代理等级: 'Temsilci\nSeviyesi',
  下级折扣比: 'Alt\nİndirim',
  代理折扣比: 'Ajan\nİndirim',
  下属等级: 'Ast\nDüzey',
  代理佣金比率: 'Temsilci\nKomisyon\nOranı',
  下属每日收入: 'Alt\nGünlük\n Kâr',
  代理佣金: 'Ajan\nKomisyon',
  引用对象: 'referans\nesne',
  积分等级折扣规则: 'Puan Kademesi İndirim Kuralları',
  积分等级折扣规则介绍: "Yapay Zeka niceliksel ticaretine başarılı bir şekilde katılan kullanıcılar ilgili hesap puanlarını alacaktır ve onların acente üst kullanıcıları da ilgili puanları artırabilir (doğrudan astların %15'i için geçerlidir).Toplam hesap puanları ilgili koşulları karşıladığında, acentenin seviyesi Hesap iyileştirilecektir. , Farklı seviyelerdeki Acente hesapları farklı oranlarda fiyat indirimi alabilir. İlgili puan indirimlerinin detayları aşağıdaki gibidir:",
  积分等级折扣规则介绍2: 'Farklı ilişkilere sahip (doğrudan) kullanıcılar, astların AI niceliksel işlemlerine katılım oranına bağlı olarak indirimler ve temsilci düzeyinde indirimler alacaktır. Diğer üst düzey yöneticiler de kendi acenteleri oranında indirim alacaklardır. Ai niceliksel işlemlerine katılım için fiyat indirimleri: İlgili temsilci seviyelerine sahip kullanıcılar, Ai niceliksel işlemlerine katılırken ilgili temsilci düzeyinde tercihli haklara sahip olacaklardır.',
  团队: 'ekip',
  代理佣金制度: 'Acente komisyon sistemi',
  代理佣金说明详情: 'A ile elde edilen LV1 ajanlarının oranı: %8, A ile elde edilen LV2 ajanlarının oranı: %3, A ile elde edilen LV3 ajanlarının oranı: %2. Örnek: B, C ve D nin günlük karı: 100 USD ve A tarafından elde edilen LV1 aracı ödülü: %8*100=8 USD. A tarafından elde edilen LV2 aracı ödülü: %3*100=3 USD. A tarafından elde edilen LV3 aracı ödülü: %2*100=2 USD. Toplam komisyon geliri: 8+3+2=13 USD dir.',
  代理结构图示: 'Proxy Yapı Şeması',
  代理结构图示说明: 'A sizi temsil eder, A B yi davet eder (A nın astı B dir), B C yi davet eder (B nin astı C dir) ve C D yi davet eder (C nin astı D dir). A nın birden fazla astı olabilir (B1, B2, B3, B4, B5, B6, vb.), B nin (B1, B2, B3) birden fazla astı olabilir (C1, c2, c3, c4, c5, c6, vb.) ) C...D...',
  高级代理佣金说明: 'Kıdemli Ajan Komisyon Talimatları',
  高级代理佣金说明详情: 'A sizi temsil eder, A B yi davet eder (A nın astı B dir), B C yi davet eder (B nin astı Cdir) ve C D yi davet eder (C nin astı D dir). Üstün aracı, kendi hesabı ve alt hesaplarında yer alan toplam düğüm madenciliği tutarının oranına göre farklı ajans komisyon geliri (%) elde edecektir. A, ajans ödüllerinin karşılık gelen oranını elde etmek için alt üyelerin günlük gelirini karşılaştırır. Katılımcı düğümler çalışır durumda olmalıdır. Acente geliri tüm finansman giderleri için INGSAI MINING tarafından sağlanır ve normal ast üyelerin günlük gelirlerini etkilemez.',
  高级代理佣金说明详情2: 'Örnek: Kıdemli aracıların toplam madencilik miktarı (A)>alt üyelerin toplam madencilik miktarının (B/C/D)>%30 u (Örnek: A>B/C/D nin %30 u)',
  积分: 'puan',
  规则: 'kural',
  会员级别: 'üyelik seviyesi',
  经验: 'toplam puanlar',
  代理信息: 'üye bilgisi',
  更多: 'Daha',
  团队奖励: 'takım komisyonu',
  昨天: 'Dün',
  本星期: 'Bu hafta',
  全部: 'Tümü',
  机器人说明: "Plus500Ai kullanıcıları aynı anda birden fazla niceliksel ticaret paketi satın alabilir ve NO.1 robotu hesap başına yalnızca 10 kez satın alabilir. Unutmayın: Kantitatif robotu satın aldıktan sonra stratejiyi aktif hale getirmek için tıklamayı unutmayın, aksi takdirde herhangi bir gelir olmayacaktır. Kantitatif robotun geçerlilik süresi, satın alma zamanına göre hesaplanır. Lütfen kantitatif ticaret stratejisini zamanında etkinleştirin. Kantitatif robotun süresi dolduktan sonra etkinleştirilmezse, kantitatif ticaret stratejisi paketini satın alma maliyetini kaybedersiniz! !! Plus500Ai kantitatif ticaretine katılan tüm kullanıcılar lütfen bu yönetmeliği dikkatlice okuyunuz! !",
  会员体验: 'Üye deneyimi',
  机器人采购: 'Robot Alımı',
  机器人启动押金: 'Düğüm POS tutarı',
  机器人礼物: 'düğüm hediyesi',
  机器人订单: 'düğüm sırası',
  一次性总和: 'göster',
  总计: 'Toplam',
  
  被激活: 'Zaten POS',
  工作中: 'AKTİF',
  暂停: 'POS u askıya al',
  结束: 'süresi dolmuş',
  刷新: 'tazelemek için',
  来源: 'kaynak',
  价格: 'Robot Paket Fiyatı',
  启动押金: 'POS tutarı',
  剩余时间: 'kalan zaman',
  激活时间: 'Aktivasyon zamanı',
  号: 'Sayı',
  我的资产: 'varlıklarım',
  退出: 'çıkış yap',
  邀请码: 'Davet Kodu',
  我的钱包余额: 'cüzdan bakiyem',
  我的机器人仓库: 'Benim Robot Listem',
  收益面板: 'kazanç paneli',
  去提款: 'para çek',
  昨天代理返利: 'Ajans komisyonu dün',
  累计代理返利: 'Kümülatif ajans komisyonu',
  今天机器人收益: 'Bugünün ticari karı',
  累计机器人收益: 'Kümülatif alım satım geliri',
  累计机器人回扣: 'Birikmiş ticaret komisyonu',
  累计总回报: 'kümülatif toplam gelir',
  自动订单机器人数量: 'Etkinleştirilmiş kantitatif ticaret robotu',
  我的付款方式: 'ödeme yöntemim',
  个人收入记录: 'kişisel gelir',
  团队收入记录: 'takım geliri',
  记录详情:'Ayrıntıları kaydet',
  充值记录: 'Mevduat kayıtları',
  我的团队成员: 'ekip üyelerim',
  活动中心: 'Kurallara Giriş',
  修改登录密码: 'Giriş şifresi ayarı',
  交易密码管理: 'İşlem şifresi ayarı',
  机器人: 'AI Ticaret Stratejisi',
  机器人性能: 'Robot Paneli',
  机器人价格: 'Ticaret Stratejisi Fiyatı',
  有效期: 'Ticari Geçerlilik Süresi',
  钱包地址: 'cüzdan adresi',
  天: 'gün',
  去购买: 'Satın almak',
  买个机器人: "Quant Trading Robot'u Satın Alın",
  我的钱包余额: 'hesap bakiyem',
  预计日收益: 'Tahmini günlük gelir',
  启动保证金: 'başlangıç ​​depozitosu',
  请输入购买数量: 'Lütfen satın alma miktarını girin',
  个机器人: 'Quant Ticaret Robotu',
  机器人购买后需要激活才能工作: 'Bir düğüm satın aldıktan sonra, yararlanmaya başlamak için ilgili POS miktarı gereklidir.',
  机器人激活需要从余额中扣除相应的启动押金:
    'Düğüm POS madenciliğinin ilgili POS tutarını hesap bakiyesinden düşürmesi gerekir',
  机器人暂停机器人停止时将返还启动押金不活跃的机器人可以赠送:
    'Düğüm POS madenciliği askıya alındığında hemen iade edilebilecek POS miktarı',
  购买: 'Satın almak',
  点击去了解我们: 'Kantitatif Ticaret',
  购买机器人: 'Bir robot al',
  快速入口: 'hızlı giriş',
  '日/个人受益': 'Gün/kişisel fayda',
  购买教程: 'AI Ticaret Robotu Listesi',
  激活金额: 'POS ÜCRETİ',
  订单数量: 'sipariş miktarı',
  我们的合作伙伴: 'Kripto Piyasası Ortaklarımız',
  提款: 'para çekmek',
  提款金额: 'para çekme miktarı',
  你还没有添加银行卡: 'Bir banka kartı eklemediniz',
  提现说明: 'Para çekme talimatları',
  说明1: '1.Tek tıklamayla seçebilir veya şarj miktarını manuel olarak girebilirsiniz.',
  说明2: '2.USD (TRC20) kullanılması tavsiye edilir, transfer ücreti en düşüktür',
  说明3: '3.Müşteri hizmetleri saatleri, Orta Amerika Saati ile Pazartesiden Cumaya AM8 - PM6 arasındadır.',
  说明4: 'Yeniden şarj etmek için USD kullanan kullanıcıların, başarılı transferin ve TXID nin doğru ekran görüntüsünü yüklemeleri gerekir ve platform incelemeyi geçtikten sonra hesap başarıyla alınır.',
  说明5: '4.Minimum yükleme 8 USD dir ve bu, sistemin hesabı hızlı bir şekilde inceleyip işleme koyması için uygundur.',
  说明6: '5.Şarj etmeyle ilgili herhangi bir sorunuz varsa, lütfen yardım için zamanında çevrimiçi müşteri hizmetleriyle iletişime geçin.',
  确认提款: 'Para çekmeyi onayla ',
  请输入手机号码:'telefon numarası',
  请输入提现金额: 'Para Çekme Miktarı ',
  提现费:'Çekim ücreti',
  提现须知: 'Lütfen para çekme kurallarını dikkatlice okuyunuz.',
  TIPS1: '1:Günlük para çekme inceleme süresi Pazartesiden Cumaya AM8 - PM6 arasındadır. Para çekme işlemleri hafta sonları yapılabilir ve para çekme inceleme zamanı Pazartesi günüdür.',
  TIPS2: '2:7X24 saat sistemi, istediğiniz zaman para çekin, en geç 24 saat içinde hesaba ulaşın, en kısa sürede 5 dakika içinde hesaba ulaşın.',
  TIPS3: '3:Çevrimiçi müşteri hizmetleri süresi: Pazartesiden Cumaya AM8 - PM6',
  TIPS4: '4:USD transferinin maliyeti nedeniyle, kullanıcı para çektiğinde platform bir para çekme ücreti alır.',
  TIPS5: '5:Minimum para çekme tutarı 10 USD dir.',
  钱包余额: 'cüzdan bakiyesi',
  输入数量:"Miktarı girin",
  数量:"miktar",
  手机号码:"telefon numarası",
  手机号码已存在:"Cep telefonu numarası kayıtlı",
  邮箱:"Posta",
  输入手机号码: 'Cep telefonu numarasını girin',
  请输入登录密码: 'giriş şifresi',
  登录: 'Giriş yapmak',
  输入账号: 'Hesap numarasını girin',
  密码: 'şifre',
  点击注册: 'Hesabınız yok？Hemen bir hesap oluşturun!',
  忘记密码: 'şifreyi unut',
  重新登录: 'Tekrar giriş yap',
  密码找回成功: 'Şifre başarıyla alındı',
  找回成功请重新登录: 'Başarıyla alın, lütfen tekrar giriş yapın',
  确认: 'teyit etmek',
  发送验证码: 'Doğrulama kodunu gönder',
  再次输入密码: 'Şifreyi Onayla',
  输入验证码: "E-posta CAPTCHA'sını girin",
  输入手机验证码:"Telefonunuzun OTP'sini girin",
  输入手机号: 'cep telefonu numarasını girin',
  输入密码: 'giriş şifresi',
  快速注册: 'çabuk kayıt',
  注册: 'kayıt olmak',
  立即登录: 'Zaten bir hesabınız var, giriş yapmak için tıklayın',
  请输入邮箱: 'Gmail adresi',
  输入用户名: 'Kullanıcı adınızı giriniz',
  请输入邀请码: 'Lütfen davet kodunu girin',
  返回: 'geri dönmek',
  密码找回失败: 'Şifre kurtarma başarısız oldu',
  输入的信息有误: 'Girilen bilgiler yanlış',
	获取验证码:"CAPTCHA",
	手机号码不能为空:"Telefon numarası boş geçilemez",
	账号不存在:"Hesap mevcut değil",
	发送成功:"Başarıyla gönderildi",
	今日收益:"（cari gelir）",
	"您好如果您中途终止POS-STAKING系统将收取5%的STAKING费用":"Merhaba, POS'u yarıda sonlandırırsanız sistem %5 POS işletim ücreti alır. Özel hatırlatma: Deneyimin ücretsiz düğümü askıya alındıktan sonra tekrar POS alınmayacak ve herhangi bir ücret alınmayacaktır! !",
	我同意:"Kabul ediyorum",
	用户账号ID:"Hesap Kimliği",
	取消:"İptal et",
	日收益:"Günlük gelir",
	请输入购买数量:"Lütfen satın alma miktarını girin",
	数量不能为空:"Miktar boş olamaz",
	加载中:"Yükleniyor...",
	唯一码:"Robot Kodlama",
	未激活:"AKTİF DEĞİL",
	激活:"Stratejiyi Başlat",
	购买时间:"alım zamanı",
	"钱包余额不足，激活该机器人失败":"Yetersiz hesap bakiyesi, düğüm POS madenciliği yapılamıyor",

	运行时长:"Çalışma süresi",
	签约购买:"bakiye alımı",
	系统赠送:"Sistem hediyesi",
	状态:"eyalet",
	正常:"normal",
	我的机器人:"Quant Ticaret Robotum",
	一级会员:"Seviye 1",
	二级会员:"Seviye 2",
	三级会员:"Seviye 3",
	人:"",
	充值客服:"Müşteri hizmetlerini şarj edin",
	充值:"Depozito",
	提现:"Çekil",
	提款密码:"Para çekme şifresi",
	设置交易密码:"İşlem şifresini ayarla",
	登录密码:"giriş şifresi",
	请输入交易密码:"işlem şifresini girin",
	再次请输入交易密码:"İşlem şifresini onaylayın",
	确认: 'teyit etmek',
	手机号码不能为空:"Telefon numarası boş geçilemez",
	两次密码不一致:"İki şifre eşleşmiyor",
	请输入验证码:"lütfen doğrulama kodunu girin",
	操作成功:"Başarılı operasyon",
	"用户名或密码不正确,登录失败":"Kullanıcı adı veya şifre yanlış, oturum açılamadı",
	登录成功:"giriş başarılı",
	充值说明:"Şarj talimatları",
	请先设置支付密码:"Lütfen önce işlem şifresini belirleyin",
	复制成功:"başarıyla kopyala",
	冻结机器人做单本金:"Kantitatif fonları dondurun",
	待审核:"incelenecek...",
	成功:"başarı",
	失败:"başarısız olmak",
	审核中:"inceleniyor",
	在线充值:"Çevrimiçi şarj edin",
	描叙:"betimlemek",
	POS节点挖矿LV0:"POS-LV0 düğüm madenciliği",
	POS节点挖矿LV1:"POS-LV1 düğüm madenciliği",
	POS节点挖矿LV2:"POS-LV2 düğüm madenciliği",
	POS节点挖矿LV3:"POS-LV3 düğüm madenciliği",
	POS节点挖矿LV4:"POS-LV4 düğüm madenciliği",
	POS节点挖矿LV5:"POS-LV5 düğüm madenciliği",
	银行卡提现:"Banka kartı para çekme",
	USD提现:"USD çekilmesi",
	三级代理:"Üçüncü seviye ajan",
	一级代理:"Birinci seviye ajan",
	二级代理:"ikincil ajan",
	一级:"Seviye 1",
	二级:"Seviye 2",
	三级:"Seviye 3",
	做单:"Düğüm madenciliği",
	登录失败:"giriş başarısız oldu",
	请勿重复操作:"Sık işlemleri tekrarlamayın",
	邀请码不正确:"davet kodu yanlış",
	团队返佣:"takım komisyonu",
	购买机器人返佣:"Robot Komisyon",
	本金返还:"POS iadesi",
	佣金收入:"Komisyon geliri",
	时间:"zaman",
	机器人做单返还:"POS tutarı iadesi",
	涨幅:"değişiklik",
	市场:"Market",
	客服:"Servis",
	验证码错误:"Doğrulama kodu hatası",
  付款方式:"USD çekme adresi",
	我的留言:"benim mesajım",
  暂无数据: 'Veri yok',
  银行卡:'banka kartı',
  修改: 'revize',
 '请确保您的钱包地址正确，如果您的地址输入错误而导致收不到钱，请及时联系在线客服协助':'Lütfen cüzdan adresinizin doğru olduğundan emin olun. Adresiniz yanlış girilirse ve para alınamıyorsa, lütfen yardım için zamanında çevrimiçi müşteri hizmetleri ile iletişime geçin.',
 连接借记卡:"连接借记卡",
 银行名称:'Banka ismi',
 姓名姓氏: 'İsim',
 IBAN号码:'IBAN 号码',
 '* 重要提示:借记卡信息必须真实有效才能取款。':'*Önemli hatırlatma: Para çekebilmeniz için banka hesap bilgilerinizin doğru ve geçerli olması gerekir。',
 帮助中心:'yardım Merkezi',
 会员须知:'Üye Bildirimi',
 '24小时内使用USD实时取款（推荐）':'1. 24小时内使用USD实时取款（推荐）',
 '银行卡 取款 *取款时间为10:00-18:00 *发起取款后2小时内入账*申请非取款时间或跨行取款将于次日10:00-18:00到账':' 2.银行卡 取款 *取款时间为10:00-18:00 *发起取款后2小时内入账*申请非取款时间或跨行取款将于次日10:00-18:00到账',
 我知道了:'Anlıyorum',
 链接点击:'Yönlendirme kayıt bağlantısı',
 确定:'Elbette',
 建立自己的团队邀请:'Kendi Takım Davetinizi Oluşturun',
 此账号已冻结:"Bu hesap donduruldu",
 手机号存已存在:"Cep telefonu numarası zaten mevcut",
 注册账号已存在:"Kayıtlı hesap zaten var",
 请确定新密码:"Lütfen yeni şifreyi onaylayın",
 请再次输入登录密码:"Lütfen giriş şifresini tekrar girin",
 密码长度不能少于6位:"Şifre uzunluğu 6 karakterden az olamaz",
 加入:'katıl',
 "你出售的机器人对方已接受,出售所得已打款到您账户,请查收。":
 	    "你出售的节点对方已接受,出售所得已打款到您账户,请查收。",
 	"你出售的机器人对方已拒绝接受":"你出售的节点对方已拒绝接受",
 	向你赠送一台机器人:"向你赠送一个节点，待确认",
 	向你出售一台机器人:"向你出售一个节点，待确认",
 	出售价格不能超过机器人价格:"出售价格不能超过节点价格",
 	银行卡已被绑定:"Banka hesabı kullanıldı",
 	USD_TRC20提现:'USD_TRC20 para çekme',
 	USD_ERC20提现:'USD_ERC20 para çekme',
 	银行卡提现:'Banka hesabının çekilmesi',
 	邀请赠送:'Davet bonusu',
 	机器人返佣:'Robot Komisyon',
 	升级:'başarılı satın alma',
 	充值主网:'充值主网',
   "您被限制出售机器人,请联系客服了解详情":
     "您被限制出售节点,请联系客服了解详情",
   交易编号: "işlem numarası",
   消息详情:"Mesaj Detayları",
   个人收益累计:"birikmiş kişisel gelir",
   今日代理收益:"Ajans geliri bugün",
   代理累计收益:"Ajans kümülatif geliri",
   截图保存推荐给朋友:"Yönlendirme kayıt bağlantınızı paylaşın ve takım gelir komisyonu ödülleri kazanmak için arkadaşlarınızı Plus500Ai platformuna katılmaya davet edin. Üçüncü seviye ekip üyeleri için gelir komisyonu %8-%3-%2'dir. Ekip komisyonu, alt seviyedeki üyelerin günlük gelir verilerine göre belirlenir ve her gün saat 00:00'dan sonra otomatik olarak hesap bakiyenize yansıtılır.",
   复制:"kopya",
   充值提示1:"İpucu 1: Minimum yeniden yükleme 10 USD'dir, yeniden yükleme yapabilirsiniz, aksi takdirde kredilendirilmezsiniz",
   充值提示2:"İpucu 2: Yeniden yüklemenin zincirde onaylanması gerekir. Başarılı bir yeniden yüklemeden sonra, yaklaşık bir dakika içinde cüzdan bakiyesine ulaşması beklenir.",
   请输入正确的充值金额:"Depozito miktarı ≥ 8 USD",
   推荐:"tavsiye etmek",
   充值金额:"depozito miktarı",
   请上传付款截图:"Lütfen başarılı ödemenin ekran görüntüsünü yükleyin",
   交易号: 'İşlem Hash numarası',
   充值ID提示:"Lütfen Txid işlem seri numaranızı yapıştırın",
   "请确保钱包地址无误，如因钱包地址填写错误导致无法收款而造成的损失，将有用户自己承担。":"Lütfen cüzdan adresinin doğru olduğundan emin olun. Cüzdan adresinin yanlış doldurulması nedeniyle paranın alınamamasından kaynaklanan herhangi bir kayıp kullanıcı tarafından karşılanacaktır.",
	上传凭证:"Ödeme belgesini yükleyin",
	充值确认: "göndermek",
	不能为空:"Boş olamaz",
  绑定钱包地址:'Cüzdan adresini bağla',
	备注:"not",
	请输入银行卡号:"Banka hesap numarasını girin",
	请输入你的名字:"adınızı giriniz",
	银行卡号:"Banka hesabı",
	添加银行卡:"Banka hesabını bağla",
	请选择银行:"Lütfen bir banka seçin",
	请输入钱包地址:"Lütfen cüzdan adresini girin",
	钱包地址:"cüzdan adresi",
	"密码错误次数过多,请等待x秒后再试":"Çok fazla yanlış şifre var, lütfen 600 saniye bekleyin ve tekrar deneyin",
	"此账号已冻结,请联系客服":"Bu hesap donduruldu, lütfen müşteri hizmetleri ile iletişime geçin.",
	手机号格式错误:"Bozuk telefon numarası",
	"取款须知":"Para Çekme Bildirimi",
	"须知1":"1. 24 saat içinde gerçek zamanlı para çekme için USD-TRC20 kullanın (önerilir)",
	"须知2":"2. Cumartesi ve Pazar günleri para çekme",
	"须知3":"*Kullanıcılar Cumartesi ve Pazar günleri para çekebilirler.",
	"须知4":"*Hafta sonu para çekme işlemleri Pazartesi 10:00-20:00 saatleri arasında gelecek",
	我知道了:"Anlıyorum",
	"提现0":"1USD=1USD ERC20",
	"提现1":"提示1：最低单笔提现100USD/天，单笔交易手续费5USD/次",
	"提现2":"TRC20提示2：每次提款最低10USD，每次交易手续费1USD。",
	"提现3":"银行账户提示3：最低单次取款为$10，交易手续费为交易金额的$6%",
	"提现4":"提现时间：提现申请提交后24小时内到账",
	
	已绑定:"Bağlı",
	去设置:"ayarlara git",
	注册成功:"başarılı Kayıt",
	汇率:"Döviz kuru",
	请输入正确的提现金额:"Lütfen doğru para çekme miktarını girin",
	提款记录:"Para çekme geçmişi",
	"绑定的钱包地址必须跟付款钱包一致，否则不会到账。":"tip1:绑定的钱包地址必须跟付款钱包一致，否则不会到账。",
	"充值成功后预计在一分钟左右到账，请检查钱包约。":"tip2:充值成功后预计在一分钟左右到账，请检查钱包余额。",
	请先绑定钱包地址:"Lütfen önce cüzdan adresini bağlayın",
	输入邮箱:"E-posta girin",
	邮箱已存在:"posta kutusu zaten var",
	注册邮箱已存在:"Kayıtlı e-posta zaten var",
	指定用户不存在:"Belirtilen kullanıcı mevcut değil",
	今日涨幅:"değişiklik",
	恭喜新用户:"Tebrikler yeni kullanıcı ",
	快捷充币:"Depozito",
	快速买USD:"USD şarjı",
	在线支付:"Online ödeme",
	自动到账:"otomatik hesap",
	线下支付:"çevrimdışı ödemeler",
	联系客服获取验证码:"Doğrulama kodunu almak için müşteri hizmetleriyle iletişime geçin",
	获取:"Elde etmek",
	退出成功:"başarıyla çık",
	不能低于最小充值:"Minimum depozitodan daha düşük olamaz",
	不能低于最小提现金额:"Minimum para çekme tutarından düşük olamaz",
	最小提现:"Minimum para çekme",
	设置成功:"başarıyla ayarlandı",
	官方充币:"sistem mevduatı",
	银行卡充值:"Banka yüklemesi",
	等待审核:"Denetlenen",
	可提现金额不足:"Para çekmek için yeterli miktar mevcut değil",
	未经授权:"yetkisiz",
	交易密码不正确:"İşlem şifresi yanlış",
	提现次数不足:"Yetersiz para çekme süreleri",
	官网:"官网",
	账户USD不足:"Hesap USD bakiyesi yetersiz",
	激活成功: "POS başarısı",
	操作成功: "Başarılı operasyon",
	修改成功:"Başarıyla değiştirildi",
	注册说明:"Mobil OTP ve E-posta CAPTCHA'yı doğrulamak için yalnızca bir yöntem seçmeniz gerekir ve başarıyla kaydolabilirsiniz",
	弹窗内容:
	"Plus500Ai kantitatif ticaret platformuna hoş geldiniz. Her kullanıcı 1 USD'lik kayıt bonusunu kullanarak 1 gün geçerli NO.0 kantitatif robot ticaret stratejisi paketini satın alabilir ve ücretsiz 0,5 USD kazanabilir. Diğer niceliksel ticaret stratejisi paketleri, ilgili ücretlerin kullanılmasını gerektirir. Kullanıcıların robotu satın aldıktan sonra, otomatik olarak çalışmaya başlayabilmesi için niceliksel ticaret stratejisini etkinleştirmeleri gerekir.Her ticaret stratejisi farklı bir geçerlilik süresine karşılık gelir. Kullanıcı ilgili robot puanlarını satın aldıktan sonra stratejiyi etkinleştirmek için tıklamazsa herhangi bir ticari gelir elde edemezsiniz.",
	标题1:'1.Plus500 platformunun tanıtımı',
	标题2:'2. Yapay Zeka niceliksel ticaretinin avantajları',
	标题3:"3. Neden Plus500Ai'yi seçmelisiniz?",
	标题4:"4. Plus500Ai'ye nasıl katılabilirsiniz?",
	标题5:'5.Plus500Ai pazar beklentileri',
	标题6:'6.Plus500Ai gelir modeli',
	标题7:'7.Para Yatırma ve Çekme Düzenlemeleri',
	标题8:"Battle Royale Oynanışı",
	内容8:"Plu500Ai platformunda Battle Royale oyununda 8 oda bulunuyor.Oyuncular 8 oda arasından istedikleri odayı seçip altın para koyabiliyor.Sistem geri sayımı bittikten sonra oyun sisteminde bir katil belirecek.Katil rastgele bir odaya giriyor. ve odadaki insanları ortadan kaldırır.Diğer oyuncular bu odadaki altınları paylaşarak kar elde edebilir. Battle royale oyununun oynanışı nispeten kısadır ve oyun yalnızca birkaç dakika sürer, böylece oyuncular boş zamanlarında oyunu oynayabilirler. Bölünme miktarı = bu dönemde katilin öldürdüğü toplam oda sayısı. Gerçek hisse tutarı = hisse tutarı * 0,9 (0,1 imha oranı hariç). Paylaşım oranı = diğer odalardaki gerçek paylaşım tutarı / toplam bahis tutarı. Kazanma miktarı = paylaşım oranı * gerçek bahis tutarı. 1 altın para = 1 USD olup, battle royale oyununa katılmak için minimum 10 USD gereklidir.",
	内容1:"Plus500 işlem platformu Plus500SEY Ltd. tarafından sağlanmaktadır. Buna göre Plus500SEY Ltd, bu web sitesinde açıklanan veya sunulan finansal ürünlerin ihraççısı ve satıcısıdır. Plus500SEY Ltd, Victoria'da ofisleri bulunan Seyşeller merkezli bir şirkettir (Şirket No. 8426415-1). Şirket, Seyşeller Finansal Hizmetler Otoritesi (FSA) tarafından SD039 lisans numarasıyla yetkilendirilmiş ve denetlenmektedir ve çeşitli temel ürünlerde Fark Sözleşmeleri (CFD) sağlamaktadır. Şirket, şu anda 2.800'den fazla finansal araçtan oluşan bir portföy sunan, hızla büyüyen bir CFD sağlayıcısıdır. Plus500SEY Ltd, Londra Menkul Kıymetler Borsası Ana Pazarında işlem gören ve merkezi Hayfa'da bulunan Plus500 Ltd'nin bir yan kuruluşudur.",
	内容2:'Yapay zekayla tam otomatik kantitatif ticaretin avantajı, işlemleri model ayarlarına göre yürütmek için rasyonel araçlar kullanmaktır.Robotun hiçbir duygusal değişimi yoktur ve insan doğasının, insanın açgözlülüğünün ve korkusunun ve operasyon sürecindeki diğer birçok eksiklik ve zayıflığın üstesinden gelebilir. Manuel operasyonlar çoğu zaman piyasanın yükseliş ve düşüşlerinden ve diğer çeşitli faktörlerden etkilenir ve uzun süre en iyi kar modeline göre çalışamaz.',
	内容3:'Plus500Ai, temel ürünlerini yeni ve mevcut pazarlara genişleterek, yeni ticari ve finansal ürünler piyasaya sürerek ve müşterilerle olan ilişkisini derinleştirerek çok varlıklı bir fintech grubu olarak konumunu giderek güçlendirdi. Varlıklarınızı koruyun ve şifreleme anahtarlarınızı sektör lideri güvenlik teknolojisiyle koruyun. Plus500Ai aynı zamanda küresel saklama olanağı da sunuyor; bu da düzenlemeye tabi olduğumuz, denetlendiğimiz ve 250 milyon dolara kadar sigortalı olduğumuz anlamına geliyor. Sermayenizi yalnızca varlıkları tutmak için değil aynı zamanda onları işe koymak amacıyla kullanın. Plus500Ai, risk toleransınıza bağlı olarak fonlarınızı Ai kantitatif ticaretinden klasik birincil hizmetlere kadar çeşitli şekillerde dağıtmanıza olanak tanır. Ek blockchain mühendislerini işe almadan kendi kullanıcılarınıza daha fazla hizmet sunabileceğiniz çevrimiçi platform.',
	内容4:"Plus500Ai'nin kuruluşundan bu yana yalnızca tek bir resmi web sitesi var: WWW.PLUS500AI.COM. Diğer web siteleri firmamıza ait olmadığından, tüm kullanıcıların APP'yi indirmek için resmi web sitesinde oturum açması gerekir. Kayıt olmak için kullanıcının cep telefonu numarasını, e-posta adresini ve tavsiyecinin davet kodunu hızlı bir şekilde kaydolmanız gerekir. Müşterilerle olan tüm iletişimler, e-posta veya çevrimiçi sohbet yoluyla yazılı olarak yapılır. Plus500 ile e-posta yoluyla iletişime geçmek için lütfen Bize Ulaşın sayfasındaki formu (başvuru formu) doldurun. Başvuru formunu gönderdikten sonra Plus500, başvuru formunda belirttiğiniz e-posta adresine doğrudan e-posta yoluyla bir yanıt gönderecektir. Herhangi bir sorunuz varsa lütfen müşteri hizmetlerine başvurun.",
	内容5:'Plus500Ai, müşterilere güvenilir ve sezgisel finansal ticaret fırsatları sunmak için çeşitli ürünler, cihazlar ve ülkelerde faaliyet göstermektedir.Ürünlerimizde ve ekiplerimizde her zaman mükemmelliği hedefledik. Gelişmiş tescilli teknoloji ve müşteri odaklı yaklaşımla kullanıcılarımız birinci sınıf bir ticaret hizmeti deneyimi yaşarlar.',
	内容6:"Plus500Ai, kıdemli veri analistlerinden oluşan bir ekip tarafından kripto para piyasası için profesyonel niceliksel ticaret stratejileri sağlar.Ai niceliksel ticaret robotuna yatırım yapan kullanıcılar, robotla eşleşen ticaret stratejilerine güvenerek niceliksel ticaret karları elde edebilirler. Ai niceliksel ticaret robotu, Plus500Ai'nin piyasa ticaret stratejisine dayalı olarak optimum ticaret süresini döngüsel olarak analiz eder ve düşük seviyeli alım ve yüksek fiyatlarla satış modeli aracılığıyla niceliksel ticaret karları elde eder. Plus500Ai, yatırım yapan kullanıcılara her gün en uygun niceliksel ticaret stratejilerini sağlar ve platform, kullanıcıların niceliksel gelir kârlarının %5'ini platform işletme fonu olarak alır.",
	内容7:"Plus500Ai şu anda USD yatırma ve çekme işlemlerini desteklemektedir ve gelecekte başka kanalların açılması durumunda resmi web sitesinde bir duyuru yapılacaktır. Minimum para yatırma ve çekme miktarı 10 USD'dir. En hızlı para yatırma ve çekme işlemi 5 dakika içinde ve en yavaş para yatırma ve çekme işlemi 24 saat içinde gerçekleşir. Kullanıcının para çekme süresinde herhangi bir sınırlama yoktur, istediğiniz zaman para çekebilirsiniz. Para çekme işlemleri normal iş günlerinde 5 dakika - 24 saat içinde gerçekleşir. Hafta sonu para çekme işlemleri Pazartesi günü işleme koyulacak şekilde ayarlanmıştır.",
	BSC提现:"USD_BSC çekilmesi",
	社区Ads收益:"Topluluk Reklamları geliri",
	Ads收益区间:"Reklam geliri 1-1000$",
	申请Ads费用:"Başvuru Reklam Ücreti",
	Ads促销地址:"Reklam tanıtım adresi",
	填写Ads促销地址:"Ads tanıtım adresini doldurun",
	申请Ads费用须知:"Reklam ücretleri için başvuru talimatları",
	须知A:"1. Kullanıcılar tanıtım videoları kaydeder ve bunları Reklam tanıtımı için kendi YouTube, Facebook, kanallarına veya diğer topluluklarına yükler, 1 ABD Doları ile 1000 ABD Doları arasında bir bonus için başvurur ve platform, başvuruyu gönderdikten 3 gün sonra Reklam video materyalinizi inceler ve Reklamları koyar video tanıtım etkisine göre hesaba bonus yatır Hesap bakiyeniz, tanıtım etkisi olmayan videolar incelenemez",
	须知B:"2. Aynı kanaldaki aynı Ads tanıtım videosu yalnızca bir kez bonus için başvurabilir ve yeni tanıtım video materyalleri haftada bir yayınlanabilir. Art arda kötü niyetli istekler göndermeyin. Platformda sizin tarafınızdan yapılmayan Reklam isteklerini veya incelenemeyen Reklam materyallerini kötü niyetli bir şekilde gönderirseniz, sistem algıladıktan sonra hesabınız yasaklanacaktır.",
	须知C:"3. Kullanıcı tarafından kaydedilen Reklamlar video materyalinin temel bir platform tanıtımı ve platform işlevi açıklamasına sahip olması gerekir. Video süresi 5 dakikadan kısa değildir. Kanalınıza ne kadar çok abone olursa, videonun tanıtım etkisi o kadar iyi ve o kadar yüksek olur. Başvuruda bulunabileceğiniz reklam ücreti. Belirli Reklam ücretleri, YOUTUBE örneğini alarak aşağıdaki gibidir:",
	须知D: "Abone 0-2K: başvuru tutarı 1-20$",
	须知D1:"Abone 3K-10K: başvuru miktarı 21-40$",
	须知D2:"Abone 11K-30K: başvuru tutarı 41-100$",
	须知D3:"Abone 31K-50K: başvuru tutarı 101-300$",
	须知D4:"Abone 51K-100K: başvuru tutarı 301-1000$",
	须知D5:"4. Sistem, her Pazar günü Ads bonus başvurularını inceleyip işleme koyacak ve nitelikli kullanıcılara sistem tarafından gönderilen bonusları alacaktır.",
	请求审核:"Inceleme iste",
	申请金额:"Başvuru Miktarı",
	URL促销地址:"URL tanıtım adresi",
	提交时间:"uygulama zamanı",
	需要更新的银行卡不存在:"Güncellenecek banka kartı mevcut değil",
	银行卡提现:"Banka kartı ile para çekme",
	预计收入:"Tahmini gelir",
	总投资:"Toplam yatırım",
	购买价:"alış fiyatı",
	邀请:"Davet et",
	成员:"üyeler",
	积分等级折扣规则介绍3:"Bu indirim tutarı yalnızca 31 Aralık 2023 tarihine kadar geçerli olup, 1 Ocak 2024 tarihinden sonra yeni bir düzenleme yapılacaktır.",
	LV1佣金:"SEVİYE1 Komisyonu",
	LV2佣金:"SEVİYE2 Komisyonu",
	LV3佣金:"SEVİYE3 Komisyonu",
	合格条件:"Kişi başı minimum 8$ depozito yatırın",
	随时可退:"Her an iade edilebilir",
	登录密码错误:"Yanlış oturum açma parolası",
	充值奖金:"USD≥50USD yatırın, bonus %2",
	充值奖金1:"IDR≥50USD yatırın, bonus %2",
	充值奖金2:"THB≥50USD yatırın, bonus %5",
	活动已过期:"Etkinliğin süresi doldu, bir sonraki etkinlik bekleniyor",
	不能重复发送短信:"Tekrarlanan kısa mesajları 10 dakika içinde gönderemezsiniz. Doğrudan kaydolmak için EMAIL CAPTCHA'yı kullanmanız önerilir.",
	限购说明1:"Merhaba, NO.0 madencilik düğümünün her kullanıcısı onu yalnızca bir kez satın alabilir.",
	修改银行提示:"Para çekme banka hesabı bağlandıktan sonra değiştirilemez. Yardıma ihtiyacınız varsa lütfen müşteri hizmetleriyle iletişime geçin.",
	修改钱包地址提示:"USD para çekme adresi bağlandıktan sonra değiştirilemez. Yardıma ihtiyacınız varsa lütfen müşteri hizmetleriyle iletişime geçin.",
	注册推送消息1:"INGSAI'ye hoş geldiniz. Yeni kullanıcılar bir POS madenciliği düğümü başlattıktan sonra her 5 dakikada bir POS madenciliği geliri alacaklardır. Takım komisyon geliri her gün saat 00.00 civarında otomatik olarak hesap bakiyesine girilecektir.",
	限购说明:"Merhabalar, bu tip kantitatif robot paketi için maksimum satın alma sayısı 10 katıdır, diğer kantitatif ticaret paketlerini de satın almanız tavsiye edilir.",
	注册协议:"(Hesabı Kaydet) seçeneğine tıklayarak 18 yaşından büyük olduğunuzu onaylamış olursunuz. Plus500AI'nin, ürünleri/hizmetleri hakkında ilgili bilgileri sağlamak amacıyla sizinle elektronik olarak iletişim kurmak için e-posta adresinizi veya diğer kişisel verilerinizi kullanabileceğini biliyorsunuz. Ayrıca, ticaret deneyiminizden en iyi şekilde yararlanmanıza yardımcı olmak için sizi piyasa olayları hakkında da bilgilendirebiliriz. Platformdaki (Bildirim Ayarları) ve (Gizlilik Ayarları) sekmelerinden bildirim tercihlerini yönetebilir ve değiştirebilirsiniz. Dilediğiniz zaman bu iletişimlere olan aboneliğinizi iptal edebilirsiniz. Daha fazla bilgi için lütfen (Gizlilik Sözleşmemizi) inceleyin.",
	公司说明1:"Plus500AI, Plus500 Ltd.'nin tescilli ticari markasıdır. Plus500 Ltd aşağıdaki bağlı ortaklıklar aracılığıyla faaliyet göstermektedir:",
	公司说明2:"Plus500SEY Ltd, Seyşeller Finansal Hizmetler Otoritesi (Lisans No. SD039) tarafından yetkilendirilmiş ve denetlenmektedir.",
	公司说明3:"Plus500SEY Ltd, bu web sitesinde açıklanan veya mevcut olan finansal ürünlerin ihraççısı ve satıcısıdır.",
	公司说明4:"Plus500UK Ltd, Mali Davranış Otoritesi tarafından yetkilendirilir ve denetlenir. Mali Yürütme Otoritesi Kayıt Numarası: 509909. Kripto para CFD'leri perakende kullanıcılar tarafından kullanılamaz.",
	公司说明5:"Plus500CY Ltd, Kıbrıs Menkul Kıymetler ve Borsa Komisyonu tarafından yetkilendirilir ve denetlenir (yetki kodu: 250/14). Kripto para CFD'leri Birleşik Krallık'taki perakende yatırımcılara sunulmamaktadır.",
	公司说明6:"Plus500AU Pty Ltd, aşağıdaki lisanslara sahiptir: ASIC tarafından verilen AFSL (No. 417727), Yeni Zelanda FMA tarafından verilen FSP (No. 486026) ve Güney Afrika FSCA tarafından verilen Yetkili Finansal Hizmet Sağlayıcısı (No. 47546).",
	公司说明7:"Plus500EE AS, Estonya Mali Denetleme Kurumu (Lisans No. 4.1-1/18) tarafından yetkilendirilmiş ve denetlenmektedir.",
	公司说明8:"Plus500SG Pte Ltd (UEN 201422211Z), Singapur Para Otoritesi tarafından verilen ve sermaye piyasası ürünlerinin ticaretini yapmasına olanak tanıyan bir sermaye piyasası hizmetleri lisansına sahiptir (lisans numarası CMS100648).",
	公司说明9:"Plus500AE Ltd, Dubai Finansal Hizmetler Otoritesi (F005651) tarafından yetkilendirilmiş ve denetlenmektedir.",
    加入我们的社群:"Topluluğumuza Katılın",
	PLUS500集团:"PLUS500 GRUBU",
	受监管的操作:"DÜZENLENMİŞ OPERASYONLAR",
	健全的全球监管框架:"Sağlam küresel düzenleyici çerçeve",
	治理和社会责任:"YÖNETİŞİM VE SOSYAL SORUMLULUK",
	底部说明:"Plus500, işinin her alanında sorumlu ve sürdürülebilir bir şekilde çalışmaya kendini adamıştır ve bu yaklaşımın hem görevi hem de etkili yönetimin önemli bir parçası olduğuna inanmaktadır. Plus500, çalışanlarımız, müşterilerimiz, yerel topluluklar, hayır kurumları ve hissedarlarımız için somut değer yaratmak amacıyla bir dizi ÇSY girişimine kendini adamıştır.",
	投资者联系方式:"YATIRIMCI İLETİŞİM",
	投资者关系主管:"Yatırımcı İlişkileri Başkanı",
	AI智慧型量化交易:"AI akıllı niceliksel ticaret",
	订单执行快速可靠:"Sipariş yürütme hızlı ve güvenilirdir",
	全天候自动分析行情:"24 saat otomatik piyasa analizi",
	交易受监管低风险:"Ticaret düzenlemeye tabidir ve düşük risklidir",
	稳定策略高效收益:"İstikrarlı strateji ve verimli getiriler",
	取款快速且安全:"Hızlı ve güvenli bir şekilde para çekin",
	游戏说明:"Oyuna girip para kazanmak için gereken minimum bakiye 10 USD'dir",
	
	货币:"TRY",
	能量值:"Enerji değeri",
	财富值:"servet değeri",
	佣金:"komisyon",
	累计工资:"Birikmiş maaş",
	工资:"maaş",
	租赁:"kira",
	去租赁:"Kiralamaya git",
	
	当前速率:"mevcut oran",
	当前工资:"mevcut maaş",
	预计今日收益:"Bugünkü tahmini kazançlar",
	累计佣金:"Birikmiş komisyon",
	邀请好友注册领取工资:'Arkadaşlarınızı kaydolmaya ve maaş almaya davet edin',
	月:'ay',
	符号:'tr',
	转盘抽奖:'Çark ile çekiliş',
	剩余抽奖次数:'Kalan çekiliş sayısı',
	关闭:'Kapat',
	恭喜您获得:'Tebrikler, kazandınız',
	很遗憾没有中奖:'Üzgünüm, kazanamadınız',
	游戏规则:'Oyun kuralları',
	租赁机器人后会获得抽奖次数:'Robotu kiraladığınızda çekiliş hakkı kazanacaksınız',
	中奖金额会存入余额:'Kazanılan miktar bakiyenize yüklenecektir',
	中奖记录请到收入记录中查看:'Kazanım geçmişinizi gelir kayıtlarında kontrol edebilirsiniz'
}
