<template>
  <layout :isShowFooter="false">
    <template #title><img class="team-icon" style="width:111px;height:25px;" src="@/assets/img/about.png" alt="" /></template>
   <template #right></template>
    <template #content>
       <div class="card card-1 flexbox_column ml32 mr32">
        <div class="flexbox_column a-center">
          <img class="robot" src="@/assets/img/logo111.png" alt="" />
          <!--<div class="f30 mt20 mb20">Plus500AI</div>-->
          <div class="mb20" style="font-weight:bold;">{{$t('POS区块节点挖矿')}}</div>
        </div>
        <div class="table mt10">
          <div class="title flexbox">
            <div
              class="title-item flexbox j-center"
              v-for="(item, i) in tab1.title"
              :key="i"
            >
              {{ item }}
            </div>
          </div>
          <div
            class="flexbox"
            :style="{ background: i % 2 === 0 ? '#fff' : '#0D6EFD' }"
            v-for="(item, i) in tab1.data"
            :key="i"
          >
            <div class="body-item flex1 flexbox j-center">{{ item.i }}</div>
            <div class="body-item flex1">{{ item.price }}</div>
            <div class="body-item flex1">{{ item.startUp }}</div>
            <div class="body-item flex1">{{ item.total }}</div>
            <div class="body-item flex1">{{ item.daily }}</div>
          </div>
        </div>
        <div class="flexbox j-end ccolor f30 mt20"></div>
      </div>
      <div class="card card-1 flexbox_column ml32 mr32 mt20">
        <div class="flexbox_column a-center">
          <div class="f30 mb20" style="font-weight:bold;">{{$t('积分等级折扣规则')}}</div>
          <div style="line-height: 20px">
            {{$t('积分等级折扣规则介绍')}} 
          </div>
        </div>
        <div class="table mt10">
          <div class="title flexbox">
            <div
              class="title-item flexbox j-center"
              v-for="item in tab2.title"
              :key="item"
            >
              {{ item }}
            </div>
          </div>
          <div
            class="flexbox"
            :style="{ background: i % 2 === 0 ? '#fff' : '#0D6EFD' }"
            v-for="(item, i) in tab2.data"
            :key="i"
          >
            <div class="body-item flex1 flexbox j-center">{{ item.i }}</div>
            <div class="body-item flex1">{{ item.price }}</div>
            <div class="body-item flex1">{{ item.startUp }}</div>
            <div class="body-item flex1">{{ item.total }}</div>
          </div>
        </div>
        <div class="lh20 ccolor mt30">
          {{$t('积分等级折扣规则介绍2')}}<br>
		  
        </div>
      </div>

      <div class="card card-1 flexbox_column ml32 mr32 mt20">
        <div class="flexbox_column a-center">
          <div class="f30 mb20" style="font-weight:bold;">{{$t('代理佣金制度')}}</div>
        </div>
        <div class="table mt10">
          <div class="title flexbox">
            <div
              class="title-item flexbox j-center"
              v-for="item in tab3.title"
              :key="item"
            >
              {{ item }}
            </div>
          </div>
          <div
            class="flexbox"
            :style="{ background: i % 2 === 0 ? '#fff' : '#0D6EFD' }"
            v-for="(item, i) in tab3.data"
            :key="i"
          >
            <div class="body-item flex1 flexbox j-center">{{ item.i }}</div>
            <div class="body-item flex1">{{ item.price }}</div>
            <div class="body-item flex1">{{ item.startUp }}</div>
            <div class="body-item flex1">{{ item.total }}</div>
          </div>
        </div>
        <div class="lh20 ccolor mt30">
          {{$t('代理佣金说明详情')}}
        </div>
      </div>

      <div class="card card-1 flexbox_column ml32 mr32 mt20">
        <div class="flexbox_column a-center">
          <div class="f30 mb20" style="font-weight:bold;">{{$t('代理结构图示')}}</div>
          <img class="typeicon" src="@/assets/icon/type.png" alt="" />
        </div>
        <div class="lh20 ccolor mt30">
          {{$t('代理结构图示说明')}}
        </div>
      </div>

      <!--<div class="card card-1 flexbox_column ml32 mr32 mt20">
        <div class="flexbox_column a-center">
          <div class="f30 mb20">{{$t('高级代理佣金说明')}}</div>
          <div style="line-height: 20px">
            {{$t('高级代理佣金说明详情')}}
          </div>
        </div>
        <div class="table mt10">
          <div class="title flexbox">
            <div
              class="title-item flexbox j-center"
              v-for="(item, i) in tab4.title"
              :key="i"
            >
              {{ item }}
            </div>
          </div>
          <div
            class="flexbox"
            :style="{ background: i % 2 === 0 ? '#131313' : '#292B2D' }"
            v-for="(item, i) in tab4.data"
            :key="i"
          >
            <div class="body-item flex1 flexbox j-center">{{ item.i }}</div>
            <div class="body-item flex1">{{ item.price }}</div>
            <div class="body-item flex1">{{ item.startUp }}</div>
            <div class="body-item flex1">{{ item.total }}</div>
            <div class="body-item flex1">{{ item.totals }}</div>
          </div>
        </div>
        <div class="lh20 ccolor mt30">
         {{$t('高级代理佣金说明详情2')}}
        </div>-->
      </div>
	   <div class=""><br>
	  <!--<div class="flexbox_column a-center" style="width: 100%;">
	   
	    <img class="typeicon" :src="info.team_img" alt="" />
	  
	  </div>-->
	  </div>
    </template>
  </layout> 
</template>

<script>
	import {
	  apikongtou, apiky_buy,apiUser_mas2,
	  
	} from "@/http/api/";
export default {
  data() {
    return {
		token:"",
		info:[],
      tab1: {
        title: [
          this.$i18n.t('节点类型'),
          this.$i18n.t('加入价格'),
          this.$i18n.t('日收益'),
          this.$i18n.t('质押周期'),
          this.$i18n.t('总收入')
        ],
        data: [
			{
			  i: 'Robot',
			  price: '50USDT',
			  startUp: '≈1.5USDT',
			  total:'1 '+ this.$i18n.t('天'),
			  daily: '≈1.5$'
			}
        ]
      },
      tab2: {
        title: [
          this.$i18n.t('累计积分'),
          this.$i18n.t('代理等级'),
          this.$i18n.t('下级折扣比'),
          this.$i18n.t('代理折扣比')
        ],
        data: [
          {
            i: '1000',
            price: 'VIP2',
            startUp: '5%',
            total: '10%'
          },
		  {
		    i: '2000',
		    price: 'VIP3',
		    startUp: '7%',
		    total: '12%',
		    },
		  {
		    i: '5000',
		    price: 'VIP4',
		    startUp: '9%',
		    total: '14%',
		    },
		  {
		    i: '10000',
		    price: 'VIP5',
		    startUp: '11%',
		    total: '16%',
		    },
          {
            i: '30000',
            price: 'VIP6',
            startUp: '15%',
            total: '20%'
          }
        ]
      },
      tab3: {
        title: [
          this.$i18n.t('下属等级'),
           this.$i18n.t('代理佣金比率'),
         this.$i18n.t('下属每日收入'),
         this.$i18n.t('代理佣金')
        ],
        data: [
          {
            i: 'LV_1',
            price: '8%',
            startUp: '$100',
            total: '$8'
          },
		  {
		    i: 'LV_2',
		    price: '3%',
		    startUp: '$100',
		    total: '$3',
		    },
          {
            i: 'LV_3',
            price: '2%',
            startUp: '$100',
            total: '$2'
          }
        ]
      },
      tab4: {
        title: [
          this.$i18n.t('下属等级'),
          this.$i18n.t('引用对象'),
          'A>30% of\n B/C/D',
          '20% of B/ \n C/D < A≤3 \n 0% of B/C/D',
          'A≤20% of \n B/C/D'
        ],
        data: [
          {
            i: 'LV_1',
            price: 'B',
            startUp: '8%',
            total: '5.4%',
            totals: '4%'
          },
		  {
		    i: 'LV_2',
		    price: 'C',
		    startUp: '3%',
		    total: '1.2%',
		    totals: '0.5%'
		  },
		  {
            i: 'LV_3',
            price: 'D',
            startUp: '2%',
            total: '0.8%',
            totals: '0.3%'
          }
        ]
      }
    }
  },
  created() {
     var token = localStorage.getItem("key");
     console.log("token", token);
     if (!token) {
       this.$router.replace("/login");
     }
     this.token = token;
     this.init();
  	 apiUser_mas2({
  	   userid: token
  	 }).then((res) => {
  	   // 未登录踢下线
  	   if (res.status == 10) {
  	     this.$router.replace('/login')
  	   }
  	   // this.showdown = res.user.showdown
  	   this.info = res.user
  	  
  	 })
   },
  methods: {
	  init(){
	  		 
	  		  this.yaoqinglist = [];
	  		  apikongtou({
	  		    userid: this.token,
	  		  }).then((res) => {
	  		  	this.yaoqinglist = res.info;
	  		});
	  },
  }
}
</script>

<style lang="scss" scoped>
.content {
  .card {
    background: #ffffff;
    border-radius: 10px;
    opacity: 1;
    padding: 35px;
    .typeicon {
      width: 100%;
	  border-radius: 10px;
    }
    .table {
      border-radius: 13px 13px 13px 13px;
      overflow: hidden;
      .title {
        background: linear-gradient(
          180deg,
          #0D6EFD 0%,
          rgba(243, 204, 46, 0) 100%
        );
        .title-item {
          flex: 1;
          min-height: 90px;
          display: flex;
		  font-weight:bold;
          justify-content: center;
          align-items: center;
          white-space: pre-wrap;
          text-align: center;
        }
      }
      .body-item {
        height: 77px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .card-1 {
    .robot {
      width: 362px;
      height: 143px;
    }
  }
}
</style>
