<template>
  <layout>
	  
    <template #left> <span></span></template>
    <template #title><img class="team-icon" src="@/assets/img/about.png" alt="" /></template>
    <template #content>
      <div class="content">
		  <div v-for="item in list" style="margin: 20px 0;" @click="handleUrl(item.id)">
			  <img style="max-width: 100%;" class="imgIcon" :src="item.img" alt="" />
			  <div style="font-size: 12px;color: #ffffff;text-indent: 28px;line-height: 20px;padding: 10px;">
				  {{item.mas}}
			  </div>
		  </div>
      </div>
	
    </template>
	  
  </layout>
</template>

<script>
	import ROBOT from '@/components/robot'
import { mapGetters } from 'vuex'
import { apiHangqing,shichangzixun} from "@/http/api/";
import { Toast } from 'vant'
import pako from 'pako'
export default {
	components: {
	  
		ROBOT,
	
	},
  data() {
    return {
      partnerStatus: false,
      priceStatus: true,
      changeStatus: true,
		token:"",
		list:[]
    }
  },
  computed: {
  },
	created() {
	Toast.loading({
	  duration: 0,
	  message: this.$t('加载中'),
	  forbidClick: true,
	  
	});
	   shichangzixun().then((res) => {	
			if(res.info){
				this.list = res.info;
			}
				Toast.clear();
		}); 
		this.getHQ();
	},
	methods:{
		getHQ(){
			// apiHangqing({}).then((res) => {
			// 	this.HQlist = res
			// 	this.btc = res[0].last;
			// 	this.fil = res[1].last;
			// 	this.xch = res[2].last;
			// 	this.doge = res[3].last;
			// 	this.bch = res[4].last;
			// 	this.zec = res[5].last;
			// 	this.eth = res[6].last;
			// 	this.eos = res[7].last;
			// 	this.ltc = res[8].last;
			// 	this.etc = res[9].last;
			// 	this.sendSocket()
			// }); 
		},
		handleUrl(id) {
		  //window.location.href = url
		  this.$router.push('/marketdetail/'+id)
		},
		
	}
}
</script>

<style lang="scss" scoped>
.team-icon {
	  width: 302px;
	  height: 80px;
    image-rendering: pixelated;
	}
.content {}
</style>
