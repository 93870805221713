<template>
  <div class="page">
    <div class="header">
      <div @click="getBack">
        <img class="img" src="../../assets/img/arrow.png" alt="" />
      </div>
      <div class="logg">{{ $t('付款方式') }}</div>
      <div>
        <!-- <img class="img" src="../../assets/img/添加.png" alt="" /> -->
      </div>
    </div>
    <div class="contnet">
      <div class="card">
        <p class="text">{{ $t('银行名称') }}</p>
		 <van-field class="input"
		              readonly
		              clickable
		              
		              :value="value"
		              :placeholder="$t('请选择银行')"
		              @click="showPicker = true"
		            />
        <van-popup v-model="showPicker" round position="bottom" >
                      <van-picker
                        show-toolbar
                        :columns="columns"
                        @cancel="showPicker = false"
                        @confirm="onConfirm"
                      />
        </van-popup>
        <p class="text">{{ $t('姓名姓氏') }}</p>
        <van-field class="input" v-model="name" placeholder="DADF" />
        <p class="text">{{ $t('银行卡号') }}</p>
        <van-field
          class="input"
          v-model="card"
          :placeholder="$t('请输入银行卡号')"
        />
<!-- 		<p class="text">{{ $t('备注') }}</p>
		<van-field class="input" v-model="remark" :placeholder="$t('备注')" /> -->
        <div class="description">
          <div>
            {{ $t('* 重要提示:借记卡信息必须真实有效才能取款。') }}
          </div>
        </div>
      </div>
    </div>
	<van-popup v-model="paypwd" round closeable @close="closepaypwd">
		  <div class="wt-select-dialog" style=" height: 8.00097rem;text-align: center;background-color: #2a2c2e">
		    <br>
		    <div class="wt-dialog_bd">
		
			<p style="color: #FFFFFF;text-align: center;z-index: 10; font-size: 18px; ">{{$t("请输入交易密码")}} <span style="color: #26B77E;"></span></p>
		     <div class="wt-card" style="min-height: 50px;text-align: center;">
		         <van-field class="input" v-model="paypasswd" :placeholder="$t('请输入交易密码')" />
		      </div>
		      <van-row >
		
				<div >
		        <van-col span="12" style="width: 90%; padding-top: 20px;">
		          <van-button  type="info" style="width: 5.5rem;background-color: #2a2c2e" @click="saveBank">{{
		            $t("确定")
		          }}</van-button>
		        </van-col>
				</div>
		      </van-row>
		    </div>
		  </div>
		</van-popup>
    <!-- <div class="prompt">Cheqam在24小时内取款</div> -->
    <div class="footerBtn">
      <HBBTN @clickEvent="saveBank">
        {{ $t('确认') }}
      </HBBTN>
    </div>
    <ROBOT></ROBOT>
	
	
  </div>
</template>

<script>
import ROBOT from '@/components/robot'
import HBBTN from '@/components/hb-btn'
import { NavBar, Button, Field, Popup, Picker, Toast,Row,Col } from "vant";
import { apiUser_bank_type, apiUser_bank_mas,getUrlKey,apieditcard_ajax } from "@/http/api/";
import md5 from 'js-md5';
export default {
  name: 'welcome',
  components: {
    HBBTN,
    ROBOT,
	[Popup.name]: Popup,
  },
  data() {
    return {
      name: '',
      card: '',
      name: '',
      inputData: '',
	  columns: [],
	  showPicker: false,
	  paypwd:false,
	  paypasswd:"",
	  remark:"",
	  value:"",
	  id:0
    }
  },
 created: function () {
     // `this` 指向 vm 实例
 
     const token = localStorage.getItem("key");
     console.log("token", token);
     if (token == null) {
       this.$router.replace("/login");
     }
     this.userid = token;
    apiUser_bank_type({
      userid: token,
    }).then((res) => {
      console.log(res);
       this.columns = res.info;
    });
 	var url = window.location.href;
 	let id = 	getUrlKey('id', url);
 	this.id = id;
	var that = this;
 	apiUser_bank_mas({
 	  userid: token,
 	}).then((res) => {
 	 console.log(res);
 	  for(let i=0; i < res.user.length; i++){
 		  if(res.user[i].id == id){
 			
 			  if(res.user[i].type=="BANK")
 			    {
 					that.name = res.user[i].name;
 					that.card = res.user[i].bankcard2;
 				    that.value = res.user[i].bank;
 				}
 			
 		  }
 	  }
 	});
   },
  methods: {
	   saveBank() {
	        if (!this.card) {
	          Toast.fail(this.$t("不能为空"));
	          return;
	        }
	        if (!this.value) {
	          Toast.fail(this.$t("不能为空"));
	          return;
	        }
	        if (!this.name) {
	          Toast.fail(this.$t("不能为空"));
	          return;
	        }
	        if (!this.remark) {
	          // Toast.fail(this.$t("不能为空"));
	          // return;
	        }
	  // 	  if(this.paypasswd=="")
	  // 	  {
	  // 		  this.paypwd=1;
	  // 		  return;
	  // 	  }
		 
	        apieditcard_ajax({
	          userid: this.userid,
	          name: this.name,
	          card: this.card,
	          bank: this.value,
	          remark: this.remark,
						id:this.id,
						paypass:md5(this.paypasswd),
	        }).then((res) => {
	          console.log(res);
	          if (res.status) {
	            setTimeout(() => {
	              Toast.success(this.$t(res.info));
	              this.$router.push("/bankCard");
	            }, 1000);
	          } else {
	            Toast.fail(this.$t(res.info));
	          }
	        });
	      },
    confirmEvent() {
	
      this.paypwd = true;
    },
	closepaypwd(){
		this.paypwd = false;
	},
    getBack() {
      this.$router.back()
    },
	
	 onConfirm(value) {
	      this.value = value;
	      this.showPicker = false;
	      if (value == "USD") {
	        this.$router.push("/bankCard");
	      }
	    },
  }
}
</script>

<style lang="scss" scoped>
.page {
  padding: 0 30px 40px 40px;
  .header {
    padding: 0 10px;
    height: 56px;
    line-height: 56px;
    display: flex;
    justify-content: space-between;

    .img {
      width: 40px;
    }
    .logg {
      font-size: 40px;
      font-family: PingFang SC-中粗体, PingFang SC;
      font-weight: normal;
      color: #ffffff;
    }
  }
  .contnet {
    margin-top: 42px;
    width: 100%;
    border-radius: 20px;
    background-color: #2a2c2e;
    .card {
      padding: 40px 26px;

      .text {
        color: #888;
      }
    }
    .description {
      color: #0d9135;
      line-height: 30px;
      letter-spacing: 1px;
    }
  }
  .footerBtn {
    margin-top: 50px;
  }
}
</style>
