export const prizeList = [
  {
    icon: require("../../assets/img/give_up.png"), // 奖品图片
    name: "Sorry", // 奖品名称
    isPrize: 0 // 该奖项是否为奖品
  },
  {
    icon: require("../../assets/img/bean_one.png"),
    name: "1USD",
    isPrize: 1
  },
  {
    icon: require("../../assets/img/bean_two.png"),
    name: "5USD",
    isPrize: 1
  },
  {
    icon: require("../../assets/img/bean_five.png"),
    name: "10USD",
    isPrize: 1
  },
  {
    icon: require("../../assets/img/bean_ten.png"),
    name: "100USD",
    isPrize: 1
  },
  {
    icon: require("../../assets/img/bean_500.png"),
    name: "1000USD",
    isPrize: 1
  },
  {
    icon: require("../../assets/img/saodi.png"),
    name: "Robot",
    isPrize: 1
  },
  {
    icon: require("../../assets/img/iphone.png"),
    name: "iPhone16",
    isPrize: 1
  }
]