export default {
  tabbar: {
    首页: 'بيت',
    市场: 'سوق',
    任务: 'مهمة',
    团队: 'الفريق',
    我的: 'خاصتي'
  },
  navbar: {
    充值: 'تعبئة رصيد',
    提现: 'انسحب',
    帮助: 'التعليمات',
    分享: 'ادعو',
	推广: 'أرباح الإعلانات',
    活动: 'علاوة',
    游戏: 'لعبة',
  },	 
  payment: {
    付款方式详情: 'تفاصيل طريقة الدفع',
    银行卡: 'بطاقة مصرفية',
    钱包地址: 'عنوان المحفظة',
    交易密码: 'كلمة مرور المعاملة',
    保存: 'يحفظ'
  },
  market: {
    市场: 'سوق',
    名称: 'اسم',
    价格: 'سعر',
  },
 
  task: {
    任务: '任务',
	我的余额: '我的余额',
    增加订单数量: '增加订单数量',
    今日剩余订单: '今日剩余订单',
    频率: '频率',
    手动交易: '手动交易',
    预期收益: '预期收益',
    需要启动存款: '需要启动存款',
    任务说明: '任务说明',
    每天可以完成10个任务: '每天可以完成10个任务',
    联系VIP助手增加任务数量: '联系VIP助手增加任务数量',
    每个用户的任务总数为300次: '每个用户的任务总数为300次',
    购买机器人无需人工操作即可自动交易: '购买机器人无需人工操作即可自动交易',
    我知道了: '我知道了'
  },
  邀请好友注册领取佣金:"قم بدعوة الأصدقاء للتسجيل والحصول على العمولات",
  领取:"يستلم",
  未满足条件:"لم يتم استيفاء الشرط",
  总收入:"إجمالي الدخل",
  投资建议:"※ قم بتنفيذ خطة استثمار الفائدة المركبة للحصول على عوائد أكبر",
  语言切换: 'تبديل اللغة',
  POS区块节点挖矿: 'تفاصيل التداول الكمي لمنظمة العفو الدولية',
  节点类型: 'روبوت \n النوع',
  加入价格: 'شراء\n سعر',
  质押资金: 'نقاط البيع \n الأموال',
  质押周期: 'مدة\n(يوم)',
  每日收入: 'يوميًا\n الدخل',
  累计积分: 'المجموع الإجمالي\n نقاط',
  代理等级: 'انجينت\n درجة',
  下级折扣比: 'المرؤوس\n تخفيض',
  代理折扣比: 'وكيل \n تخفيض',
  下属等级: 'المرؤوس\n درجة',
  代理佣金比率: 'وكيل\n عمولة\n النسبة',
  下属每日收入: 'المرؤوس\n يوميًا\n الإيرادات',
  代理佣金: 'وكيل\n عمولة',
  引用对象: 'اقتبس\n هدف',
  积分等级折扣规则: 'قواعد خصم فئة النقاط',
  积分等级折扣规则介绍: "سيحصل المستخدمون الذين يشاركون بنجاح في التداول الكمي لـ Ai على نقاط الحساب المقابلة، ويمكن للمستخدمين المتميزين من وكلاءهم أيضًا زيادة النقاط المقابلة (تنطبق على 15٪ من المرؤوسين المباشرين).عندما يستوفي إجمالي نقاط الحساب الشروط المقابلة، فإن مستوى الوكيل سيتم تحسين الحساب، ويمكن لحسابات الوكلاء بمستويات مختلفة الحصول على نسب مختلفة من خصومات الأسعار. تفاصيل خصومات النقاط ذات الصلة هي كما يلي:",
  积分等级折扣规则介绍2: "سيحصل المستخدمون الذين لديهم علاقات مختلفة (مباشرة) على خصومات وخصومات على مستوى الوكيل بناءً على نسبة مشاركة المرؤوسين في المعاملات الكمية لـ Ai. كما سيحصل كبار المسؤولين التنفيذيين الآخرين على مستويات خصم تتناسب مع وكلائهم. خصومات الأسعار للمشاركة في المعاملات الكمية لـ Ai: سيحصل المستخدمون ذوو مستويات الوكيل المقابلة على الحقوق التفضيلية المقابلة على مستوى الوكيل عند المشاركة في المعاملات الكمية لـ Ai.",
  团队: 'الفريق',
  代理佣金制度: 'نظام عمولة الوكالة',
  代理佣金说明详情:"نسبة عوامل LV1 التي حصل عليها A: 8%، نسبة عوامل LV2 التي حصل عليها A: 3%، نسبة عوامل LV3 التي حصل عليها A: 2%. مثال: الربح اليومي لـ B وC وD هو: 100 دولار أمريكي، ومكافأة الوكيل LV1 التي حصل عليها A: 8%*100=8 دولار أمريكي. مكافأة وكيل LV2 التي حصل عليها A: 3%*100=3 دولار أمريكي. مكافأة وكيل LV3 التي حصل عليها A: 2%*100=2 دولار أمريكي. إجمالي دخل العمولة هو: 8+3+2=13 دولار أمريكي.",
  代理结构图示: 'مخطط هيكل الوكيل',
  代理结构图示说明: 'A يمثلك A يدعو B (مرؤوس A هو B) B يدعو C (مرؤوس B هو C) C يدعو D (مرؤوس C هو D) A يمكن أن يكون له مرؤوسون متعددون (B1 ، B2 ، B3 ، B4 ، B5 ، B6 ، إلخ. ) B (B1، B2، B3) قد يكون لها أيضًا مرؤوسون متعددون (C1 ، c2 ، c3 ، c4 ، c5 ، c6 ، إلخ.) C ... D ...',
  高级代理佣金说明: 'تعليمات عمولة الوكلاء الأقدم',
  高级代理佣金说明详情: 'A يمثلك A يدعو B (المرؤوس A هو B) B يدعو C (المرؤوس B هو C) C يدعو D (المرؤوس C هو D) سيشارك الوكيل المتفوق في المبلغ الإجمالي لتعدين العقدة وفقًا لحسابه الخاص وحساب المرؤوس نسبة الحساب للحصول على دخل عمولة مختلف للوكيل (٪) يقارن A الدخل اليومي للأعضاء المرؤوسين للحصول على النسبة المقابلة لمكافآت الوكيل يجب أن تكون العقد المشاركة في حالة نشطة عادية. يتم توفير دخل الوكيل بواسطة INGSAI MINING. لن يتم توفير جميع النفقات المالية تؤثر على الدخل اليومي للأعضاء المرؤوسين العاديين.',
  高级代理佣金说明详情2: 'مثال: إجمالي كمية التعدين لكبار الوكلاء (أ)> 30٪ من إجمالي كمية التعدين للأعضاء الأقل (B / C / D) (مثال: A> 30٪ من B / C / D)',
  积分: 'أشر',
  规则: 'قواعد',
  会员级别: 'مستوى العضوية',
  经验: 'مجمل النقاط',
  代理信息: 'معلومات الاعضاء',
  更多: 'أكثر',
  团队奖励: 'عمولة الفريق',
  昨天: 'أمس',
  本星期: '.هذا الاسبوع',
  全部: 'جميع',
  机器人说明: "يمكن لمستخدمي Plus500Ai شراء حزم تداول كمية متعددة في نفس الوقت، ويمكن للروبوت رقم 1 الشراء 10 مرات فقط لكل حساب. تذكر: لا تنس النقر لتفعيل الإستراتيجية بعد شراء الروبوت الكمي، وإلا فلن يكون هناك دخل. يتم احتساب مدة صلاحية الروبوت الكمي على أساس وقت الشراء، يرجى تفعيل استراتيجية التداول الكمي في الوقت المناسب، إذا لم يتم تفعيل الروبوت الكمي بعد انتهاء صلاحيته، فسوف تخسر تكلفة شراء حزمة استراتيجية التداول الكمي! !!يرجى من جميع المستخدمين المشاركين في التداول الكمي Plus500Ai قراءة هذه اللائحة بعناية! !",
  会员体验: 'تجربة الأعضاء',
  机器人采购: 'شراء الروبوت',
  机器人启动押金: 'كمية نقطة البيع',
  机器人礼物: 'هدية العقدة',
  机器人订单: 'ترتيب العقدة',
  一次性总和: 'تبين',
  总计: 'المجموع',
  
  被激活: 'بالفعل POS',
  工作中: 'تم التنشيط',
  暂停: 'وقفةPOS',
  结束: 'تنتهي صلاحيته',
  刷新: 'للتحديث',
  来源: 'مصدر',
  价格: 'سعر باقة الروبوت',
  启动押金: 'POSالمبلغ',
  剩余时间: 'الوقت المتبقي',
  激活时间: 'وقت التنشيط',
  号: 'رقم',
  我的资产: 'الأصول الخاصة بي',
  退出: 'تسجيل الخروج',
  邀请码: 'شفرة الدعوة',
  我的钱包余额: 'رصيد محفظتي',
  我的机器人仓库: 'قائمة الروبوتات الخاصة بي',
  收益面板: 'لوحة الأرباح',
  去提款: 'سحب المال',
  昨天代理返利: 'وكالة عمولة أمس',
  累计代理返利: 'عمولة الوكالة التراكمية',
  今天机器人收益: 'أرباح التداول اليوم',
  累计机器人收益: 'دخل التداول التراكمي',
  累计机器人回扣: 'عمولة التداول المتراكمة',
  累计总回报: 'إجمالي الدخل التراكمي',
  自动订单机器人数量: 'تنشيط روبوت التداول الكمي',
  我的付款方式: 'طريقة الدفع الخاصة بي',
  个人收入记录: 'الدخل الشخصي',
  团队收入记录: 'دخل الفريق',
  记录详情:'سجل التفاصيل',
  充值记录: 'إعادة شحن السجلات',
  我的团队成员: 'أعضاء فريقي',
  活动中心: 'مقدمة المنافع',
  修改登录密码: 'إعداد كلمة مرور تسجيل الدخول',
  交易密码管理: 'إعداد كلمة مرور المعاملة',
  机器人: 'استراتيجية التجارة منظمة العفو الدولية',
  机器人性能: 'لوحة روبوت',
  机器人价格: 'سعر استراتيجية التداول',
  有效期: 'فترة صلاحية التجارة',
  钱包地址: 'عنوان المحفظة',
  天: 'يوم',
  去购买: 'شراء',
  买个机器人: 'شراء روبوت التداول الكمي',
  我的钱包余额: 'رصيد حسابي',
  预计日收益: 'الدخل اليومي المقدر',
  启动保证金: 'إيداع بدء التشغيل',
  请输入购买数量: 'الرجاء إدخال كمية الشراء',
  个机器人: 'روبوت التداول الكمي',
  机器人购买后需要激活才能工作: 'بعد شراء عقدة ، يلزم المقدار المقابل لنقاط البيع لبدء الاستفادة',
  机器人激活需要从余额中扣除相应的启动押金:
    'يحتاج تعدين Node POS إلى خصم مبلغ POS المقابل من رصيد الحساب',
  机器人暂停机器人停止时将返还启动押金不活跃的机器人可以赠送:
    'مقدار نقاط البيع التي يمكن إرجاعها فورًا عند تعليق تعدين نقطة البيع POS',
  购买: 'شراء',
  点击去了解我们: 'التداول الكمي',
  购买机器人: 'شراء روبوت',
  快速入口: 'دخول سريع',
  '日/个人受益': 'الدخل اليومي / الشخصي',
  购买教程: 'قائمة الروبوتات التجارية AI',
  激活金额: 'كلفةPOS',
  订单数量: 'كمية الطلب',
  我们的合作伙伴: 'شركاؤنا في سوق التشفير',
  提款: 'سحب المال',
  提款金额: 'كمية السحب',
  你还没有添加银行卡: 'لم تقم بإضافة بطاقة مصرفية',
  提现说明: 'تعليمات الانسحاب',
  说明1: '1.يمكنك الاختيار بنقرة واحدة أو إدخال مبلغ إعادة الشحن يدويًا',
  说明2: '2.يوصى باستخدام USD (TRC20) ، تكون رسوم التحويل هي الأدنى',
  说明3: '3.ساعات خدمة العملاء من الاثنين إلى الجمعة AM8 إلى PM6 بتوقيت أمريكا الوسطى',
  说明4: 'يحتاج المستخدمون الذين يستخدمون USD لإعادة الشحن إلى تحميل لقطة الشاشة الصحيحة لعملية النقل الناجحة و TXID ، وسيتم استلام الحساب بنجاح بعد اجتياز النظام الأساسي للمراجعة',
  说明5: '4.الحد الأدنى لإعادة الشحن هو 10 دولارات أمريكية ، وهو أمر مناسب للنظام لمراجعة الحساب ومعالجته بسرعة',
  说明6: '5.إذا كانت لديك أي أسئلة حول إعادة الشحن ، فيرجى الاتصال بخدمة العملاء عبر الإنترنت في الوقت المناسب للحصول على المساعدة',
  确认提款: 'تأكيد السحب ',
  请输入手机号码:'رقم التليفون',
  请输入提现金额: 'كمية السحب ',
  提现费:'رسوم السحب',
  提现须知: 'يرجى قراءة قواعد الانسحاب بعناية',
  TIPS1: '1:وقت مراجعة السحب اليومي من الاثنين إلى الجمعة AM8 إلى PM6 يمكن إجراء عمليات السحب في عطلات نهاية الأسبوع ، ووقت مراجعة السحب هو يوم الاثنين',
  TIPS2: '2:نظام 7 × 24 ساعة ، وسحب الأموال في أي وقت ، والوصول إلى الحساب في غضون 24 ساعة على أبعد تقدير ، والوصول إلى الحساب في غضون 5 دقائق على الأقل',
  TIPS3: '3:وقت خدمة العملاء عبر الإنترنت: من الاثنين إلى الجمعة AM8 إلى PM6',
  TIPS4: '4:نظرًا لتكلفة تحويل USD ، فإن النظام الأساسي يفرض رسومًا على السحب عندما يقوم المستخدم بسحب الأموال',
  TIPS5: '5:الحد الأدنى للسحب هو 10 دولارات أمريكية',
  钱包余额: 'رصيد المحفظة',
  输入数量:"أدخل الكمية",
  数量:"كمية",
  手机号码:"رقم التليفون",
  手机号码已存在:"تم تسجيل رقم الهاتف المحمول",
  邮箱:"علبة بريد EMAL",
  输入手机号码: 'أدخل رقم الهاتف المحمول',
  请输入登录密码: 'كلمة سر الدخول',
  登录: 'تسجيل الدخول',
  输入账号: 'أدخل رقم الحساب',
  密码: 'كلمة المرور',
  点击注册: "ليس لديك حساب？ أنشئ حساباً الآن!",
  忘记密码: 'ننسى كلمة السر',
  重新登录: 'تسجيل الدخول مرة أخرى',
  密码找回成功: 'تم استرداد كلمة المرور بنجاح',
  找回成功请重新登录: 'استرداد بنجاح ، يرجى تسجيل الدخول مرة أخرى',
  确认: 'يتأكد',
  发送验证码: 'أرسل رمز التحقق',
  再次输入密码: 'تأكيد كلمة المرور',
  输入验证码: 'أدخل البريد الإلكتروني CAPTCHA',
  输入手机验证码:"أدخل كلمة المرور لمرة واحدة للجوال",
  输入手机号: 'إدخال رقم الهاتف المحمول',
  输入密码: 'كلمة سر الدخول',
  快速注册: 'تسجيل سريع',
  注册: 'يسجل',
  立即登录: 'عودة تسجيل الدخول',
  请输入邮箱: 'Gmailعنوان البريد الإلكتروني',
  输入用户名: 'أدخل اسم المستخدم الخاص بك',
  请输入邀请码: 'الرجاء إدخال رمز الدعوة',
  返回: 'يعود',
  密码找回失败: 'فشل استعادة كلمة المرور',
  输入的信息有误: 'المعلومات التي تم إدخالها غير صحيحة',
	获取验证码:"CAPTCHA",
	手机号码不能为空:"لا يمكن أن يكون رقم الهاتف فارغًا",
	账号不存在:"الحساب غير موجود",
	发送成功:"أرسل بنجاح",
	今日收益:"（الدخل الحالي）",
	"您好如果您中途终止POS-STAKING系统将收取5%的STAKING费用":"مرحبًا ، إذا قمت بإنهاء نظام نقاط البيع في منتصف الطريق ، فسيتم تحصيل رسوم إدارة نقاط البيع بنسبة 5٪. تذكير خاص: بعد تعليق نقطة البيع لتجربة العقدة المجانية ، لن تتمكن من الحصول عليها مرة أخرى أو الحصول على أي رسوم!",
	我同意:"أنا موافق",
	用户账号ID:"حسابID",
	取消:"ألغ",
	日收益:"الدخل اليومي",
	请输入购买数量:"الرجاء إدخال كمية الشراء",
	数量不能为空:"لا يمكن أن تكون الكمية فارغة",
	加载中:"جار التحميل...",
	唯一码:"ترميز الروبوتات",
	未激活:"غير نشط",
	激活:"ابدأ الإستراتيجية",
	购买时间:"وقت الشراء",
	"钱包余额不足，激活该机器人失败":"رصيد حساب غير كافٍ ، لا يمكن إجراء تعدين نقاط البيع في العقدة",
	
	运行时长:"تشغيل الوقت",
	签约购买:"شراء الرصيد",
	系统赠送:"هبة النظام",
	状态:"ولاية",
	正常:"طبيعي",
	我的机器人:"بلدي روبوت التداول الكمي",
	一级会员:"المستوى 1",
	二级会员:"المستوي 2",
	三级会员:"مستوى 3",
	人:"",
	充值客服:"إعادة شحن خدمة العملاء",
	充值:"تعبئة رصيد",
	提现:"انسحب",
	提款密码:"سحب كلمة المرور",
	设置交易密码:"قم بتعيين كلمة مرور المعاملة",
	登录密码:"كلمة سر الدخول",
	请输入交易密码:"أدخل كلمة مرور المعاملة",
	再次请输入交易密码:"قم بتأكيد كلمة المرور الخاصة بالمعاملة",
	确认: 'أؤكد',
	手机号码不能为空:"لا يمكن أن يكون رقم الهاتف فارغًا",
	两次密码不一致:"كلمتا المرور غير متطابقتان",
	请输入验证码:"أدخل البريد الإلكتروني CAPTCHA",
	操作成功:"عملية ناجحة",
	"用户名或密码不正确,登录失败":"اسم المستخدم أو كلمة المرور غير صحيحة ، فشل تسجيل الدخول",
	登录成功:"تم تسجيل الدخول بنجاح",
	充值说明:"تعليمات إعادة الشحن",
	请先设置支付密码:"يرجى تعيين كلمة مرور المعاملة أولاً",
	复制成功:"نسخ بنجاح",
	冻结机器人做单本金:"تجميد الأموال الكمية",
	待审核:"لتتم مراجعتها...",
	成功:"نجاح",
	失败:"يفشل",
	审核中:"قيد المراجعة",
	在线充值:"إعادة الشحن عبر الإنترنت",
	描叙:"يصف",
	POS节点挖矿LV0:"تعدين العقدة POS-LV0",
	POS节点挖矿LV1:"تعدين العقدة POS-LV1",
	POS节点挖矿LV2:"تعدين العقدة POS-LV2",
	POS节点挖矿LV3:"تعدين العقدة POS-LV3",
	POS节点挖矿LV4:"تعدين العقدة POS-LV4",
	POS节点挖矿LV5:"تعدين العقدة POS-LV5",
	银行卡提现:"السحب النقدي عن طريق البطاقة المصرفية",
	USD提现:"سحب USD",
	三级代理:"وكيل المستوى 3",
	一级代理:"وكيل المستوى 1",
	二级代理:"وكيل المستوى 2",
	一级:"المستوى 1",
	二级:"المستوي 2",
	三级:"المستوى 3",
	做单:"تعدين العقدة",
	登录失败:"فشل تسجيل الدخول",
	请勿重复操作:"لا تكرر العمليات المتكررة",
	邀请码不正确:"رمز الدعوة غير صحيح",
	团队返佣:"عمولة الفريق",
	购买机器人返佣:"لجنة الروبوت",
	本金返还:"POSلكي ترجع",
	佣金收入:"دخل العمولة",
	时间:"وقت",
	机器人做单返还:"POSإرجاع النقود",
	涨幅:"تزيد",
	市场:"سوق",
	客服:"تخدم",
	验证码错误:"خطأ في رمز التحقق",
  付款方式:"USDعنوان الانسحاب",
	我的留言:"رسالتي",
  暂无数据: 'لايوجد بيانات',
  银行卡:'بطاقة مصرفية',
  修改: 'يعدل او يراحع',
 '请确保您的钱包地址正确，如果您的地址输入错误而导致收不到钱，请及时联系在线客服协助':'يرجى التأكد من صحة عنوان محفظتك. إذا تم إدخال عنوانك بشكل غير صحيح وتعذر استلام الأموال ، فيرجى الاتصال بخدمة العملاء عبر الإنترنت في الوقت المناسب للحصول على المساعدة',
 连接借记卡:"连接借记卡",
 银行名称:'اسم المصرف',
 姓名姓氏: 'اسم',
 IBAN号码:'IBAN 号码',
 '* 重要提示:借记卡信息必须真实有效才能取款。':'* ملاحظة مهمة: يجب أن تكون معلومات الحساب المصرفي صحيحة وصالحة لسحب الأموال.',
 帮助中心:'مركز المساعدة',
 会员须知:'إشعار العضو',
 '24小时内使用USD实时取款（推荐）':'1. 24小时内使用USD实时取款（推荐）',
 '银行卡 取款 *取款时间为10:00-18:00 *发起取款后2小时内入账*申请非取款时间或跨行取款将于次日10:00-18:00到账':' 2.银行卡 取款 *取款时间为10:00-18:00 *发起取款后2小时内入账*申请非取款时间或跨行取款将于次日10:00-18:00到账',
 我知道了:'أنا أرى',
 链接点击:'رابط تسجيل الإحالة',
 确定:'بالتأكيد',
 建立自己的团队邀请:'بناء فريقك الخاص',
 此账号已冻结:"تم تجميد هذا الحساب",
 手机号存已存在:"رقم الهاتف المحمول موجود بالفعل",
 注册账号已存在:"الحساب المسجل موجود بالفعل",
 请确定新密码:"الرجاء تأكيد كلمة المرور الجديدة",
 请再次输入登录密码:"تأكيد كلمة مرور تسجيل الدخول",
 密码长度不能少于6位:"لا يمكن أن يكون طول كلمة المرور أقل من 6 أحرف",
 加入:'إنضم إلى',
 "你出售的机器人对方已接受,出售所得已打款到您账户,请查收。":
 	    "你出售的节点对方已接受,出售所得已打款到您账户,请查收。",
 	"你出售的机器人对方已拒绝接受":"你出售的节点对方已拒绝接受",
 	向你赠送一台机器人:"向你赠送一个节点，待确认",
 	向你出售一台机器人:"向你出售一个节点，待确认",
 	出售价格不能超过机器人价格:"出售价格不能超过节点价格",
 	银行卡已被绑定:"تم تقييد البطاقة المصرفية",
 	USD_TRC20提现:'سحب USD_TRC20',
 	USD_ERC20提现:'سحب USD_ERC20',
 	银行卡提现:'السحب النقدي من البطاقة المصرفية',
 	邀请赠送:'مكافأة الدعوة',
 	机器人返佣:'لجنة الروبوت',
 	升级:'شراء ناجح',
 	充值主网:'充值主网',
   "您被限制出售机器人,请联系客服了解详情":
     "您被限制出售节点,请联系客服了解详情",
   交易编号: "رقم المعاملة",
   消息详情:"تفاصيل الرسائل",
   个人收益累计:"دخل شخصي متراكم",
   今日代理收益:"أرباح الوكيل اليوم",
   代理累计收益:"دخل الوكيل التراكمي",
   截图保存推荐给朋友:"شارك رابط تسجيل الإحالة الخاص بك وقم بدعوة الأصدقاء للانضمام إلى منصة Plus500Ai لكسب مكافآت عمولة دخل الفريق. عمولة الدخل لأعضاء فريق المستوى الثالث هي 8%-3%-2%، وتعتمد عمولة الفريق على بيانات الدخل اليومية لأعضاء المستوى الأدنى وسيتم تسويتها وإدخالها تلقائيًا في رصيد حسابك بعد الساعة 00:00 كل يوم. يوم.",
   复制:"ينسخ",
   充值提示1:"نصيحة 1: الحد الأدنى لإعادة الشحن هو 10 دولارات أمريكية ، يمكنك إعادة الشحن ، وإلا فلن يتم إيداعك",
   充值提示2:"نصيحة 2: يجب تأكيد إعادة الشحن على السلسلة. بعد إعادة الشحن بنجاح ، من المتوقع أن تصل إلى رصيد المحفظة في غضون دقيقة واحدة تقريبًا。",
   请输入正确的充值金额:"مبلغ الإيداع ≥8 دولارًا أمريكيًا",
   推荐:"يوصي",
   充值金额:"مبلغ إعادة الشحن",
   请上传付款截图:"يرجى تحميل لقطة شاشة لعملية الدفع الناجحة",
   交易号: 'رقم تجزئة المعاملة',
   充值ID提示:"يرجى لصق الرقم التسلسلي لمعاملة Txid",
   "请确保钱包地址无误，如因钱包地址填写错误导致无法收款而造成的损失，将有用户自己承担。":"يرجى التأكد من صحة عنوان المحفظة ، وسيتحمل المستخدم أي خسارة ناتجة عن عدم استلام الأموال بسبب ملء عنوان المحفظة بشكل غير صحيح.",
	上传凭证:"تحميل مستند إثبات الدفع",
	充值确认: "إرسال",
	不能为空:"لايمكن ان يكون فارغا",
  绑定钱包地址:'عنوان المحفظة ملزم',
	备注:"备注",
	请输入银行卡号:"الرجاء إدخال رقم البطاقة المصرفية",
	请输入你的名字:"من فضلك أدخل إسمك",
	银行卡号:"رقم البطاقة المصرفية",
	添加银行卡:"إضافة بطاقة مصرفية",
	请选择银行:"الرجاء تحديد بنك",
	请输入钱包地址:"الرجاء إدخال عنوان المحفظة",
	钱包地址:"عنوان المحفظة",
	"密码错误次数过多,请等待x秒后再试":"هناك عدد كبير جدًا من كلمات المرور الخاطئة ، يرجى الانتظار لمدة 600 ثانية والمحاولة مرة أخرى",
	"此账号已冻结,请联系客服":"تم تجميد هذا الحساب ، يرجى الاتصال بخدمة العملاء",
	手机号格式错误:"رقم الهاتف غير صحيح",
	"取款须知":"إشعار الانسحاب",
	"须知1":"1. استخدم USD-TRC20 للسحب في الوقت الفعلي في غضون 24 ساعة (موصى به)",
	"须知2":"2. الانسحاب يومي السبت والأحد",
	"须知3":"*يمكن للمستخدمين سحب الأموال يومي السبت والأحد",
	"须知4":"*ستصل عمليات السحب في عطلة نهاية الأسبوع يوم الاثنين من 10 صباحًا حتى 8 مساءً",
	我知道了:"أنا أرى",
	"提现0":"1USD=1USD ERC20",
	"提现1":"提示1：最低单笔提现100USD/天，单笔交易手续费5USD/次",
	"提现2":"TRC20提示2：每次提款最低10USD，每次交易手续费1USD。",
	"提现3":"银行账户提示3：最低单次取款为$10，交易手续费为交易金额的$6%",
	"提现4":"提现时间：提现申请提交后24小时内到账",
	
	已绑定:"ملزمة",
	去设置:"اذهب للاعدادات",
	注册成功:"نجاح التسجيل",
	汇率:"سعر الصرف",
	请输入正确的提现金额:"الرجاء إدخال مبلغ السحب الصحيح",
	提款记录:"سجل الانسحاب",
	"绑定的钱包地址必须跟付款钱包一致，否则不会到账。":"tip1:绑定的钱包地址必须跟付款钱包一致，否则不会到账。",
	"充值成功后预计在一分钟左右到账，请检查钱包约。":"tip2:充值成功后预计在一分钟左右到账，请检查钱包余额。",
	请先绑定钱包地址:"يرجى ربط عنوان المحفظة أولاً",
	输入邮箱:"أدخل البريد الإلكتروني",
	邮箱已存在:"صندوق البريد موجود بالفعل",
	注册邮箱已存在:"البريد الإلكتروني المسجل موجود بالفعل",
	指定用户不存在:"عدم وجود المستخدم المحدد",
	今日涨幅:"تغيير الأسعار",
	恭喜新用户:"تهانينا للمستخدم الجديد ",
	快捷充币:"إيداع",
	快速买USD:"شحن USD",
	在线支付:"الدفع الالكتروني",
	自动到账:"حساب آلي",
	线下支付:"المدفوعات دون اتصال",
	联系客服获取验证码:"اتصل بخدمة العملاء للحصول على رمز التحقق",
	获取:"يحصل على",
	退出成功:"الخروج بنجاح",
	不能低于最小充值:"لا يمكن أن يكون أقل من الحد الأدنى لإعادة الشحن",
	不能低于最小提现金额:"لا يمكن أن يكون أقل من الحد الأدنى لمبلغ السحب",
	最小提现:"الحد الأدنى للسحب",
	设置成功:"مجموعة بنجاح",
	官方充币:"نظام الإيداع",
	银行卡充值:"إعادة شحن البنك",
	等待审核:"قيد الانتظار",
	可提现金额不足:"المبلغ المتاح للسحب غير كاف",
	未经授权:"غير مصرح",
	交易密码不正确:"كلمة مرور المعاملة غير صحيحة",
	提现次数不足:"أوقات السحب غير كافية",
	官网:"官网",
	账户USD不足:"رصيد حساب USD غير كافٍ",
	激活成功: "POSنجاح",
	操作成功: "عملية ناجحة",
	修改成功:"تم التعديل بنجاح",
	注册说明:"يحتاج OTP للجوال وEmail CAPTCHA فقط إلى اختيار طريقة واحدة للتحقق ويمكنك التسجيل بنجاح.",
	弹窗内容:
	"مرحبًا بك في منصة التداول الكمي Plus500Ai، حيث يمكن لكل مستخدم استخدام مكافأة التسجيل البالغة 1 USD لشراء حزمة استراتيجية التداول الآلي الكمي NO.0، والتي تكون صالحة لمدة يوم واحد وتكسب 0.5 USD مجانًا. تتطلب حزم استراتيجيات التداول الكمية الأخرى استخدام الرسوم المقابلة. بعد قيام المستخدمين بشراء الروبوت، يحتاجون إلى تفعيل إستراتيجية التداول الكمي قبل أن يبدأ تشغيلها تلقائيًا، حيث تتوافق كل إستراتيجية تداول مع فترة صلاحية مختلفة. إذا لم ينقر المستخدم لتفعيل الإستراتيجية بعد شراء نقاط الروبوت المقابلة، فلن تتلقى أي دخل من التداول.",
	标题1:'1. مقدمة عن منصة Plus500',
	标题2:'2. مزايا التداول الكمي بالذكاء الاصطناعي',
	标题3: "3. لماذا تختار Plus500Ai؟",
	标题4:'4. كيفية الانضمام إلى Plus500Ai',
	标题5:'5.Plus500Ai آفاق السوق',
	标题6:'6.Plus500Ai نموذج الإيرادات',
	标题7:'7.لائحة الإيداع والسحب',
	标题8:"طريقة لعب لعبة باتل رويال",
	内容8:"هناك 8 غرف في لعبة باتل رويال على منصة Plu500Ai، ويمكن للاعبين اختيار أي غرفة من بين الـ 8 غرف لوضع العملات الذهبية فيها، وبعد انتهاء العد التنازلي للنظام، سيظهر قاتل في نظام اللعبة، القاتل يدخل غرفة بشكل عشوائي ويقضي على الأشخاص الموجودين في الغرفة، ويمكن للاعبين الآخرين مشاركة العملات الذهبية في هذه الغرفة وجني الأرباح. طريقة اللعب في لعبة Battle Royale قصيرة نسبيًا، حيث تستمر اللعبة لبضع دقائق فقط، لذلك يمكن للاعبين لعبها في أوقات فراغهم. مقدار القسمة = إجمالي عدد الغرف التي قتلها القاتل في هذه الفترة. مبلغ السهم الفعلي = مبلغ السهم * 0.9 (باستثناء نسبة التدمير 0.1). نسبة المشاركة = مبلغ المشاركة الفعلي / إجمالي مبلغ الرهان في الغرف الأخرى. مبلغ الفوز = نسبة المشاركة * مبلغ الرهان الفعلي. 1 عملة ذهبية = 1 USD، ويتطلب الأمر ما لا يقل عن 10 USD للمشاركة في لعبة Battle Royale.",
	内容1:'يتم توفير منصة التداول Plus500 من قبل Plus500SEY Ltd. وبناء على ذلك، فإن Plus500SEY Ltd هي الجهة المصدرة والبائعة للمنتجات المالية الموضحة أو المعروضة على هذا الموقع. Plus500SEY Ltd هي شركة مقرها في سيشيل (رقم الشركة 8426415-1) ولها مكاتب في فيكتوريا. الشركة مرخصة وتشرف عليها هيئة الخدمات المالية في سيشيل (FSA) برقم الترخيص SD039 وتوفر عقود الفروقات (CFD) على العديد من المنتجات الأساسية. تعد الشركة مزودًا سريع النمو لعقود الفروقات، حيث تقدم حاليًا محفظة تضم أكثر من 2800 أداة مالية. Plus500SEY Ltd هي شركة تابعة لشركة Plus500 Ltd، المدرجة في السوق الرئيسي لبورصة لندن ومقرها الرئيسي في حيفا.',
	内容2:'تتمثل ميزة التداول الكمي الآلي بالكامل بالذكاء الاصطناعي في استخدام أدوات عقلانية لتشغيل المعاملات وفقًا لإعدادات النموذج، حيث لا يوجد لدى الروبوت أي تغييرات عاطفية ويمكنه التغلب على الطبيعة البشرية والجشع والخوف البشري والعديد من أوجه القصور ونقاط الضعف الأخرى في عملية التشغيل. غالباً ما تتأثر العمليات اليدوية بصعود وهبوط السوق وعوامل أخرى مختلفة، ولا يمكن أن تعمل وفق أفضل نموذج ربح لفترة طويلة.',
	内容3:"قامت Plus500Ai تدريجياً بتعزيز مكانتها كمجموعة متعددة الأصول في مجال التكنولوجيا المالية من خلال توسيع منتجاتها الأساسية إلى أسواق جديدة وحالية، وإطلاق منتجات تجارية ومالية جديدة، وتعميق مشاركتها مع العملاء. قم بحماية أصولك وحماية مفاتيح التشفير الخاصة بك باستخدام تقنية الأمان الرائدة في الصناعة. تقدم Plus500Ai أيضًا خدمة الوصاية العالمية، مما يعني أننا خاضعون للتنظيم والتدقيق والتأمين بما يصل إلى 250 مليون دولار. استثمر رأس مالك ليس بهدف الاحتفاظ بالأصول فحسب، بل بهدف تفعيلها أيضًا. يتيح لك Plus500Ai نشر أموالك بعدة طرق، بدءًا من التداول الكمي بالذكاء الاصطناعي وحتى الخدمات الأولية الكلاسيكية، اعتمادًا على قدرتك على تحمل المخاطر. منصة عبر الإنترنت لتوفير المزيد من الخدمات لمستخدميك دون توظيف مهندسين إضافيين في مجال blockchain.",
	内容4:"منذ تأسيس Plus500Ai، لم يكن هناك سوى موقع رسمي واحد فقط، وهو WWW.PLUS500AI.COM. يحتاج جميع المستخدمين إلى تسجيل الدخول إلى الموقع الرسمي لتنزيل التطبيق، حيث أن المواقع الأخرى ليست تابعة لشركتنا. للتسجيل، تحتاج إلى إرسال رقم الهاتف المحمول للمستخدم وعنوان البريد الإلكتروني ورمز دعوة الموصي للتسجيل بسرعة. جميع الاتصالات مع العملاء تكون كتابية، إما عبر البريد الإلكتروني أو الدردشة عبر الإنترنت. للتواصل مع Plus500 عبر البريد الإلكتروني، يرجى ملء النموذج (نموذج الطلب) الموجود في صفحة اتصل بنا. بعد تقديم نموذج الطلب، ستقوم Plus500 بإرسال الرد عبر البريد الإلكتروني مباشرة إلى عنوان البريد الإلكتروني الذي حددته في نموذج الطلب. إذا كان لديك أي أسئلة، يرجى الاتصال بخدمة العملاء.",
	内容5:'تعمل Plus500Ai عبر المنتجات والأجهزة والبلدان لتزويد العملاء بفرص تداول مالي موثوقة وبديهية، لقد سعينا دائمًا إلى التميز في منتجاتنا وفرقنا. بفضل التكنولوجيا المتقدمة الخاصة بنا والنهج الذي يركز على العملاء، يحصل مستخدمونا على تجربة خدمة تداول من الدرجة الأولى.',
	内容6:'توفر Plus500Ai استراتيجيات تداول كمية احترافية لسوق العملات المشفرة من قبل فريق من كبار محللي البيانات، ويمكن للمستخدمين الذين يستثمرون في روبوت التداول الكمي Ai الحصول على أرباح تداول كمية من خلال الاعتماد على استراتيجيات التداول المتطابقة مع الروبوت. يقوم روبوت التداول الكمي Ai بتحليل وقت التداول الأمثل بشكل دوري بناءً على استراتيجية التداول في السوق الخاصة بـ Plus500Ai، ويحصل على أرباح تداول كمية من خلال نموذج البيع والشراء منخفض المستوى بأسعار مرتفعة. توفر Plus500Ai للمستخدمين المستثمرين استراتيجيات تداول كمية مثالية كل يوم، وتستخرج المنصة 5% من أرباح الدخل الكمي للمستخدمين كأموال لتشغيل المنصة.',
	内容7:'يدعم Plus500Ai حاليًا إيداعات وسحوبات USD، وسيكون هناك إعلان على الموقع الرسمي إذا تم فتح قنوات أخرى في المستقبل. الحد الأدنى للإيداع والسحب هو 10 دولار أمريكي، أسرع إيداع وسحب يتم خلال 5 دقائق والأبطأ خلال 24 ساعة. لا يوجد حد أقصى لوقت سحب المستخدم، يمكنك سحب الأموال في أي وقت. تصل عمليات السحب خلال 5 دقائق - 24 ساعة في أيام العمل العادية. يتم ترتيب عمليات السحب في عطلة نهاية الأسبوع لتتم معالجتها يوم الاثنين.',
	BSC提现:"USD_BSC سحب",
	社区Ads收益:"دخل إعلانات المجتمع",
	Ads收益区间:"دخل الإعلانات 1-1000 دولار",
	申请Ads费用:"رسوم إعلانات التطبيق",
	Ads促销地址:"عنوان URL لترويج الإعلانات",
	填写Ads促销地址:"املأ عنوان URL لمواد ترويج الإعلانات",
	申请Ads费用须知:"تعليمات التقديم على رسوم الإعلانات",
	须知A:"1. يقوم المستخدمون بتسجيل مقاطع الفيديو الترويجية وتحميلها إلى مواقعهم الخاصة على YouTube أو Facebook أو القناة أو المجتمعات الأخرى لترويج الإعلانات ، والتقدم للحصول على مكافأة من 1 إلى 1000 دولار ، وستقوم المنصة بمراجعة مواد الفيديو الخاصة بالإعلانات بعد 3 أيام من تقديم الطلب ، ومنحها مكافأة الإعلانات وفقًا لتأثير ترويج الفيديو في رصيد حسابك ، لا يمكن لمقاطع الفيديو التي ليس لها أي تأثيرات ترويجية اجتياز المراجعة",
	须知B:"2. لا يمكن تطبيق نفس الفيديو الترويجي للإعلانات على نفس القناة إلا للحصول على مكافأة مرة واحدة ، ويمكن إصدار مواد فيديو ترويجية جديدة مرة واحدة في الأسبوع. لا ترسل طلبات متكررة بشكل ضار. إذا قمت بإرسال طلبات إعلانات ضارة لم تقم بها بنفسك على النظام الأساسي عدة مرات أو فشل في مراجعة مواد الإعلانات ، سيتم حظر حسابك بعد اكتشاف النظام",
	须知C:"3. يجب أن تحتوي مادة فيديو الإعلانات التي سجلها المستخدم على مقدمة أساسية للنظام الأساسي وشرح لوظيفة النظام الأساسي. لا يقل وقت الفيديو عن 5 دقائق. وكلما زاد عدد المشتركين في قناتك ، كان التأثير الترويجي للفيديو أفضل ، و أعلى رسوم الإعلانات التي يمكنك التقدم لها ، يكون مرجع تكلفة الإعلانات المحدد كما يلي ، مع أخذ YOUTUBE كمثال:",
	须知D: "المشترك 0-2K: المبلغ الذي يمكن التقدم له هو 1-20 $",
	须知D1:"المشترك 3K-10K: المبلغ الذي يمكن التقديم له هو 21-40 $",
	须知D2:"المشترك 11K-30K: يمكنه التقديم بمبلغ 41-100 دولار",
	须知D3:"المشترك 31 ألف - 50 ألف: المبلغ الذي يمكن التقدم بطلبه هو 101-300 دولار",
	须知D4:"المشترك 51 ألف - 100 ألف: المبلغ الذي يمكن التقديم له هو 301-1000 دولار",
	须知D5:"4. سيقوم النظام بمراجعة ومعالجة طلبات مكافآت الإعلانات كل يوم أحد، وسيتلقى المستخدمون المؤهلون المكافآت التي يرسلها النظام.",
	请求审核:"طلب مراجعة",
	申请金额:"مبلغ التطبيق",
	URL促销地址:"عنوان URL الترويجي",
	提交时间:"وقت التقديم",
	需要更新的银行卡不存在:"البطاقة المصرفية المراد تحديثها غير موجودة",
	提款方式:"طريقة السحب",
	预计收入:"الدخل المقدر",
	总投资:"إجمالي الاستثمار",
	购买价:"ثمن الشراء",
	邀请:"ادعو",
	成员:"أعضاء",
	合格条件:"قم بإيداع الحد الأدنى 8 دولارًا للشخص الواحد",
	积分等级折扣规则介绍3:"مبلغ الخصم هذا صالح فقط حتى 31 ديسمبر 2023، وبعد 1 يناير 2024 سيكون هناك تعديل جديد.",
	LV1佣金:"لجنة المستوى 1",
	LV2佣金:"لجنة المستوى 2",
	LV3佣金:"لجنة المستوى 3",
	随时可退:"برد في أي وقت",
	登录密码错误:"كلمة مرور تسجيل الدخول خاطئة",
	充值奖金:"قم بإيداع USD≥50USD، مكافأة قدرها 3%",
	充值奖金1:"قم بإيداع IDR≥50USD، مكافأة 2%",
	充值奖金2:"قم بإيداع THB≥50USD، مكافأة 5%",
	不能重复发送短信:"لا يمكنك إرسال رسائل نصية متكررة خلال 10 دقائق، ومن المستحسن استخدام EMAIL CAPTCHA للتسجيل مباشرة.",
	限购说明1:"مرحبًا، يمكن لكل مستخدم لحزمة استراتيجية تداول الروبوت الكمي NO.0 شرائها مرة واحدة فقط.",
	活动已过期:"انتهت صلاحية الحدث، في انتظار الحدث التالي",
	修改钱包地址提示:"لا يمكن تعديل عنوان سحب USD بعد ربطه.إذا كنت بحاجة إلى المساعدة، يرجى الاتصال بخدمة العملاء.",
	修改银行提示:"لا يمكن تعديل الحساب البنكي المسحوب بعد ربطه، إذا كنت بحاجة إلى المساعدة، يرجى الاتصال بخدمة العملاء.",
	注册推送消息1:"مرحبًا بك في INGSAI، بعد أن يبدأ المستخدمون الجدد عقدة تعدين POS، سيحصلون على دخل تعدين POS كل 5 دقائق. سيتم إدخال دخل عمولة الفريق تلقائيًا في رصيد الحساب عند حوالي 00.00 يوميًا.",
	限购说明:"مرحبًا، الحد الأقصى لعدد عمليات الشراء لهذا النوع من باقات التداول الكمي هو 10 مرات، ويُنصح بشراء أنواع أخرى من باقات التداول الكمي.",
	注册推送消息:"مرحبًا بكم في نظام تعدين عقدة Ingsai، تطلق المنصة مكافآت الإيداع التالية: 5 دولارات لإعادة تعبئة واحدة بقيمة 100 دولار، و25 دولارًا لإعادة تعبئة واحدة بقيمة 500 دولار، و60 دولارًا لإعادة تعبئة واحدة بقيمة 1000 دولار، و2000 دولار لإعادة تعبئة واحدة بقيمة 1000 دولار إعادة شحن واحدة. احصل على مكافأة قدرها 150 دولارًا، مع إعادة شحن واحدة بقيمة 5000 دولار وإعادة شحن واحدة بقيمة 580 دولارًا. هذا الحدث صالح لفترة طويلة. يمكن للمستخدمين المؤهلين الاتصال بخدمة العملاء عبر الإنترنت للحصول على المكافأة. (إنغساي قسم الترويج الرسمي)",
	注册协议:"بالضغط على (إنشاء حساب)، فإنك تؤكد أن عمرك يزيد عن 18 عامًا. أنت تدرك أن Plus500AI قد تستخدم عنوان بريدك الإلكتروني أو بياناتك الشخصية الأخرى للاتصال بك إلكترونيًا لتقديم المعلومات ذات الصلة حول منتجاتها/خدماتها. قد نقوم أيضًا بإخطارك بأحداث السوق لمساعدتك في تحقيق أقصى استفادة من تجربة التداول الخاصة بك. يمكنك إدارة وتعديل تفضيلات الإشعارات في علامتي التبويب (إعدادات الإشعارات) و(إعدادات الخصوصية) على المنصة. يمكنك إلغاء الاشتراك في هذه الاتصالات في أي وقت. لمزيد من المعلومات، يرجى مراجعة (اتفاقية الخصوصية) الخاصة بنا",
	公司说明1:"Plus500AI هي علامة تجارية مسجلة لشركة Plus500 Ltd. تعمل شركة Plus500 Ltd من خلال الشركات التابعة التالية:",
	公司说明2:"Plus500SEY Ltd مرخصة ومنظمة من قبل هيئة الخدمات المالية في سيشيل (رقم الترخيص SD039).",
	公司说明3:"Plus500SEY Ltd هي الجهة المصدرة والبائعة للمنتجات المالية الموضحة أو المتوفرة على هذا الموقع.",
	公司说明4:"Plus500UK Ltd مرخصة ومنظمة من قبل هيئة السلوك المالي. رقم تسجيل هيئة السلوك المالي: 509909. عقود الفروقات الخاصة بالعملات المشفرة غير متاحة لمستخدمي التجزئة.",
	公司说明5:"Plus500CY Ltd مرخصة ومنظمة من قبل هيئة الأوراق المالية والبورصة القبرصية (رمز التفويض: 250/14). عقود الفروقات على العملات المشفرة غير متاحة لمستثمري التجزئة في المملكة المتحدة.",
	公司说明6:"تحمل شركة Plus500AU Pty Ltd التراخيص التالية: AFSL (رقم 417727) الصادر عن ASIC، وFSP (رقم 486026) الصادر عن FMA النيوزيلندية، ومقدم الخدمات المالية المعتمد (رقم 47546) الصادر عن FSCA بجنوب إفريقيا.",
	公司说明7:"Plus500EE AS ​​مرخصة ومنظمة من قبل هيئة الرقابة المالية الإستونية (الترخيص رقم 4.1-1/18).",
	公司说明8:"تحمل شركة Plus500SG Pte Ltd (UEN 201422211Z) ترخيصًا لخدمات أسواق رأس المال صادرًا عن سلطة النقد في سنغافورة، مما يسمح لها بتداول منتجات سوق رأس المال (رقم الترخيص CMS100648).",
	公司说明9:"Plus500AE Ltd مرخصة وخاضعة لرقابة هيئة دبي للخدمات المالية (F005651).",
	加入我们的社群:"انضم إلى مجتمعنا",
	PLUS500集团:"مجموعة PLUS500",
	受监管的操作:"العمليات المنظمة",
	健全的全球监管框架:"إطار تنظيمي عالمي قوي",
	治理和社会责任:"الحوكمة والمسؤولية الاجتماعية",
	底部说明:"تظل Plus500 ملتزمة بالعمل بشكل مسؤول ومستدام في جميع جوانب أعمالها وتعتقد أن هذا النهج هو واجبها وجزء أساسي من الإدارة الفعالة. تلتزم Plus500 بمجموعة من المبادرات البيئية والاجتماعية والحوكمة (ESG) لخلق قيمة ملموسة لموظفينا وعملائنا والمجتمعات المحلية والجمعيات الخيرية والمساهمين لدينا.",
	投资者联系方式:"اتصالات المستثمرين",
	投资者关系主管:"رئيس علاقات المستثمرين",
	AI智慧型量化交易:"الذكاء الاصطناعي التداول الكمي الذكي",
	订单执行快速可靠:"تنفيذ الطلب سريع وموثوق",
	全天候自动分析行情:"تحليل السوق التلقائي على مدار الساعة",
	交易受监管低风险:"التداول منظم ومنخفض المخاطر",
	稳定策略高效收益:"استراتيجية مستقرة وعوائد فعالة",
	取款快速且安全:"سحب الأموال بسرعة وأمان",
    游戏说明:"الحد الأدنى للرصيد المطلوب هو 10 USD للدخول إلى اللعبة وكسب المال",
}
