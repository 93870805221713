<template>
  <div class="page">
    <div class="header">
      <div @click="getBack">
        <img class="img" src="../../assets/img/arrow.png" alt="" style="height: 15px;width: 15px;"/>
      </div>
      <div class="logg" style="font-weight: bold; color:#fff;">{{ $t('个人收入记录') }}</div>
      <div></div>
    </div>
    <div class="choose">
      <van-tabs v-model="active" :ellipsis="false">
        <van-tab :title="$t('购买机器人返佣')" name="1">
          <van-list
            v-model="loading"
            :finished="finished"
            :finished-text="$t('暂无数据')"
            @load="onLoad"
          >
            <van-cell v-for="item in list" :key="item.id">
              <div class="learn">
                <div class="box">
                  <div>
                    <div class="natixis robotl">
                      <div>{{$t('时间')}} </div>
                      <span class="tags"></span>
                    </div>
                    <div>
                      <span>{{item.create_time}}</span>
                    </div>
                  </div>
                  <div>
                    <div class="price">{{item.amount}}<span style='font-size: 12px'> USD</span></div>
                    <div>{{ $t(item.memo) }}</div>
                  </div>
                </div>
              </div>
            </van-cell>
          </van-list>
        </van-tab>
        <van-tab :title="$t('团队返佣')" name="2">
          <van-list
            v-model="loading"
            :finished="finished"
            :finished-text="$t('暂无数据')"
            @load="onLoad"
          >
            <van-cell v-for="item in list" :key="item.id">
              <div class="learn">
                <div class="box">
                  <div>
                    <div class="natixis robotl">
                      <div>{{$t('时间')}} </div>
                      <span class="tags"></span>
                    </div>
                    <div>
                      <span>{{item.create_time}}</span>
                    </div>
                  </div>
                  <div>
                    <div class="price">{{item.amount}}<span style='font-size: 12px'> USD</span></div>
                    <div>{{ $t(item.memo) }}</div>
                  </div>
                </div>
              </div>
            </van-cell>
          </van-list>
        </van-tab>
        <van-tab :title="$t('工资')" name="3">
           
          <van-list
            v-model="loading"
            :finished="finished"
            :finished-text="$t('暂无数据')"
            @load="onLoad"
          >
            <van-cell v-for="item in list" :key="item.id">
              <div class="learn">
                <div class="box">
                  <div>
                    <div class="natixis robotl">
                      <div>{{$t('时间')}} </div>
                      <span class="tags"></span>
                    </div>
                    <div>
                      <span>{{item.create_time}}</span>
                    </div>
                  </div>
                  <div>
                    <div class="price">{{item.amount}}<span style='font-size: 12px'> USD</span></div>
                    <div>{{ $t(item.memo) }}</div>
                  </div>
                </div>
              </div>
            </van-cell>
          </van-list>
         <!-- <div class="pitera">
            <img src="../../assets/img/机器人.png" alt="" />
            <div>暂无数据</div>
          </div> -->
       <!-- </van-tab> 
        <van-tab :title="$t('本金返还')" name="4">
          <van-list
            v-model="loading"
            :finished="finished"
            :finished-text="$t('暂无数据')"
            @load="onLoad"
          >
            <van-cell v-for="item in list" :key="item.id">
              <div class="learn">
                <div class="box">
                  <div>
                    <div class="natixis robotl">
                      <div>{{$t('时间')}} </div>
                      <span class="tags"></span>
                    </div>
                    <div>
                      <span>{{item.create_time}}</span>
                    </div>
                  </div>
                  <div>
                    <div class="price">{{item.amount}} <span style='font-size: 12px'> USD</span></div>
                    <div>{{ $t(item.memo) }}</div>
                  </div>
                </div>
              </div>
            </van-cell>-->
          </van-list>

          <!-- <div class="pitera">
            <img src="../../assets/img/机器人.png" alt="" />
            <div>暂无数据</div>
          </div> -->
        </van-tab>
      </van-tabs>
    </div>
    <!-- <div class="footer">
      <span class="comeTo">{{ $t('总计') }} :</span>
      <span class="num"> 30</span>
      <span class="comeTo"> {{ $t('一次性总和') }} :</span>
      <span class="addUpTo">30</span>
    </div> -->
    <ROBOT></ROBOT>
  </div>
</template>

<script>
import ROBOT from '@/components/robot'
import { apishourujilu ,getUrlKey} from "@/http/api/";
import { NavBar, Tab, Tabs, Empty, PullRefresh, Toast, List } from "vant";
export default {
  name: 'welcome',
  components: {
    ROBOT
  },
  data() {
    return {
      active: '2',
      list: [],
      loading: false,
      finished: false,
	  token:"",
	  type:1,
	  page:1,
    }
  },
  watch: {
    active(val) {
      this.list = []
      this.page = 1;
	  
	  this.loading = false
      this.finished = false
      this.onLoad()
    }
  },
    created() {
    	var token = localStorage.getItem("key");
    	console.log("token", token);
    	if (!token) {
    	  this.$router.replace("/login");
    	}
    	this.token = token;
		var url = window.location.href ;
		var index =  	getUrlKey('active', url);   
		if(index){
			this.active = 	getUrlKey('active', url);
		}
    },
  methods: {
    copyEvent() {
      console.log('fuzhi ')
    },
    getBack() {
      this.$router.back()
    },
    onLoad() {
     Toast.loading({
       duration: 0,
       message: this.$t('加载中'),
       forbidClick: true,
     });
     var that = this;
       apishourujilu({
       userid: this.token,
       page:this.page,
       type:this.active
       }).then((res) => {
       Toast.clear();
       for(let i = 0; i < res.info.length; i++){
     	  that.list.push(res.info[i]);
       }
       // 数据全部加载完成
       if(res.info.length ==0){
       		that.finished = true
       }
     });
     this.page++
     // 加载状态结束
     this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  //   padding: 0 30px;
  background:#07183d;
  .header {
    padding: 0 40px;
    height: 56px;
    line-height: 56px;
    display: flex;
    justify-content: space-between;
    .close {
      width: 28px;
      height: 28px;
    }
    .img {
      width: 40px;
	  margin-top:15px;
    }
    .logg {
      font-size: 40px;
      font-family: PingFang SC-中粗体, PingFang SC;
      font-weight: normal;
      color: #ffffff;
    }
  }
  .choose {
    margin-top: 60px;
    padding: 0 24px;
    .learn {
      margin-top: 30px;
      color: #fff;
      padding: 20px 25px;
      border-bottom: 1px solid #2a2c2e;
      .box {
        color: #888;
        // margin-bottom: 10px;
        align-items: center;
        display: flex;
        justify-content: space-between;
        .natixis {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          .img {
            margin-right: 10px;
            width: 60px;
            height: 60px;
          }
        }
        .price {
          font-size: 36px;
		  font-weight:bold;
          color: #888;
        }
        .robotl {
          font-size: 24px;
		  font-weight:bold;
          color: #888;
          .tags {
            margin-left: 10px;
          }
        }
      }
    }
  }
  .pitera {
    color: #fff;
    line-height: 60px;
    font-size: 24px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .footer {
    position: fixed;
    font-size: 24px;
    color: #888;
    bottom: 0;
    height: 90px;
    width: 100%;
    text-align: center;
    line-height: 90px;
    background-color: #2a2c2e;
    border-radius: 30px 30px 0 0;
    .num {
      color: #0d9135;
      font-size: 30px;
      margin-right: 30px;
    }
    .comeTo {
      margin-right: 20px;
    }
    .addUpTo {
      font-size: 30px;
      color: #fff;
    }
  }
}
</style>
