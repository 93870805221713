export default {
  tabbar: {
    首页: 'Beranda',
    市场: 'Pasar',
    任务: '任务',
    团队: 'Tim',
    我的: 'Saya'
  },
  navbar: {
    充值: 'Isi ulang',
    提现: 'Penarikan',
    帮助: 'FAQ',
    分享: 'Undang',
	推广: 'Bonus Ads',
    活动: 'Bonus',
    游戏: 'Permainan',
  },	 
  payment: {
    付款方式详情: 'Detail metode pembayaran',
    银行卡: '银行卡',
    钱包地址: 'Alamat dompet',
    交易密码: 'Sandi transaksi',
    保存: 'Kirim'
  },
  market: {
    市场: 'Pasar',
    名称: 'Nama',
    价格: 'Harga',
  },
 
  task: {
    任务: '任务',
	我的余额: '我的余额',
    增加订单数量: '增加订单数量',
    今日剩余订单: '今日剩余订单',
    频率: '频率',
    手动交易: '手动交易',
    预期收益: '预期收益',
    需要启动存款: '需要启动存款',
    任务说明: '任务说明',
    每天可以完成10个任务: '每天可以完成10个任务',
    联系VIP助手增加任务数量: '联系VIP助手增加任务数量',
    每个用户的任务总数为300次: '每个用户的任务总数为300次',
    购买机器人无需人工操作即可自动交易: '购买机器人无需人工操作即可自动交易',
    我知道了: '我知道了'
  },
  邀请好友注册领取佣金:"Undang teman untuk mendaftar dan menerima komisi",
  领取:"Menerima",
  未满足条件:"Kondisi tidak terpenuhi",
  总收入:"Jumlah pemasukan",
  投资建议:"※Lakukan rencana investasi bunga majemuk untuk mendapatkan keuntungan yang lebih besar",
  语言切换: 'Beralih bahasa',
  POS区块节点挖矿: 'Detail Trading Kuantitatif Ai',
  节点类型: 'Robot\nJenis',
  加入价格: 'Beli\nHarga',
  质押资金: 'POS\nDana',
  质押周期: 'Periode\n(hari)',
  每日收入: 'Pendapatan\nHarian',
  累计积分: 'Akumulasi\nPoin',
  代理等级: 'Level\nAgen',
  下级折扣比: 'Bawahan\nDiskon',
  代理折扣比: 'Agen\nDiskon',
  下属等级: 'Bawahan\nTingkat',
  代理佣金比率: 'Agen\nKomisi\nTarif',
  下属每日收入: 'Bawahan\nLaba\nHarian',
  代理佣金: 'Agen\nKomisi',
  引用对象: 'Referensi\nobjek',
  积分等级折扣规则: 'Aturan Diskon Tingkat Poin',
  积分等级折扣规则介绍: 'Pengguna yang berhasil berpartisipasi dalam trading kuantitatif Ai akan menerima poin akun yang sesuai, dan pengguna atasan agen mereka juga dapat meningkatkan poin yang sesuai (berlaku untuk 15% bawahan langsung). Ketika total poin akun memenuhi kondisi yang sesuai, level agen akun akan ditingkatkan. , Akun agen di level yang berbeda bisa mendapatkan proporsi diskon harga yang berbeda. Rincian diskon poin terkait adalah sebagai berikut:',
  积分等级折扣规则介绍2: 'Pengguna dengan hubungan berbeda (langsung) akan menerima diskon dan diskon tingkat agen berdasarkan proporsi partisipasi bawahan dalam transaksi kuantitatif Ai. Eksekutif senior lainnya juga akan menerima tingkat diskon sebanding dengan agen mereka sendiri. Diskon harga untuk berpartisipasi dalam transaksi kuantitatif Ai: Pengguna dengan tingkat agen yang sesuai akan menerima hak preferensi tingkat agen yang sesuai ketika berpartisipasi dalam transaksi kuantitatif Ai.',
  团队: 'Tim',
  代理佣金制度: 'Sistem komisi agen',
  代理佣金说明详情: 'Proporsi agen LV1 diperoleh A: 8%, rasio agen LV2 diperoleh A: 3%, rasio agen LV3 diperoleh A: 2%. Contoh: Keuntungan harian B, C, dan D adalah: 100 USD, dan hadiah agen LV1 yang diperoleh A: 8%*100=8 USD. Hadiah agen LV2 diperoleh A: 3%*100=3 USD. Hadiah agen LV3 diperoleh A: 2%*100=2 USD. Total pendapatan komisi adalah: 8+3+2=13 USD.',
  代理结构图示: 'Diagram Struktur Proksi',
  代理结构图示说明: 'A mewakili Anda, A mengundang B (bawahan A adalah B), B mengundang C (bawahan B adalah C), dan C mengundang D (bawahan C adalah D). A dapat memiliki beberapa bawahan (B1, B2, B3, B4, B5, B6, dst.), B (B1, B2, B3) juga dapat memiliki beberapa bawahan (C1, c2, c3, c4, c5, c6, dst. ) C...D...',
  高级代理佣金说明: 'Instruksi Komisi Agen Senior',
  高级代理佣金说明详情: 'A mewakili Anda, A mengundang B (bawahan A adalah B), B mengundang C (bawahan B adalah C), dan C mengundang D (bawahan C adalah D). Agen superior akan memperoleh pendapatan komisi agen yang berbeda (%) sesuai dengan rasio jumlah total penambangan node yang terlibat dalam akunnya sendiri dan akun bawahannya. A membandingkan pendapatan harian anggota bawahan untuk mendapatkan rasio imbalan agensi yang sesuai. Node yang berpartisipasi harus aktif dan berjalan. Pendapatan agensi disediakan oleh INGSAI MINING untuk semua biaya keuangan dan tidak akan mempengaruhi pendapatan harian anggota bawahan biasa.',
  高级代理佣金说明详情2: 'Contoh: Total jumlah penambangan agen senior (A)>30% dari total jumlah penambangan anggota bawah (B/C/D) (Contoh: A>30% B/C/D)',
  积分: 'Poin',
  规则: 'Aturan',
  会员级别: 'Tingkat keanggotaan',
  经验: 'Poin total',
  代理信息: 'Informasi anggota',
  更多: 'Lebih',
  团队奖励: 'Komisi tim',
  昨天: 'Kemarin',
  本星期: 'Minggu ini',
  全部: 'Semua',
  机器人说明: 'Pengguna Plus500Ai dapat membeli beberapa paket perdagangan kuantitatif secara bersamaan, dan robot NO.1 hanya dapat membeli 10 kali per akun. Ingat: Jangan lupa klik untuk mengaktifkan strategi setelah membeli robot kuantitatif, jika tidak, tidak akan ada pemasukan. Masa berlaku robot kuantitatif dihitung berdasarkan waktu pembelian. Harap aktifkan strategi perdagangan kuantitatif tepat waktu. Jika robot kuantitatif belum diaktifkan setelah habis masa berlakunya, Anda akan kehilangan biaya pembelian paket strategi perdagangan kuantitatif! !!Semua pengguna yang berpartisipasi dalam perdagangan kuantitatif Plus500Ai harap membaca dengan cermat Peraturan ini! !',
  会员体验: 'Pengalaman anggota',
  机器人采购: 'Pembelian Robot',
  机器人启动押金: 'Jumlah node POS',
  机器人礼物: 'Hadiah node',
  机器人订单: 'Urutan node',
  一次性总和: 'Menunjukkan',
  总计: 'Total',
 
  被激活: 'Sudah POS',
  工作中: 'DIAKTIFKAN',
  暂停: 'Berhenti POS',
  结束: 'KADALUARSA',
  刷新: 'Segarkan',
  来源: 'Sumber',
  价格: 'Harga Paket Robot',
  启动押金: 'Jumlah POS',
  剩余时间: 'Waktu tersisa',
  激活时间: 'Waktu aktivasi',
  号: 'No',
  我的资产: 'Aset saya',
  退出: 'Keluar',
  邀请码: 'Kode undangan',
  我的钱包余额: 'Saldo dompet saya',
  我的机器人仓库: 'Daftar Robot Saya',
  收益面板: 'Panel penghasilan',
  去提款: 'Tarik uang',
  昨天代理返利: 'Komisi agensi kemarin',
  累计代理返利: 'Komisi agen kumulatif',
  今天机器人收益: 'Profit trading hari ini',
  累计机器人收益: 'Profit trading kumulatif',
  累计机器人回扣: 'Akumulasi komisi trading',
  累计总回报: 'Total profit kumulatif',
  自动订单机器人数量: 'Robot trading kuantitatif yang diaktifkan',
  我的付款方式: 'Metode pembayaran saya',
  个人收入记录: 'Pendapatan pribadi',
  团队收入记录: 'Pendapatan tim',
  记录详情:'Rekam detailnya',
  充值记录: 'Isi ulang catatan',
  我的团队成员: 'Anggota tim saya',
  活动中心: 'Pengantar Aturan',
  修改登录密码: 'Pengaturan kata sandi masuk',
  交易密码管理: 'Pengaturan kata sandi transaksi',
  机器人: 'Strategi Trading Ai',
  机器人性能: 'Papan Robot',
  机器人价格: 'Harga Strategi Trading',
  有效期: 'Masa Berlaku Trade',
  钱包地址: 'Alamat dompet',
  天: 'Hari',
  去购买: 'Beli',
  买个机器人: 'Beli Robot Trading Kuantitas',
  我的钱包余额: 'Saldo rekening saya',
  预计日收益: 'Estimasi pendapatan harian',
  启动保证金: 'Setoran awal',
  请输入购买数量: 'Masukkan jumlah pembelian',
  个机器人: 'Robot Trading Kuantitas',
  机器人购买后需要激活才能工作: 'Setelah membeli node, jumlah POS yang sesuai diperlukan untuk mulai mendapatkan keuntungan',
  机器人激活需要从余额中扣除相应的启动押金:
    'Penambangan Node POS perlu mengurangi jumlah POS yang sesuai dari saldo akun',
  机器人暂停机器人停止时将返还启动押金不活跃的机器人可以赠送:
    'Jumlah POS yang dapat dikembalikan segera ketika penambangan node POS ditangguhkan',
  购买: 'Beli',
  点击去了解我们: 'Trading Kuantitatif',
  购买机器人: 'Beli robot',
  快速入口: 'Entri cepat',
  '日/个人受益': 'Hari / keuntungan pribadi',
  购买教程: 'Daftar Robot Trading AI',
  激活金额: 'BIAYA POS',
  订单数量: 'Kuantitas pesanan',
  我们的合作伙伴: 'Mitra Pasar Crypto kami',
  提款: 'Tarik uang',
  提款金额: 'Jumlah penarikan',
  你还没有添加银行卡: '你还没有添加银行卡',
  提现说明: 'Instruksi penarikan',
  说明1: '1.Anda dapat memilih dengan satu klik atau memasukkan jumlah isi ulang secara manual',
  说明2: '2.Disarankan untuk menggunakan USD (TRC20), biaya transfer paling rendah',
  说明3: '3.Jam layanan pelanggan adalah Senin hingga Jumat AM8 hingga PM6 Waktu Amerika Tengah',
  说明4: 'Pengguna yang menggunakan USD untuk mengisi ulang perlu mengunggah tangkapan layar yang benar dari transfer yang berhasil dan TXID, dan akun akan berhasil diterima setelah platform lolos peninjauan',
  说明5: '4.Isi ulang minimum adalah 8 USD, yang memudahkan sistem untuk meninjau dan memproses akun dengan cepat',
  说明6: '5.Jika Anda memiliki pertanyaan tentang pengisian ulang, silakan hubungi layanan pelanggan online tepat waktu untuk mendapatkan bantuan',
  确认提款: 'Konfirmasi penarikan ',
  请输入手机号码:'Nomor telepon',
  请输入提现金额: 'Jumlah penarikan ',
  提现费:'Biaya penarikan',
  提现须知: 'Harap baca aturan penarikan dengan hati-hati',
  TIPS1: '1:Waktu peninjauan penarikan harian adalah dari Senin hingga Jumat AM8 hingga PM6. Penarikan dapat dilakukan pada akhir pekan, dan waktu peninjauan penarikan adalah hari Senin',
  TIPS2: '2:Sistem 7X24 jam, tarik uang kapan saja, masuk ke rekening paling lambat 24 jam, dan sampai ke rekening paling cepat 5 menit',
  TIPS3: '3:Waktu layanan pelanggan online: Senin hingga Jumat AM8 hingga PM6',
  TIPS4: '4:Karena biaya transfer USD, platform membebankan biaya penarikan saat pengguna menarik uang',
  TIPS5: '5:Jumlah penarikan minimum adalah 10 USD',
  钱包余额: 'Saldo dompet',
  输入数量:"Masukkan kuantitas",
  数量:"kuantitas",
  手机号码:"Nomor telepon",
  手机号码已存在:"Nomor ponsel telah terdaftar",
  邮箱:"Mail",
  输入手机号码: 'Masukkan No.Hp',
  请输入登录密码: 'Kata sandi masuk',
  登录: 'Masuk log in',
  输入账号: 'Masukkan nomor akun',
  密码: 'Kata sandi',
  点击注册: 'Belum punya akun？ Buat akun sekarang!',
  忘记密码: 'Lupa password',
  重新登录: 'Login lagi',
  密码找回成功: 'Password berhasil diambil',
  找回成功请重新登录: 'Berhasil mengambil, silakan masuk lagi',
  确认: 'Konfirmasi',
  发送验证码: 'Kirim kode verifikasi',
  再次输入密码: 'Konfirmasi sandi',
  输入验证码: 'Masukkan email CAPTCHA',
  输入手机验证码:"Masukkan OTP No.Hp",
  输入手机号: 'Masukan nomor handphone',
  输入密码: 'Kata sandi masuk',
  快速注册: 'Registrasi cepat',
  注册: 'Daftar',
  立即登录: 'Sudah punya akun, klik untuk login',
  请输入邮箱: 'Alamat Gmail',
  输入用户名: 'Masukkan nama pengguna Anda',
  请输入邀请码: 'Masukkan kode undangan',
  返回: 'kembali',
  密码找回失败: 'Pemulihan kata sandi gagal',
  输入的信息有误: 'Informasi yang dimasukkan salah',
	获取验证码:"CAPTCHA",
	手机号码不能为空:"Nomor telepon tidak boleh kosong",
	账号不存在:"Akun tidak ada",
	发送成功:"Berhasil dikirim",
	今日收益:"（pendapatan saat ini）",
	"您好如果您中途终止POS-STAKING系统将收取5%的STAKING费用":"Halo, jika Anda menghentikan POS di tengah jalan, sistem akan membebankan biaya pengelolaan POS sebesar 5%. Pengingat khusus: Setelah POS pengalaman node gratis ditangguhkan, Anda tidak akan bisa mendapatkannya lagi, Anda juga tidak akan bisa mendapatkan biaya apa pun! !",
	我同意:"Saya setuju",
	用户账号ID:"ID Akun",
	取消:"Batal",
	日收益:"Penghasilan harian",
	请输入购买数量:"Masukkan jumlah pembelian",
	数量不能为空:"Kuantitas tidak boleh kosong",
	加载中:"Pemuatan...",
	唯一码:"Pengkodean Robot",
	未激活:"TIDAK AKTIF",
	激活:"Mulai Strategi",
	购买时间:"Waktu pembelian",
	"钱包余额不足，激活该机器人失败":"Saldo akun tidak mencukupi, penambangan node POS tidak dapat dilakukan",

	运行时长:"Waktu berjalan",
	签约购买:"Pembelian saldo",
	系统赠送:"Hadiah sistem",
	状态:"menyatakan",
	正常:"Normal",
	我的机器人:"Robot Trading Kuantitas Saya",
	一级会员:"Tingkat 1",
	二级会员:"Tingkat 2",
	三级会员:"Tingkat 3",
	人:"",
	充值客服:"Isi ulang layanan pelanggan",
	充值:"Isi ulang",
	提现:"Menarik",
	提款密码:"Kata sandi penarikan",
	设置交易密码:"Tetapkan kata sandi transaksi",
	登录密码:"Kata sandi masuk",
	请输入交易密码:"Masukkan kata sandi transaksi",
	再次请输入交易密码:"Konfirmasi kata sandi transaksi",
	确认: 'Konfirmasi',
	手机号码不能为空:"Nomor telepon tidak boleh kosong",
	两次密码不一致:"Kedua kata sandi tidak cocok",
	请输入验证码:"Masukkan kode verifikasi",
	操作成功:"Operasi sukses",
	"用户名或密码不正确,登录失败":"Nama pengguna atau sandi salah, login gagal",
	登录成功:"Login berhasil",
	充值说明:"Instruksi isi ulang",
	请先设置支付密码:"Harap atur kata sandi transaksi terlebih dahulu",
	复制成功:"Berhasil menyalin",
	冻结机器人做单本金:"Bekukan dana kuantitatif",
	待审核:"Untuk ditinjau...",
	成功:"Sukses",
	失败:"Gagal",
	审核中:"Sedang ditinjau",
	在线充值:"Isi ulang online",
	描叙:"menggambarkan",
	POS节点挖矿LV0:" Penambangan node POS-LV0",
	POS节点挖矿LV1:" Penambangan node POS-LV1",
	POS节点挖矿LV2:" Penambangan node POS-LV2",
	POS节点挖矿LV3:" Penambangan node POS-LV3",
	POS节点挖矿LV4:" Penambangan node POS-LV4",
	POS节点挖矿LV5:" Penambangan node POS-LV5",
	银行卡提现:"Penarikan tunai kartu bank",
	USD提现:"Penarikan USD",
	三级代理:"Agen Tingkat 3",
	一级代理:"Agen Tingkat 1",
	二级代理:"Agen Tingkat 2",
	一级:"Tingkat 1",
	二级:"Tingkat 2",
	三级:"Tingkat 3",
	做单:"Penambangan node",
	登录失败:"Gagal masuk",
	请勿重复操作:"Jangan ulangi operasi yang sering",
	邀请码不正确:"Kode undangan salah",
	团队返佣:"Komisi tim",
	购买机器人返佣:"Komisi Robot",
	本金返还:"Dana jumlah POS",
	佣金收入:"Pendapatan komisi",
	时间:"Waktu",
	机器人做单返还:"Dana jumlah POS",
	涨幅:"kenaikan",
	市场:"Pasar",
	客服:"Melayani",
	验证码错误:"Kesalahan kode verifikasi",
  付款方式:"Alamat penarikan USD",
	我的留言:"pesan saya",
  暂无数据: 'Tidak ada data',
  银行卡:'kartu bank',
  修改: 'Merevisi',
 '请确保您的钱包地址正确，如果您的地址输入错误而导致收不到钱，请及时联系在线客服协助':'Harap pastikan alamat dompet Anda benar. Jika alamat Anda salah dimasukkan dan uang tidak dapat diterima, harap hubungi layanan pelanggan online tepat waktu untuk mendapatkan bantuan',
 连接借记卡:"连接借记卡",
 银行名称:'Nama Bank',
 姓名姓氏: 'Nama',
 IBAN号码:'IBAN 号码',
 '* 重要提示:借记卡信息必须真实有效才能取款。':'* Catatan Penting: Informasi rekening bank harus benar dan valid untuk menarik uang.',
 帮助中心:'Pusat Bantuan',
 会员须知:'Pemberitahuan Anggota',
 '24小时内使用USD实时取款（推荐）':'1. 24小时内使用USD实时取款（推荐）',
 '银行卡 取款 *取款时间为10:00-18:00 *发起取款后2小时内入账*申请非取款时间或跨行取款将于次日10:00-18:00到账':' 2.银行卡 取款 *取款时间为10:00-18:00 *发起取款后2小时内入账*申请非取款时间或跨行取款将于次日10:00-18:00到账',
 我知道了:'Jadi begitu',
 链接点击:'Tautan pendaftaran referensi',
 确定:'Tentu',
 建立自己的团队邀请:'Bangun timmu sendiri',
 此账号已冻结:"Akun ini telah dibekukan",
 手机号存已存在:"Nomor ponsel sudah ada",
 注册账号已存在:"Akun terdaftar sudah ada",
 请确定新密码:"Harap konfirmasi kata sandi baru",
 请再次输入登录密码:"Masukkan kata sandi masuk lagi",
 密码长度不能少于6位:"Panjang kata sandi tidak boleh kurang dari 6 karakter",
 加入:'bergabung',
 "你出售的机器人对方已接受,出售所得已打款到您账户,请查收。":
 	    "你出售的节点对方已接受,出售所得已打款到您账户,请查收。",
 	"你出售的机器人对方已拒绝接受":"你出售的节点对方已拒绝接受",
 	向你赠送一台机器人:"向你赠送一个节点，待确认",
 	向你出售一台机器人:"向你出售一个节点，待确认",
 	出售价格不能超过机器人价格:"出售价格不能超过节点价格",
 	银行卡已被绑定:"Rekening bank telah digunakan",
 	USD_TRC20提现:'Penarikan USD_TRC20',
 	USD_ERC20提现:'Penarikan USD_ERC20',
 	银行卡提现:'Penarikan rekening bank',
 	邀请赠送:'Bonus undangan',
 	机器人返佣:'Komisi Robot',
 	升级:' pembelian  ',
 	充值主网:'充值主网',
   "您被限制出售机器人,请联系客服了解详情":
     "您被限制出售节点,请联系客服了解详情",
   交易编号: "NO. transaksi",
   消息详情:"Detail pesan",
   个人收益累计:"Akumulasi pendapatan pribadi",
   今日代理收益:"Pendapatan agen hari ini",
   代理累计收益:"Pendapatan kumulatif agensi",
   截图保存推荐给朋友:"Bagikan tautan pendaftaran referensi Anda dan undang teman untuk bergabung dengan platform Plus500Ai untuk mendapatkan hadiah komisi pendapatan tim. Komisi pendapatan untuk anggota tim tingkat ketiga adalah 8%-3%-2%. Komisi tim didasarkan pada data pendapatan harian anggota tingkat bawah dan akan secara otomatis diselesaikan dan dimasukkan ke saldo akun Anda setelah pukul 00:00 setiap hari.",
   复制:"Salinan",
   充值提示1:"Kiat 1: Isi ulang minimum adalah 10 USD, Anda dapat mengisi ulang, jika tidak, Anda tidak akan dikreditkan",
   充值提示2:"Kiat 2: Isi ulang harus dikonfirmasi pada rantai. Setelah isi ulang berhasil, diharapkan saldo dompet tiba dalam waktu sekitar satu menit。",
   请输入正确的充值金额:"Jumlah deposit ≥8 USD",
   推荐:"menyarankan",
   充值金额:"Jumlah isi ulang",
   请上传付款截图:"Unggah tangkapan layar pembayaran yang berhasil",
   交易号: 'Nomor hash transaksi',
   充值ID提示:"Silakan tempel nomor seri transaksi Txid Anda",
   "请确保钱包地址无误，如因钱包地址填写错误导致无法收款而造成的损失，将有用户自己承担。":"Harap pastikan bahwa alamat dompet sudah benar.Kerugian yang disebabkan oleh kegagalan menerima uang karena pengisian alamat dompet yang salah akan ditanggung oleh pengguna.",
	上传凭证:"Unggah bukti pembayaran",
	充值确认: "Kirim",
	不能为空:"Tidak boleh kosong",
  绑定钱包地址:'Mengikat alamat dompet',
	备注:"Komentar",
	请输入银行卡号:"Masukkan nomor kartu bank",
	请输入你的名字:"Silahkan masukan nama anda",
	银行卡号:"Rekening bank",
	添加银行卡:"Tambahkan bank",
	请选择银行:"Pilih bank",
	请输入钱包地址:"Masukkan alamat dompet",
	钱包地址:"Alamat dompet",
	"密码错误次数过多,请等待x秒后再试":"Terlalu banyak kata sandi yang salah, harap tunggu selama 600 detik dan coba lagi",
	"此账号已冻结,请联系客服":"Akun ini telah dibekukan, harap hubungi layanan pelanggan",
	手机号格式错误:"Nomor telepon rusak",
	"取款须知":"Pemberitahuan Penarikan",
	"须知1":"1. Gunakan USD-TRC20 untuk penarikan real-time dalam 24 jam (disarankan)",
	"须知2":"2. Penarikan pada hari Sabtu dan Minggu",
	"须知3":"*Pengguna dapat menarik uang pada hari Sabtu dan Minggu",
	"须知4":"*Penarikan akhir pekan akan tiba pada hari Senin pukul 10:00-20:00",
	我知道了:"Jadi begitu",
	"提现0":"1USD=1USD ERC20",
	"提现1":"提示1：最低单笔提现100USD/天，单笔交易手续费5USD/次",
	"提现2":"TRC20提示2：每次提款最低10USD，每次交易手续费1USD。",
	"提现3":"银行账户提示3：最低单次取款为$10，交易手续费为交易金额的$6%",
	"提现4":"提现时间：提现申请提交后24小时内到账",
	
	已绑定:"TERIKAT",
	去设置:"Pergi ke pengaturan",
	注册成功:"Registrasi berhasil",
	汇率:"kurs",
	请输入正确的提现金额:"Harap masukkan jumlah penarikan yang benar",
	提款记录:"Riwayat penarikan",
	"绑定的钱包地址必须跟付款钱包一致，否则不会到账。":"tip1:绑定的钱包地址必须跟付款钱包一致，否则不会到账。",
	"充值成功后预计在一分钟左右到账，请检查钱包约。":"tip2:充值成功后预计在一分钟左右到账，请检查钱包余额。",
	请先绑定钱包地址:"Harap ikat alamat dompet terlebih dahulu",
	输入邮箱:"Masukan email",
	邮箱已存在:"Kotak surat sudah ada",
	注册邮箱已存在:"Email terdaftar sudah ada",
	指定用户不存在:"Pengguna yang ditentukan tidak ada",
	今日涨幅:"Perubahan",
	恭喜新用户:"Selamat pengguna baru ",
	快捷充币:"Isi ulang",
	快速买USD:"Isi ulang USD",
	在线支付:"Pembayaran Online",
	自动到账:"Akun otomatis",
	线下支付:"Pembayaran luring",
	联系客服获取验证码:"Hubungi layanan pelanggan untuk mendapatkan kode verifikasi",
	获取:"Memperoleh",
	退出成功:"Keluar dengan sukses",
	不能低于最小充值:"Tidak boleh lebih rendah dari isi ulang minimum",
	不能低于最小提现金额:"Tidak boleh lebih rendah dari jumlah penarikan minimum",
	最小提现:"Penarikan minimum",
	设置成功:"Berhasil ditetapkan",
	官方充币:"Deposito sistem",
	银行卡充值:"Isi ulang bank",
	等待审核:"Tunggu ulasan",
	可提现金额不足:"Jumlah yang tersedia tidak cukup untuk penarikan",
	未经授权:"Tanpa izin",
	交易密码不正确:"Kata sandi transaksi salah",
	提现次数不足:"Waktu penarikan tidak mencukupi",
	官网:"官网",
	账户USD不足:"Saldo USD akun tidak mencukupi",
	激活成功: "Sukses POS",
	操作成功: "Operasi sukses",
	修改成功:"Berhasil dimodifikasi",
	注册说明:"OTP Seluler dan CAPTCHA Email hanya perlu memilih satu metode untuk verifikasi dan Anda berhasil mendaftar",
	弹窗内容:
	"Selamat datang di platform perdagangan kuantitatif Plus500Ai. Setiap pengguna dapat menggunakan bonus pendaftaran 1 USD untuk membeli paket strategi perdagangan robot kuantitatif NO.0, yang berlaku selama 1 hari dan mendapatkan 0,5 USD gratis. Paket strategi perdagangan kuantitatif lainnya memerlukan biaya yang sesuai untuk digunakan. Setelah pengguna membeli robot, mereka perlu mengaktifkan strategi perdagangan kuantitatif sebelum dapat mulai berjalan secara otomatis. Setiap strategi perdagangan memiliki masa berlaku yang berbeda. Jika pengguna tidak mengklik untuk mengaktifkan strategi setelah membeli poin robot yang sesuai, Anda tidak akan menerima pendapatan perdagangan apa pun.",
	标题1:'1. Pengenalan platform Plus500',
	标题2:'2. Keuntungan perdagangan kuantitatif AI',
	标题3:'3. Mengapa memilih Plus500Ai?',
	标题4:'4. Bagaimana cara bergabung dengan Plus500Ai',
	标题5:'5.Prospek pasar Plus500Ai',
	标题6:'6. Model pendapatan Plus500Ai',
	标题7:'7.Peraturan Deposit dan Penarikan',
	标题8:"Permainan Battle Royale",
	内容8:"Ada 8 ruangan dalam game battle royale di platform Plu500Ai. Pemain dapat memilih ruangan mana saja di antara 8 ruangan tersebut untuk menaruh koin emas. Setelah hitungan mundur sistem berakhir, seorang pembunuh akan muncul di sistem permainan. Pembunuh tersebut secara acak memasuki sebuah ruangan dan melenyapkan orang-orang di ruangan itu. Pemain lain dapat berbagi koin emas di ruangan ini dan mendapatkan keuntungan. Gameplay dari game battle royale ini tergolong singkat, permainan hanya berlangsung beberapa menit, sehingga pemain dapat memainkannya di waktu luang. Jumlah pembagian = jumlah total kamar yang dibunuh oleh pembunuh pada periode ini. Jumlah saham sebenarnya = jumlah saham * 0,9 (tidak termasuk rasio kehancuran 0,1). Rasio pembagian = jumlah pembagian aktual / jumlah total taruhan di ruangan lain. Jumlah kemenangan = rasio pembagian * jumlah taruhan sebenarnya. 1 koin emas = 1 USD, dan diperlukan minimal 10 USD untuk berpartisipasi dalam game battle royale.",
	内容1:"Platform perdagangan Plus500 disediakan oleh Plus500SEY Ltd. Oleh karena itu, Plus500SEY Ltd adalah penerbit dan penjual produk keuangan yang dijelaskan atau ditawarkan di situs web ini. Plus500SEY Ltd adalah perusahaan yang berbasis di Seychelles (Perusahaan No. 8426415-1) dengan kantor di Victoria. Perusahaan ini diberi wewenang dan diawasi oleh Otoritas Jasa Keuangan (FSA) Seychelles dengan nomor lisensi SD039 dan menyediakan Contracts for Difference (CFD) pada berbagai produk dasar. Perusahaan ini adalah penyedia CFD yang berkembang pesat yang saat ini menawarkan portofolio lebih dari 2.800 instrumen keuangan. Plus500SEY Ltd adalah anak perusahaan Plus500 Ltd, yang terdaftar di Pasar Utama Bursa Efek London dan berkantor pusat di Haifa.",
	内容2:'Keuntungan dari perdagangan kuantitatif AI yang sepenuhnya otomatis adalah menggunakan alat rasional untuk mengoperasikan transaksi sesuai dengan pengaturan model.Robot tidak memiliki perubahan emosional dan dapat mengatasi sifat manusia, keserakahan dan ketakutan manusia serta banyak kekurangan dan kelemahan lainnya dalam proses pengoperasian. Operasi manual seringkali dipengaruhi oleh naik turunnya pasar dan berbagai faktor lainnya, dan tidak dapat beroperasi sesuai model keuntungan terbaik untuk waktu yang lama.',
	内容3:'Plus500Ai secara bertahap memperkuat posisinya sebagai grup fintech multi-aset dengan memperluas produk intinya ke pasar baru dan yang sudah ada, meluncurkan produk perdagangan dan keuangan baru, dan memperdalam keterlibatannya dengan pelanggan. Lindungi aset Anda dan lindungi kunci enkripsi Anda dengan teknologi keamanan terdepan di industri. Plus500Ai juga menawarkan hak asuh global, yang berarti kami teregulasi, diaudit, dan diasuransikan hingga $250 juta. Gunakan modal Anda dengan tujuan tidak hanya untuk memiliki aset, namun juga untuk menggunakannya. Plus500Ai memungkinkan Anda menyebarkan dana Anda dalam berbagai cara, mulai dari perdagangan kuantitatif Ai hingga layanan utama klasik, bergantung pada toleransi risiko Anda. Platform online untuk memberikan lebih banyak layanan kepada pengguna Anda sendiri tanpa mempekerjakan insinyur blockchain tambahan.',
	内容4:"Sejak berdirinya Plus500Ai, hanya ada satu situs resmi yaitu WWW.PLUS500AI.COM. Semua pengguna harus masuk ke situs web resmi untuk mengunduh APLIKASI, karena situs web lain bukan milik perusahaan kami. Untuk mendaftar, Anda perlu mengirimkan nomor ponsel pengguna, alamat email, dan kode undangan pemberi rekomendasi untuk mendaftar dengan cepat. Semua komunikasi dengan pelanggan dilakukan secara tertulis, baik melalui email atau chat online. Untuk menghubungi Plus500 melalui email, silakan lengkapi formulir di halaman 'Hubungi Kami' ('Formulir Permintaan'). Setelah mengirimkan formulir aplikasi, Plus500 akan mengirimkan balasan melalui email langsung ke alamat email yang Anda tentukan dalam formulir aplikasi. Jika Anda memiliki pertanyaan, silakan hubungi layanan pelanggan.",
	内容5:'Plus500Ai beroperasi di seluruh produk, perangkat, dan negara untuk memberikan peluang perdagangan keuangan yang andal dan intuitif kepada pelanggan. Kami selalu mengejar keunggulan dalam produk dan tim kami. Dengan teknologi eksklusif yang canggih dan pendekatan yang berpusat pada pelanggan, pengguna kami menerima pengalaman layanan perdagangan kelas satu.',
	内容6:"Plus500Ai menyediakan strategi perdagangan kuantitatif profesional untuk pasar mata uang kripto oleh tim analis data senior.Pengguna yang berinvestasi pada robot perdagangan kuantitatif Ai dapat memperoleh keuntungan perdagangan kuantitatif dengan mengandalkan strategi perdagangan yang cocok dengan robot tersebut. Robot perdagangan kuantitatif Ai secara siklis menganalisis waktu perdagangan optimal berdasarkan strategi perdagangan pasar Plus500Ai, dan memperoleh keuntungan perdagangan kuantitatif melalui model jual beli tingkat rendah dengan harga tinggi. Plus500Ai memberi pengguna investasi strategi perdagangan kuantitatif yang optimal setiap hari, dan platform mengekstrak 5% dari keuntungan pendapatan kuantitatif pengguna sebagai dana pengoperasian platform.",
	内容7:'Plus500Ai saat ini mendukung deposit dan penarikan USD, dan akan ada pengumuman di situs resminya jika saluran lain dibuka di masa mendatang. Setoran dan penarikan minimum keduanya 10 USD. Setoran dan penarikan tercepat dalam 5 menit dan paling lambat dalam 24 jam. Tidak ada batasan waktu penarikan pengguna, Anda dapat menarik uang kapan saja. Penarikan tiba dalam waktu 5 menit - 24 jam pada hari kerja normal. Penarikan akhir pekan diatur untuk diproses pada hari Senin.',
	BSC提现:"Penarikan USD_BSC",
	社区Ads收益:"Penghasilan Iklan Komunitas",
	Ads收益区间:"Pendapatan iklan $1-1000",
	申请Ads费用:"Biaya Iklan Aplikasi",
	Ads促销地址:"Alamat promosi iklan",
	填写Ads促销地址:"Isi alamat promosi iklan",
	申请Ads费用须知:"Petunjuk untuk mengajukan biaya Iklan",
	须知A:"1. Pengguna merekam video promosi dan mengunggahnya ke YouTube, Facebook, saluran, atau komunitas mereka sendiri untuk promosi Iklan, mengajukan bonus $1-$1000, dan platform akan meninjau materi video Iklan Anda 3 hari setelah mengirimkan aplikasi, dan memasang Iklan bonus ke akun sesuai dengan efek promosi video Saldo akun Anda, video tanpa efek promosi apa pun tidak dapat ditinjau",
	须知B:"2. Video promosi Iklan yang sama di saluran yang sama hanya dapat mengajukan bonus satu kali, dan materi video promosi baru dapat dirilis seminggu sekali. Jangan mengirim permintaan berulang yang berbahaya. Jika Anda dengan jahat mengirimkan permintaan Iklan yang tidak dibuat sendiri di platform beberapa kali atau materi Iklan yang gagal ditinjau, akun Anda akan diblokir setelah sistem mendeteksi",
	须知C:"3. Materi video Iklan yang direkam oleh pengguna harus memiliki pengenalan platform dasar dan penjelasan fungsi platform. Durasi video tidak lebih pendek dari 5 menit. Semakin banyak pelanggan saluran Anda, semakin baik efek promosi video, dan semakin tinggi Biaya iklan yang dapat Anda ajukan. Biaya Iklan khusus adalah sebagai berikut, dengan menggunakan YOUTUBE sebagai contoh:",
	须知D: "Pelanggan 0-2K: jumlah aplikasi 1-20$",
	须知D1:"Pelanggan 3K-10K: jumlah aplikasi 21-40$",
	须知D2:"Pelanggan 11K-30K: jumlah aplikasi 41-100$",
	须知D3:"Pelanggan 31K-50K: jumlah aplikasi 101-300$",
	须知D4:"Pelanggan 51K-100K: jumlah aplikasi 301-1000$",
	须知D5:"4. Sistem akan meninjau dan memproses permohonan bonus Iklan setiap hari Minggu, dan pengguna yang memenuhi syarat akan menerima bonus yang dikirimkan oleh sistem.",
	请求审核:"Meminta peninjauan",
	申请金额:"Jumlah Aplikasi",
	URL促销地址:"Alamat promosi URL",
	提交时间:"Waktu penyerahan",
	需要更新的银行卡不存在:"Kartu bank yang akan diperbarui tidak ada",
	银行卡提现:"Penarikan tunai kartu bank",
	预计收入:"Estimasi penghasilan",
	总投资:"Total investasi",
	购买价:"Harga beli",
	邀请:"Undang",
	成员:"anggota",
	积分等级折扣规则介绍3:"Besaran diskon ini hanya berlaku hingga 31 Desember 2023, setelah 1 Januari 2024 akan ada penyesuaian baru.",
	LV1佣金:"Komisi TINGKAT1",
	LV2佣金:"Komisi TINGKAT2",
	LV3佣金:"Komisi TINGKAT3",
	合格条件:"Deposit minimal $8 per orang",
	随时可退:"Kembali kapan saja",
	登录密码错误:"Kata sandi masuk salah",
	充值奖金:"Deposit USD≥50USD, bonus 3%",
	充值奖金1:"Deposit Rp≥50USD, bonus 2%",
	充值奖金2:"Deposit THB≥50USD, bonus 5%",
	活动已过期:"Acara telah berakhir, tunggu acara selanjutnya",
	不能重复发送短信:"Anda tidak dapat mengirim pesan teks berulang kali dalam waktu 10 menit. Disarankan agar Anda menggunakan EMAIL CAPTCHA untuk mendaftar secara langsung.",
	限购说明1:"Halo, setiap pengguna paket strategi perdagangan robot kuantitatif NO.0 hanya dapat membelinya satu kali.",
	修改钱包地址提示:"Alamat penarikan USD tidak dapat diubah setelah diikat. Jika Anda memerlukan bantuan, silakan hubungi layanan pelanggan.",
	修改银行提示:"Rekening bank penarikan tidak dapat diubah setelah diikat. Jika Anda memerlukan bantuan, silakan hubungi layanan pelanggan.",
	注册推送消息1:"Selamat datang di INGSAI. Setelah pengguna baru memulai node penambangan POS, mereka akan menerima pendapatan penambangan POS setiap 5 menit.Pendapatan komisi tim akan otomatis masuk ke saldo akun sekitar pukul 00.00 per hari.",
	限购说明:"Halo, maksimal pembelian paket robot kuantitatif jenis ini adalah 10 kali, disarankan untuk membeli paket perdagangan kuantitatif jenis lainnya.",
	注册协议:"Dengan menekan (Buat Akun), Anda mengonfirmasi bahwa Anda berusia di atas 18 tahun. Anda menyadari bahwa Plus500AI dapat menggunakan alamat email Anda atau data pribadi lainnya untuk menghubungi Anda secara elektronik guna memberikan informasi yang relevan tentang produk/layanannya. Kami juga dapat memberi tahu Anda tentang peristiwa pasar untuk membantu Anda mendapatkan hasil maksimal dari pengalaman trading Anda. Anda dapat mengelola dan mengubah preferensi notifikasi di tab (Pengaturan Notifikasi) dan (Pengaturan Privasi) pada Platform. Anda dapat berhenti berlangganan komunikasi ini kapan saja. Untuk informasi lebih lanjut, silakan tinjau (Perjanjian Privasi) kami",
	公司说明1:"Plus500AI adalah merek dagang terdaftar dari Plus500 Ltd. Plus500 Ltd beroperasi melalui anak perusahaan berikut:",
	公司说明2:"Plus500SEY Ltd disahkan dan diatur oleh Otoritas Jasa Keuangan Seychelles (Nomor Lisensi SD039).",
	公司说明3:"Plus500SEY Ltd adalah penerbit dan penjual produk keuangan yang dijelaskan atau tersedia di situs web ini.",
	公司说明4:"Plus500UK Ltd disahkan dan diatur oleh Financial Conduct Authority. Nomor Registrasi Otoritas Perilaku Keuangan: 509909. CFD Mata Uang Kripto tidak tersedia untuk pengguna ritel.",
	公司说明5:"Plus500CY Ltd disahkan dan diatur oleh Komisi Sekuritas dan Bursa Siprus (kode otorisasi: 250/14). CFD Mata Uang Kripto tidak tersedia untuk investor ritel Inggris.",
	公司说明6:"Plus500AU Pty Ltd memegang lisensi berikut: AFSL (No. 417727) yang dikeluarkan oleh ASIC, FSP (No. 486026) yang dikeluarkan oleh FMA Selandia Baru, dan Penyedia Jasa Keuangan Resmi (No. 47546) yang dikeluarkan oleh FSCA Afrika Selatan.",
	公司说明7:"Plus500EE AS ​​​​diotorisasi dan diatur oleh Otoritas Pengawas Keuangan Estonia (Lisensi No. 4.1-1/18).",
	公司说明8:"Plus500SG Pte Ltd (UEN 201422211Z) memegang lisensi layanan pasar modal yang dikeluarkan oleh Otoritas Moneter Singapura, yang mengizinkannya untuk memperdagangkan produk pasar modal (nomor lisensi CMS100648).",
	公司说明9:"Plus500AE Ltd disahkan dan diatur oleh Otoritas Jasa Keuangan Dubai (F005651).",
	加入我们的社群:"Gabung Dengan Komunitas Kami",
	PLUS500集团:"PLUS500 KELOMPOK",
	受监管的操作:"OPERASI YANG DIATUR",
	健全的全球监管框架:"Kerangka peraturan global yang kuat",
	治理和社会责任:"TATA KELOLA DAN TANGGUNG JAWAB SOSIAL",
	底部说明:"Plus500 tetap berdedikasi untuk beroperasi secara bertanggung jawab dan berkelanjutan dalam semua aspek bisnisnya dan percaya bahwa pendekatan ini adalah tugasnya dan merupakan bagian penting dari manajemen yang efektif. Plus500 berkomitmen terhadap serangkaian inisiatif ESG untuk menciptakan nilai nyata bagi masyarakat, pelanggan, komunitas lokal, badan amal, dan pemegang saham kami.",
	投资者联系方式:"KONTAK INVESTOR",
	投资者关系主管:"Kepala Hubungan Investor",
	AI智慧型量化交易:"Trading kuantitatif cerdas AI",
	订单执行快速可靠:"Eksekusi pesanan cepat dan dapat diandalkan",
	全天候自动分析行情:"Analisis pasar otomatis sepanjang waktu",
	交易受监管低风险:"Trading diatur dan risikonya rendah",
	稳定策略高效收益:"Strategi yang stabil dan pengembalian yang efisien",
	取款快速且安全:"Tarik uang dengan cepat dan aman",
	游戏说明:"Saldo minimum yang diperlukan adalah 10 USD untuk memasuki permainan dan menghasilkan uang",
	//8.7
	货币:"Rp",
	能量值:"Nilai energi",
	财富值:"nilai kekayaan",
	佣金:"komisi",
	累计工资:"Akumulasi gaji",
	工资:"gaji",
	租赁:"sewa",
	去租赁:"Pergi ke persewaan",
	
	当前速率:"tarif saat ini",
	当前工资:"gaji saat ini",
	预计今日收益:"Estimasi penghasilan hari ini",
	累计佣金:"Akumulasi komisi",
	邀请好友注册领取工资:'Undang teman untuk mendaftar dan menerima gaji',
	月:'bulan',
	符号:'id_id',
	转盘抽奖:'Lotere rolet',
	剩余抽奖次数:'Jumlah undian yang tersisa',
	关闭:'penutup',
	恭喜您获得:'Selamat atas perolehannya',
	很遗憾没有中奖:'Sayang sekali saya tidak menang',
	游戏规则:'aturan permainan',
	租赁机器人后会获得抽奖次数:'Setelah menyewa robot, Anda akan mendapatkan nomor undian',
	中奖金额会存入余额:'Jumlah kemenangan akan dikreditkan ke saldo Anda',
	中奖记录请到收入记录中查看:'Silakan periksa catatan pendapatan untuk catatan kemenangan.'
}
